import { Dispatch } from "redux";
import { ShopRPC, ShopListInput } from "../../rpc-clients/ShopRPC";
import { buildJSONRPCClient, asyncActionCreator, combineMiddleware } from "../asyncActionCreator";
import { universalErrorHandler } from "../middlewares/universalErrorHandler";
import { ActionType } from "../../constants/ActionType";
import { errorLogger } from "../middlewares/errorLogger";

export type ShopAsyncActionCreatorType = {
    shopList(input: ShopListInput): void;
}

export const shopAsyncActionCreators = {
    shopList: (input: ShopListInput) => {
        return async (dispatch: Dispatch) => {
            const rpc = buildJSONRPCClient(ShopRPC);
            const asyncAction = await asyncActionCreator(
                rpc.shopList.bind(rpc),
                input,
                {
                    whenSuccess: (result) => {
                        return {
                            type: ActionType.LoadShops,
                            data: result
                        }
                    },
                    whenFailure: combineMiddleware([universalErrorHandler, errorLogger])
                }
            );
            if(asyncAction) {
                dispatch(asyncAction);
            }
        }
    },
};