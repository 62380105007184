import { REHYDRATE } from "redux-persist"
import { LoginDataAction } from "../actions/LoginDataAction";
import { JWTInfo } from "../models/LoginData";

export const loginDataReducer = (state: JWTInfo | null, action: any) => {
  switch (action.type) {
    case LoginDataAction.Load:
    case LoginDataAction.Save:
      return action.data

    case REHYDRATE:
      if (!action.payload) {
        return null
      }
      if (JSON.stringify(action.payload.loginData) === "{}") {
        return null
      }
      return action.payload.loginData

    case LoginDataAction.UpdateUserInfo:
      return { ...state, ...action.data }

    case LoginDataAction.UpdateCredential:
      return { ...state, ...action.data }      

    case LoginDataAction.Remove:
      return null

    default:
      return state || null
  }
}