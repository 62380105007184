import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { Link } from 'react-router-dom';

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class AdminDashboardPage extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    if (!this.props.appState.userInfo.hasLoggedIn){
      this.props.history.push('/amb/login');
    }
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  render() {
    return (
    <>
      <Header></Header>
      <div>
        <Link to="/amb/shop_list">店舗リスト</Link>
    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDashboardPage);
