import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { UserInfo } from "../modules/UserInfo";

const defaultState = (): UserInfo => (new UserInfo());
export const userInfoReducer = (
  state = defaultState(),
  action: GenericAction<string, any>
) => {
  let newState = state;
  const data = action.data;
  switch (action.type) {
    case ActionType.Login:
      newState.hasLoggedIn = true;
      return { ...newState }
    case ActionType.Logout:
      newState.hasLoggedIn = false;
      return { ...newState }
      /*
    case ActionType.Login:
      newState. = data;
      return { ...newState }
    case ActionType.Logout:
      newState. = data;
      return { ...newState }
      */
    case ActionType.IssueLoginLink:
      newState.loginEmail = data;
      return { ...newState }
    case ActionType.SaveGeoLocation:
      newState.geoLocation = data;
      return { ...newState }
    //%%actionType entry%%
    default:
      return state;
  }
};
