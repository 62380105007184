import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
//import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Shop } from '../entity/Shop';
//%%import entry%%

//modules
import ImageUploader from 'react-images-upload';

//elements
import styles from "../css/ShopDetailModal.module.css"
import { Form } from 'react-bootstrap';
import { japanesePrefectures, japaneseCities } from '../constants/japaneseCities';
//%%element import entry%%

interface IState {
  pictures: any[]
  shop: Shop;
  //%%component state entry%%
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  shop: Shop;
  //%%props entry%%
}

class ShopEditModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      shop: props.shop,
      pictures: []
    }
  }
  async componentDidMount() {
    this.props.dispatch({type: ActionType.UpdateShop, data: this.props.shop})
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  handleChange = (ev: any) => {
    const shop = this.state.shop;
    let value = ev.target.value;
    switch (ev.target.name){
      case 'prefId':
        // value = parseInt(value);
    }
    (shop as any)[ev.target.name] = value;
    shop.seqNo = 1;
    this.setState({shop: shop}, () => {
      this.props.dispatch({type: ActionType.UpdateShop, data: shop})
    });
  }
  onDrop = (picture: any) => {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }
  render() {
    return (
      <>
        <img width="100%" src={this.state.shop.imageUrl} alt={this.state.shop.name} />
        <ImageUploader
          withIcon={true}
          buttonText='画像を選択してください'
          onChange={this.onDrop}
          imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
          maxFileSize={5242880}
          defaultImage={this.state.shop.imageUrl}
          withPreview={true}
          singleImage={true}
        />
        {this.state.shop.distanceFromHere ? '現在地から' + this.state.shop.distanceFromHere + 'm' : ''} <br />

        <Form.Group controlId="form.name">
            <Form.Label>店舗名</Form.Label>
            <Form.Control placeholder="" name="name" value={this.state.shop.name} onChange={this.handleChange} />
        </Form.Group>
        {this.renderTime()}
        <Form.Group controlId="form.description">
            <Form.Label>店舗名</Form.Label>
            <Form.Control as="textarea" placeholder="" name="description" value={this.state.shop.description} onChange={this.handleChange} />
        </Form.Group>

        {this.renderMenus()}
        <Form.Group controlId="form.prefId">
            <Form.Label>都道府県</Form.Label>
            <Form.Control as="select" placeholder="" name="prefId" value={this.state.shop.prefId} onChange={this.handleChange}>
              <option value="">--</option>
              {Object.keys(japanesePrefectures).map(prefId => {
                return (
                  <option key={prefId} value={prefId}>{(japanesePrefectures as any)[prefId]}</option>
                )
              })}
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="form.cityId">
            <Form.Label>市区町村</Form.Label>
            <Form.Control as="select" placeholder="" name="cityId" value={this.state.shop.cityId || undefined} onChange={this.handleChange}>
              <option value="">--</option>
              {(japaneseCities as any)[(japanesePrefectures as any)[this.state.shop.prefId]] && Object.keys((japaneseCities as any)[(japanesePrefectures as any)[this.state.shop.prefId]]).map(cityId => {
                return (
                  <option value={cityId}>
                    {(japaneseCities as any)[(japanesePrefectures as any)[this.state.shop.prefId]][cityId].city}
                  </option>
                )
              })}
            </Form.Control>
        </Form.Group>
        <Form.Group controlId="form.address">
            <Form.Label>住所</Form.Label>
            <Form.Control placeholder="" name="address" value={this.state.shop.address || ''} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="form.latitude">
            <Form.Label>緯度</Form.Label>
            <Form.Control placeholder="" name="latitude" value={this.state.shop.latitude} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="form.longitude">
            <Form.Label>経度</Form.Label>
            <Form.Control placeholder="" name="longitude" value={this.state.shop.longitude} onChange={this.handleChange} />
        </Form.Group>

        <div className={styles.subLink}>
          <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/@?api=1&map_action=map&center=' + this.state.shop.latitude + ',' + this.state.shop.longitude}>Googleマップで見る</a>
        </div>
        <Form.Group controlId="form.tel">
            <Form.Label>TEL</Form.Label>
            <Form.Control placeholder="" name="tel" value={this.state.shop.tel} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="form.uberEatsUrl">
            <Form.Label>UberEats URL</Form.Label>
            <Form.Control placeholder="" name="uberEatsUrl" value={this.state.shop.uberEatsUrl || ''} onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="form.demaeKanUrl">
            <Form.Label>出前館URL</Form.Label>
            <Form.Control placeholder="" name="demaeKanUrl" value={this.state.shop.demaeKanUrl || ''} onChange={this.handleChange} />
        </Form.Group>

        {/* %%child component entry%% --> */}
      </>
    );
  }
  renderTime = () => {
    if (this.state.shop.toGoTimeStart && this.state.shop.toGoTimeEnd) {
      return (
        <>
          テイクアウト受付時間 { this.state.shop.toGoTimeStart} -{ this.state.shop.toGoTimeEnd} < br />
        </>
      );
    }
  }
  renderMenus = () => {
    if (this.state.shop.menus) {
      return (
        <table>
          <tbody>
            <tr>
              {this.state.shop.menus.map(menu => {
                return (
                  <td key={menu.id}>
                    <img width="80" src={menu.imageUrl} alt={menu.name} />
                    {menu.name}
                    <span style={{ color: '#f00' }}>{menu.price}（税抜）</span>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      )
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopEditModal);
