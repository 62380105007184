import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import { Shop } from '../entity/Shop';
import { Button } from 'react-bootstrap';

//elements
import styles from "../css/ShopDetailModal.module.css"
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  shop: Shop;
}

class ShopDetailModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  call = () => {
    window.location.href = 'tel:' + this.props.shop.tel;
  }
  render() {
    return (
      <>
        <img width="100%" src={this.props.shop.imageUrl} alt={this.props.shop.name} />
        {this.props.shop.distanceFromHere ? '現在地から' + this.props.shop.distanceFromHere + 'm' : ''} <br />

        <b>{this.props.shop.name}</b><br />
        {this.renderTime()}
        {this.props.shop.description}<br />

        {this.renderMenus()}
        <div className={styles.address}>
        {this.props.shop.address}
        </div>
        <div className={styles.subLink}>
          <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/@?api=1&map_action=map&center=' + this.props.shop.latitude + ',' + this.props.shop.longitude}>Googleマップで見る</a>
        </div>
        <Button variant="dark" block={true} onClick={this.call} >電話する</Button>
        <div className={styles.centerNotice}>便利に出前をするなら！</div>
        
        <div className={styles.buttons}>
        <Button variant="light">UBERイーツで注文</Button>
        <Button variant="light">出前館で注文</Button>
        </div>

        {/* %%child component entry%% --> */}
      </>
    );
  }
  renderTime = () => {
    if (this.props.shop.toGoTimeStart && this.props.shop.toGoTimeEnd) {
      return (
        <>
          テイクアウト受付時間 { this.props.shop.toGoTimeStart} -{ this.props.shop.toGoTimeEnd} < br />
        </>
      );
    }
  }
  renderMenus = () => {
    if (this.props.shop.menus) {
      return (
        <table>
          <tbody>
            <tr>
              {this.props.shop.menus.map(menu => {
                return (
                  <td key={menu.id}>
                    <img width="80" src={menu.imageUrl} alt={menu.name} />
                    {menu.name}
                    <span style={{ color: '#f00' }}>{menu.price}（税抜）</span>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      )
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopDetailModal);
