export const japaneseCityInfoByCityId = (cityId: string) => {
    let exists = false;
    let pref = '';
    Object.keys(japaneseCities).forEach(_pref => {
        if (Object.keys((japaneseCities as any)[_pref]).indexOf(cityId) > -1){
            pref = _pref;
            return exists = true;
        }
    })
    if (exists){
        return (japaneseCities as any)[pref][cityId];
    }
    return null;
}
export const japaneseDistricts = {
    北海道: [
        1
    ],
    東北: [
        2,3,4,5,6,7
    ],
    関東: [
        8,9,10,11,12,13,14
    ],
    北陸: [
        16,17,18
    ],
    中部: [
        21,22,23
    ],
    甲信越: [
        15,19,20
    ],
    関西: [
        24,25,26,27,28,29,30
    ],
    '中国・四国': [
        31,32,33,34,35,36,37,38,39
    ],
    九州: [
        40,41,42,43,44,45,46
    ],
    沖縄: [
        47
    ],
}
export const japanesePrefectures = {
    1: '北海道',
    2: '青森',
    3:'岩手',
    4:'宮城',
    5:'秋田',
    6:'山形',
    7:'福島',
    8:'茨城',
    9:'栃木',
    10:'群馬',
    11:'埼玉',
    12:'千葉',
    13:'東京',
    14:'神奈川',
    15:'新潟',
    16:'富山',
    17:'石川',
    18:'福井',
    19:'山梨',
    20:'長野',
    21:'岐阜',
    22:'静岡',
    23:'愛知',
    24:'三重',
    25:'滋賀',
    26:'京都',
    27:'大阪',
    28:'兵庫',
    29:'奈良',
    30:'和歌山',
    31:'鳥取',
    32:'島根',
    33:'岡山',
    34:'広島',
    35:'山口',
    36:'徳島',
    37:'香川',
    38:'愛媛',
    39:'高知',
    40:'福岡',
    41:'佐賀',
    42:'長崎',
    43:'熊本',
    44:'大分',
    45:'宮崎',
    46:'鹿児島',
    47:'沖縄'
}
export const japaneseCities = {
    "北海道":{
        "011002":{
            "cityId":"011002",
            "pref":"北海道",
            "city":"札幌市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｻｯﾎﾟﾛｼ"
        },
        "012025":{
            "cityId":"012025",
            "pref":"北海道",
            "city":"函館市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾊｺﾀﾞﾃｼ"
        },
        "012033":{
            "cityId":"012033",
            "pref":"北海道",
            "city":"小樽市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾀﾙｼ"
        },
        "012041":{
            "cityId":"012041",
            "pref":"北海道",
            "city":"旭川市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｻﾋｶﾜｼ"
        },
        "012050":{
            "cityId":"012050",
            "pref":"北海道",
            "city":"室蘭市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾑﾛﾗﾝｼ"
        },
        "012068":{
            "cityId":"012068",
            "pref":"北海道",
            "city":"釧路市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸｼﾛｼ"
        },
        "012076":{
            "cityId":"012076",
            "pref":"北海道",
            "city":"帯広市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾋﾞﾋﾛｼ"
        },
        "012084":{
            "cityId":"012084",
            "pref":"北海道",
            "city":"北見市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷﾀﾐｼ"
        },
        "012092":{
            "cityId":"012092",
            "pref":"北海道",
            "city":"夕張市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾕｳﾊﾞﾘｼ"
        },
        "012106":{
            "cityId":"012106",
            "pref":"北海道",
            "city":"岩見沢市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｲﾜﾐｻﾞﾜｼ"
        },
        "012114":{
            "cityId":"012114",
            "pref":"北海道",
            "city":"網走市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱﾊﾞｼﾘｼ"
        },
        "012122":{
            "cityId":"012122",
            "pref":"北海道",
            "city":"留萌市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾙﾓｲｼ"
        },
        "012131":{
            "cityId":"012131",
            "pref":"北海道",
            "city":"苫小牧市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾏｺﾏｲｼ"
        },
        "012149":{
            "cityId":"012149",
            "pref":"北海道",
            "city":"稚内市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾜｯｶﾅｲｼ"
        },
        "012157":{
            "cityId":"012157",
            "pref":"北海道",
            "city":"美唄市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾞﾊﾞｲｼ"
        },
        "012165":{
            "cityId":"012165",
            "pref":"北海道",
            "city":"芦別市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｼﾍﾞﾂｼ"
        },
        "012173":{
            "cityId":"012173",
            "pref":"北海道",
            "city":"江別市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴﾍﾞﾂｼ"
        },
        "012181":{
            "cityId":"012181",
            "pref":"北海道",
            "city":"赤平市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｶﾋﾞﾗｼ"
        },
        "012190":{
            "cityId":"012190",
            "pref":"北海道",
            "city":"紋別市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾓﾝﾍﾞﾂｼ"
        },
        "012203":{
            "cityId":"012203",
            "pref":"北海道",
            "city":"士別市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾍﾞﾂｼ"
        },
        "012211":{
            "cityId":"012211",
            "pref":"北海道",
            "city":"名寄市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅﾖﾛｼ"
        },
        "012220":{
            "cityId":"012220",
            "pref":"北海道",
            "city":"三笠市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾐｶｻｼ"
        },
        "012238":{
            "cityId":"012238",
            "pref":"北海道",
            "city":"根室市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾈﾑﾛｼ"
        },
        "012246":{
            "cityId":"012246",
            "pref":"北海道",
            "city":"千歳市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾁﾄｾｼ"
        },
        "012254":{
            "cityId":"012254",
            "pref":"北海道",
            "city":"滝川市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾀｷｶﾜｼ"
        },
        "012262":{
            "cityId":"012262",
            "pref":"北海道",
            "city":"砂川市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｽﾅｶﾞﾜｼ"
        },
        "012271":{
            "cityId":"012271",
            "pref":"北海道",
            "city":"歌志内市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｳﾀｼﾅｲｼ"
        },
        "012289":{
            "cityId":"012289",
            "pref":"北海道",
            "city":"深川市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾌｶｶﾞﾜｼ"
        },
        "012297":{
            "cityId":"012297",
            "pref":"北海道",
            "city":"富良野市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾌﾗﾉｼ"
        },
        "012301":{
            "cityId":"012301",
            "pref":"北海道",
            "city":"登別市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾉﾎﾞﾘﾍﾞﾂｼ"
        },
        "012319":{
            "cityId":"012319",
            "pref":"北海道",
            "city":"恵庭市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴﾆﾜｼ"
        },
        "012335":{
            "cityId":"012335",
            "pref":"北海道",
            "city":"伊達市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾀﾞﾃｼ"
        },
        "012343":{
            "cityId":"012343",
            "pref":"北海道",
            "city":"北広島市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷﾀﾋﾛｼﾏｼ"
        },
        "012351":{
            "cityId":"012351",
            "pref":"北海道",
            "city":"石狩市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｲｼｶﾘｼ"
        },
        "012360":{
            "cityId":"012360",
            "pref":"北海道",
            "city":"北斗市",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾎｸﾄｼ"
        },
        "013030":{
            "cityId":"013030",
            "pref":"北海道",
            "city":"当別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄｳﾍﾞﾂﾁｮｳ"
        },
        "013048":{
            "cityId":"013048",
            "pref":"北海道",
            "city":"新篠津村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾝｼﾉﾂﾑﾗ"
        },
        "013315":{
            "cityId":"013315",
            "pref":"北海道",
            "city":"松前町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾏﾂﾏｴﾁｮｳ"
        },
        "013323":{
            "cityId":"013323",
            "pref":"北海道",
            "city":"福島町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾌｸｼﾏﾁｮｳ"
        },
        "013331":{
            "cityId":"013331",
            "pref":"北海道",
            "city":"知内町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾘｳﾁﾁｮｳ"
        },
        "013340":{
            "cityId":"013340",
            "pref":"北海道",
            "city":"木古内町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷｺﾅｲﾁｮｳ"
        },
        "013374":{
            "cityId":"013374",
            "pref":"北海道",
            "city":"七飯町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅﾅｴﾁｮｳ"
        },
        "013439":{
            "cityId":"013439",
            "pref":"北海道",
            "city":"鹿部町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼｶﾍﾞﾁｮｳ"
        },
        "013455":{
            "cityId":"013455",
            "pref":"北海道",
            "city":"森町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾓﾘﾏﾁ"
        },
        "013463":{
            "cityId":"013463",
            "pref":"北海道",
            "city":"八雲町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾔｸﾓﾁｮｳ"
        },
        "013471":{
            "cityId":"013471",
            "pref":"北海道",
            "city":"長万部町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｼｬﾏﾝﾍﾞﾁｮｳ"
        },
        "013617":{
            "cityId":"013617",
            "pref":"北海道",
            "city":"江差町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴｻｼﾁｮｳ"
        },
        "013625":{
            "cityId":"013625",
            "pref":"北海道",
            "city":"上ノ国町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾐﾉｸﾆﾁｮｳ"
        },
        "013633":{
            "cityId":"013633",
            "pref":"北海道",
            "city":"厚沢部町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｯｻﾌﾞﾁｮｳ"
        },
        "013641":{
            "cityId":"013641",
            "pref":"北海道",
            "city":"乙部町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾄﾍﾞﾁｮｳ"
        },
        "013676":{
            "cityId":"013676",
            "pref":"北海道",
            "city":"奥尻町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｸｼﾘﾁｮｳ"
        },
        "013706":{
            "cityId":"013706",
            "pref":"北海道",
            "city":"今金町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｲﾏｶﾈﾁｮｳ"
        },
        "013714":{
            "cityId":"013714",
            "pref":"北海道",
            "city":"せたな町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｾﾀﾅﾁｮｳ"
        },
        "013919":{
            "cityId":"013919",
            "pref":"北海道",
            "city":"島牧村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾏﾏｷﾑﾗ"
        },
        "013927":{
            "cityId":"013927",
            "pref":"北海道",
            "city":"寿都町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｽｯﾂﾁｮｳ"
        },
        "013935":{
            "cityId":"013935",
            "pref":"北海道",
            "city":"黒松内町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸﾛﾏﾂﾅｲﾁｮｳ"
        },
        "013943":{
            "cityId":"013943",
            "pref":"北海道",
            "city":"蘭越町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾗﾝｺｼﾁｮｳ"
        },
        "013951":{
            "cityId":"013951",
            "pref":"北海道",
            "city":"ニセコ町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾆｾｺﾁｮｳ"
        },
        "013960":{
            "cityId":"013960",
            "pref":"北海道",
            "city":"真狩村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾏｯｶﾘﾑﾗ"
        },
        "013978":{
            "cityId":"013978",
            "pref":"北海道",
            "city":"留寿都村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾙｽﾂﾑﾗ"
        },
        "013986":{
            "cityId":"013986",
            "pref":"北海道",
            "city":"喜茂別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷﾓﾍﾞﾂﾁｮｳ"
        },
        "013994":{
            "cityId":"013994",
            "pref":"北海道",
            "city":"京極町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷｮｳｺﾞｸﾁｮｳ"
        },
        "014001":{
            "cityId":"014001",
            "pref":"北海道",
            "city":"倶知安町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸｯﾁｬﾝﾁｮｳ"
        },
        "014010":{
            "cityId":"014010",
            "pref":"北海道",
            "city":"共和町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷｮｳﾜﾁｮｳ"
        },
        "014028":{
            "cityId":"014028",
            "pref":"北海道",
            "city":"岩内町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｲﾜﾅｲﾁｮｳ"
        },
        "014036":{
            "cityId":"014036",
            "pref":"北海道",
            "city":"泊村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾏﾘﾑﾗ"
        },
        "014044":{
            "cityId":"014044",
            "pref":"北海道",
            "city":"神恵内村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾓｴﾅｲﾑﾗ"
        },
        "014052":{
            "cityId":"014052",
            "pref":"北海道",
            "city":"積丹町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼｬｺﾀﾝﾁｮｳ"
        },
        "014061":{
            "cityId":"014061",
            "pref":"北海道",
            "city":"古平町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾌﾙﾋﾞﾗﾁｮｳ"
        },
        "014079":{
            "cityId":"014079",
            "pref":"北海道",
            "city":"仁木町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾆｷﾁｮｳ"
        },
        "014087":{
            "cityId":"014087",
            "pref":"北海道",
            "city":"余市町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾖｲﾁﾁｮｳ"
        },
        "014095":{
            "cityId":"014095",
            "pref":"北海道",
            "city":"赤井川村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｶｲｶﾞﾜﾑﾗ"
        },
        "014231":{
            "cityId":"014231",
            "pref":"北海道",
            "city":"南幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅﾝﾎﾟﾛﾁｮｳ"
        },
        "014249":{
            "cityId":"014249",
            "pref":"北海道",
            "city":"奈井江町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｲｴﾁｮｳ"
        },
        "014257":{
            "cityId":"014257",
            "pref":"北海道",
            "city":"上砂川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾐｽﾅｶﾞﾜﾁｮｳ"
        },
        "014273":{
            "cityId":"014273",
            "pref":"北海道",
            "city":"由仁町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾕﾆﾁｮｳ"
        },
        "014281":{
            "cityId":"014281",
            "pref":"北海道",
            "city":"長沼町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶﾞﾇﾏﾁｮｳ"
        },
        "014290":{
            "cityId":"014290",
            "pref":"北海道",
            "city":"栗山町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸﾘﾔﾏﾁｮｳ"
        },
        "014303":{
            "cityId":"014303",
            "pref":"北海道",
            "city":"月形町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾂｷｶﾞﾀﾁｮｳ"
        },
        "014311":{
            "cityId":"014311",
            "pref":"北海道",
            "city":"浦臼町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｳﾗｳｽﾁｮｳ"
        },
        "014320":{
            "cityId":"014320",
            "pref":"北海道",
            "city":"新十津川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾝﾄﾂｶﾜﾁｮｳ"
        },
        "014338":{
            "cityId":"014338",
            "pref":"北海道",
            "city":"妹背牛町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾓｾｳｼﾁｮｳ"
        },
        "014346":{
            "cityId":"014346",
            "pref":"北海道",
            "city":"秩父別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾁｯﾌﾟﾍﾞﾂﾁｮｳ"
        },
        "014362":{
            "cityId":"014362",
            "pref":"北海道",
            "city":"雨竜町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｳﾘｭｳﾁｮｳ"
        },
        "014371":{
            "cityId":"014371",
            "pref":"北海道",
            "city":"北竜町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾎｸﾘｭｳﾁｮｳ"
        },
        "014389":{
            "cityId":"014389",
            "pref":"北海道",
            "city":"沼田町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾇﾏﾀﾁｮｳ"
        },
        "014524":{
            "cityId":"014524",
            "pref":"北海道",
            "city":"鷹栖町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾀｶｽﾁｮｳ"
        },
        "014532":{
            "cityId":"014532",
            "pref":"北海道",
            "city":"東神楽町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋｶﾞｼｶｸﾞﾗﾁｮｳ"
        },
        "014541":{
            "cityId":"014541",
            "pref":"北海道",
            "city":"当麻町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄｳﾏﾁｮｳ"
        },
        "014559":{
            "cityId":"014559",
            "pref":"北海道",
            "city":"比布町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾟｯﾌﾟﾁｮｳ"
        },
        "014567":{
            "cityId":"014567",
            "pref":"北海道",
            "city":"愛別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｲﾍﾞﾂﾁｮｳ"
        },
        "014575":{
            "cityId":"014575",
            "pref":"北海道",
            "city":"上川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾐｶﾜﾁｮｳ"
        },
        "014583":{
            "cityId":"014583",
            "pref":"北海道",
            "city":"東川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋｶﾞｼｶﾜﾁｮｳ"
        },
        "014591":{
            "cityId":"014591",
            "pref":"北海道",
            "city":"美瑛町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾞｴｲﾁｮｳ"
        },
        "014605":{
            "cityId":"014605",
            "pref":"北海道",
            "city":"上富良野町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾐﾌﾗﾉﾁｮｳ"
        },
        "014613":{
            "cityId":"014613",
            "pref":"北海道",
            "city":"中富良野町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶﾌﾗﾉﾁｮｳ"
        },
        "014621":{
            "cityId":"014621",
            "pref":"北海道",
            "city":"南富良野町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾐﾅﾐﾌﾗﾉﾁｮｳ"
        },
        "014630":{
            "cityId":"014630",
            "pref":"北海道",
            "city":"占冠村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾑｶｯﾌﾟﾑﾗ"
        },
        "014648":{
            "cityId":"014648",
            "pref":"北海道",
            "city":"和寒町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾜｯｻﾑﾁｮｳ"
        },
        "014656":{
            "cityId":"014656",
            "pref":"北海道",
            "city":"剣淵町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｹﾝﾌﾞﾁﾁｮｳ"
        },
        "014681":{
            "cityId":"014681",
            "pref":"北海道",
            "city":"下川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾓｶﾜﾁｮｳ"
        },
        "014699":{
            "cityId":"014699",
            "pref":"北海道",
            "city":"美深町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾞﾌｶﾁｮｳ"
        },
        "014702":{
            "cityId":"014702",
            "pref":"北海道",
            "city":"音威子府村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾄｲﾈｯﾌﾟﾑﾗ"
        },
        "014711":{
            "cityId":"014711",
            "pref":"北海道",
            "city":"中川町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶｶﾞﾜﾁｮｳ"
        },
        "014729":{
            "cityId":"014729",
            "pref":"北海道",
            "city":"幌加内町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾎﾛｶﾅｲﾁｮｳ"
        },
        "014818":{
            "cityId":"014818",
            "pref":"北海道",
            "city":"増毛町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾏｼｹﾁｮｳ"
        },
        "014826":{
            "cityId":"014826",
            "pref":"北海道",
            "city":"小平町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾋﾞﾗﾁｮｳ"
        },
        "014834":{
            "cityId":"014834",
            "pref":"北海道",
            "city":"苫前町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾏﾏｴﾁｮｳ"
        },
        "014842":{
            "cityId":"014842",
            "pref":"北海道",
            "city":"羽幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾊﾎﾞﾛﾁｮｳ"
        },
        "014851":{
            "cityId":"014851",
            "pref":"北海道",
            "city":"初山別村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼｮｻﾝﾍﾞﾂﾑﾗ"
        },
        "014869":{
            "cityId":"014869",
            "pref":"北海道",
            "city":"遠別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴﾝﾍﾞﾂﾁｮｳ"
        },
        "014877":{
            "cityId":"014877",
            "pref":"北海道",
            "city":"天塩町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾃｼｵﾁｮｳ"
        },
        "015113":{
            "cityId":"015113",
            "pref":"北海道",
            "city":"猿払村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｻﾙﾌﾂﾑﾗ"
        },
        "015121":{
            "cityId":"015121",
            "pref":"北海道",
            "city":"浜頓別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾊﾏﾄﾝﾍﾞﾂﾁｮｳ"
        },
        "015130":{
            "cityId":"015130",
            "pref":"北海道",
            "city":"中頓別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶﾄﾝﾍﾞﾂﾁｮｳ"
        },
        "015148":{
            "cityId":"015148",
            "pref":"北海道",
            "city":"枝幸町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴｻｼﾁｮｳ"
        },
        "015164":{
            "cityId":"015164",
            "pref":"北海道",
            "city":"豊富町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾖﾄﾐﾁｮｳ"
        },
        "015172":{
            "cityId":"015172",
            "pref":"北海道",
            "city":"礼文町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾚﾌﾞﾝﾁｮｳ"
        },
        "015181":{
            "cityId":"015181",
            "pref":"北海道",
            "city":"利尻町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾘｼﾘﾁｮｳ"
        },
        "015199":{
            "cityId":"015199",
            "pref":"北海道",
            "city":"利尻富士町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾘｼﾘﾌｼﾞﾁｮｳ"
        },
        "015202":{
            "cityId":"015202",
            "pref":"北海道",
            "city":"幌延町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾎﾛﾉﾍﾞﾁｮｳ"
        },
        "015431":{
            "cityId":"015431",
            "pref":"北海道",
            "city":"美幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾞﾎﾛﾁｮｳ"
        },
        "015440":{
            "cityId":"015440",
            "pref":"北海道",
            "city":"津別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾂﾍﾞﾂﾁｮｳ"
        },
        "015458":{
            "cityId":"015458",
            "pref":"北海道",
            "city":"斜里町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼｬﾘﾁｮｳ"
        },
        "015466":{
            "cityId":"015466",
            "pref":"北海道",
            "city":"清里町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｷﾖｻﾄﾁｮｳ"
        },
        "015474":{
            "cityId":"015474",
            "pref":"北海道",
            "city":"小清水町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｺｼﾐｽﾞﾁｮｳ"
        },
        "015491":{
            "cityId":"015491",
            "pref":"北海道",
            "city":"訓子府町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸﾝﾈｯﾌﾟﾁｮｳ"
        },
        "015504":{
            "cityId":"015504",
            "pref":"北海道",
            "city":"置戸町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｹﾄﾁｮｳ"
        },
        "015521":{
            "cityId":"015521",
            "pref":"北海道",
            "city":"佐呂間町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｻﾛﾏﾁｮｳ"
        },
        "015555":{
            "cityId":"015555",
            "pref":"北海道",
            "city":"遠軽町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴﾝｶﾞﾙﾁｮｳ"
        },
        "015598":{
            "cityId":"015598",
            "pref":"北海道",
            "city":"湧別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾕｳﾍﾞﾂﾁｮｳ"
        },
        "015601":{
            "cityId":"015601",
            "pref":"北海道",
            "city":"滝上町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾀｷﾉｳｴﾁｮｳ"
        },
        "015610":{
            "cityId":"015610",
            "pref":"北海道",
            "city":"興部町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｺｯﾍﾟﾁｮｳ"
        },
        "015628":{
            "cityId":"015628",
            "pref":"北海道",
            "city":"西興部村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾆｼｵｺｯﾍﾟﾑﾗ"
        },
        "015636":{
            "cityId":"015636",
            "pref":"北海道",
            "city":"雄武町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｳﾑﾁｮｳ"
        },
        "015644":{
            "cityId":"015644",
            "pref":"北海道",
            "city":"大空町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵｵｿﾞﾗﾁｮｳ"
        },
        "015717":{
            "cityId":"015717",
            "pref":"北海道",
            "city":"豊浦町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾖｳﾗﾁｮｳ"
        },
        "015750":{
            "cityId":"015750",
            "pref":"北海道",
            "city":"壮瞥町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｿｳﾍﾞﾂﾁｮｳ"
        },
        "015784":{
            "cityId":"015784",
            "pref":"北海道",
            "city":"白老町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾗｵｲﾁｮｳ"
        },
        "015814":{
            "cityId":"015814",
            "pref":"北海道",
            "city":"厚真町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱﾂﾏﾁｮｳ"
        },
        "015849":{
            "cityId":"015849",
            "pref":"北海道",
            "city":"洞爺湖町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄｳﾔｺﾁｮｳ"
        },
        "015857":{
            "cityId":"015857",
            "pref":"北海道",
            "city":"安平町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱﾋﾞﾗﾁｮｳ"
        },
        "015865":{
            "cityId":"015865",
            "pref":"北海道",
            "city":"むかわ町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾑｶﾜﾁｮｳ"
        },
        "016012":{
            "cityId":"016012",
            "pref":"北海道",
            "city":"日高町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾀﾞｶﾁｮｳ"
        },
        "016021":{
            "cityId":"016021",
            "pref":"北海道",
            "city":"平取町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾞﾗﾄﾘﾁｮｳ"
        },
        "016047":{
            "cityId":"016047",
            "pref":"北海道",
            "city":"新冠町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾆｲｶｯﾌﾟﾁｮｳ"
        },
        "016071":{
            "cityId":"016071",
            "pref":"北海道",
            "city":"浦河町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｳﾗｶﾜﾁｮｳ"
        },
        "016080":{
            "cityId":"016080",
            "pref":"北海道",
            "city":"様似町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｻﾏﾆﾁｮｳ"
        },
        "016098":{
            "cityId":"016098",
            "pref":"北海道",
            "city":"えりも町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｴﾘﾓﾁｮｳ"
        },
        "016101":{
            "cityId":"016101",
            "pref":"北海道",
            "city":"新ひだか町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾝﾋﾀﾞｶﾁｮｳ"
        },
        "016314":{
            "cityId":"016314",
            "pref":"北海道",
            "city":"音更町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｵﾄﾌｹﾁｮｳ"
        },
        "016322":{
            "cityId":"016322",
            "pref":"北海道",
            "city":"士幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾎﾛﾁｮｳ"
        },
        "016331":{
            "cityId":"016331",
            "pref":"北海道",
            "city":"上士幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｶﾐｼﾎﾛﾁｮｳ"
        },
        "016349":{
            "cityId":"016349",
            "pref":"北海道",
            "city":"鹿追町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼｶｵｲﾁｮｳ"
        },
        "016357":{
            "cityId":"016357",
            "pref":"北海道",
            "city":"新得町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾝﾄｸﾁｮｳ"
        },
        "016365":{
            "cityId":"016365",
            "pref":"北海道",
            "city":"清水町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾐｽﾞﾁｮｳ"
        },
        "016373":{
            "cityId":"016373",
            "pref":"北海道",
            "city":"芽室町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾒﾑﾛﾁｮｳ"
        },
        "016381":{
            "cityId":"016381",
            "pref":"北海道",
            "city":"中札内村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶｻﾂﾅｲﾑﾗ"
        },
        "016390":{
            "cityId":"016390",
            "pref":"北海道",
            "city":"更別村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｻﾗﾍﾞﾂﾑﾗ"
        },
        "016411":{
            "cityId":"016411",
            "pref":"北海道",
            "city":"大樹町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾀｲｷﾁｮｳ"
        },
        "016420":{
            "cityId":"016420",
            "pref":"北海道",
            "city":"広尾町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾋﾛｵﾁｮｳ"
        },
        "016438":{
            "cityId":"016438",
            "pref":"北海道",
            "city":"幕別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾏｸﾍﾞﾂﾁｮｳ"
        },
        "016446":{
            "cityId":"016446",
            "pref":"北海道",
            "city":"池田町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｲｹﾀﾞﾁｮｳ"
        },
        "016454":{
            "cityId":"016454",
            "pref":"北海道",
            "city":"豊頃町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾄﾖｺﾛﾁｮｳ"
        },
        "016462":{
            "cityId":"016462",
            "pref":"北海道",
            "city":"本別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾎﾝﾍﾞﾂﾁｮｳ"
        },
        "016471":{
            "cityId":"016471",
            "pref":"北海道",
            "city":"足寄町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｼｮﾛﾁｮｳ"
        },
        "016489":{
            "cityId":"016489",
            "pref":"北海道",
            "city":"陸別町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾘｸﾍﾞﾂﾁｮｳ"
        },
        "016497":{
            "cityId":"016497",
            "pref":"北海道",
            "city":"浦幌町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｳﾗﾎﾛﾁｮｳ"
        },
        "016616":{
            "cityId":"016616",
            "pref":"北海道",
            "city":"釧路町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｸｼﾛﾁｮｳ"
        },
        "016624":{
            "cityId":"016624",
            "pref":"北海道",
            "city":"厚岸町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｱｯｹｼﾁｮｳ"
        },
        "016632":{
            "cityId":"016632",
            "pref":"北海道",
            "city":"浜中町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾊﾏﾅｶﾁｮｳ"
        },
        "016641":{
            "cityId":"016641",
            "pref":"北海道",
            "city":"標茶町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾍﾞﾁｬﾁｮｳ"
        },
        "016659":{
            "cityId":"016659",
            "pref":"北海道",
            "city":"弟子屈町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾃｼｶｶﾞﾁｮｳ"
        },
        "016675":{
            "cityId":"016675",
            "pref":"北海道",
            "city":"鶴居村",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾂﾙｲﾑﾗ"
        },
        "016683":{
            "cityId":"016683",
            "pref":"北海道",
            "city":"白糠町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾗﾇｶﾁｮｳ"
        },
        "016918":{
            "cityId":"016918",
            "pref":"北海道",
            "city":"別海町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾍﾞﾂｶｲﾁｮｳ"
        },
        "016926":{
            "cityId":"016926",
            "pref":"北海道",
            "city":"中標津町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾅｶｼﾍﾞﾂﾁｮｳ"
        },
        "016934":{
            "cityId":"016934",
            "pref":"北海道",
            "city":"標津町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ｼﾍﾞﾂﾁｮｳ"
        },
        "016942":{
            "cityId":"016942",
            "pref":"北海道",
            "city":"羅臼町",
            "prefKana":"ﾎｯｶｲﾄﾞｳ",
            "cityKana":"ﾗｳｽﾁｮｳ"
        }
    },
    "青森":{
        "020001":{
            "cityId":"020001",
            "pref":"青森県",
            "city":"",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":""
        },
        "022012":{
            "cityId":"022012",
            "pref":"青森県",
            "city":"青森市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｱｵﾓﾘｼ"
        },
        "022021":{
            "cityId":"022021",
            "pref":"青森県",
            "city":"弘前市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾋﾛｻｷｼ"
        },
        "022039":{
            "cityId":"022039",
            "pref":"青森県",
            "city":"八戸市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾊﾁﾉﾍｼ"
        },
        "022047":{
            "cityId":"022047",
            "pref":"青森県",
            "city":"黒石市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｸﾛｲｼｼ"
        },
        "022055":{
            "cityId":"022055",
            "pref":"青森県",
            "city":"五所川原市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｺﾞｼｮｶﾞﾜﾗｼ"
        },
        "022063":{
            "cityId":"022063",
            "pref":"青森県",
            "city":"十和田市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾄﾜﾀﾞｼ"
        },
        "022071":{
            "cityId":"022071",
            "pref":"青森県",
            "city":"三沢市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾐｻﾜｼ"
        },
        "022080":{
            "cityId":"022080",
            "pref":"青森県",
            "city":"むつ市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾑﾂｼ"
        },
        "022098":{
            "cityId":"022098",
            "pref":"青森県",
            "city":"つがる市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾂｶﾞﾙｼ"
        },
        "022101":{
            "cityId":"022101",
            "pref":"青森県",
            "city":"平川市",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾋﾗｶﾜｼ"
        },
        "023019":{
            "cityId":"023019",
            "pref":"青森県",
            "city":"平内町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾋﾗﾅｲﾏﾁ"
        },
        "023035":{
            "cityId":"023035",
            "pref":"青森県",
            "city":"今別町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｲﾏﾍﾞﾂﾏﾁ"
        },
        "023043":{
            "cityId":"023043",
            "pref":"青森県",
            "city":"蓬田村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾖﾓｷﾞﾀﾑﾗ"
        },
        "023078":{
            "cityId":"023078",
            "pref":"青森県",
            "city":"外ヶ浜町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｿﾄｶﾞﾊﾏﾏﾁ"
        },
        "023213":{
            "cityId":"023213",
            "pref":"青森県",
            "city":"鰺ヶ沢町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｱｼﾞｶﾞｻﾜﾏﾁ"
        },
        "023230":{
            "cityId":"023230",
            "pref":"青森県",
            "city":"深浦町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾌｶｳﾗﾏﾁ"
        },
        "023434":{
            "cityId":"023434",
            "pref":"青森県",
            "city":"西目屋村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾆｼﾒﾔﾑﾗ"
        },
        "023612":{
            "cityId":"023612",
            "pref":"青森県",
            "city":"藤崎町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾌｼﾞｻｷﾏﾁ"
        },
        "023621":{
            "cityId":"023621",
            "pref":"青森県",
            "city":"大鰐町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｵｵﾜﾆﾏﾁ"
        },
        "023671":{
            "cityId":"023671",
            "pref":"青森県",
            "city":"田舎館村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｲﾅｶﾀﾞﾃﾑﾗ"
        },
        "023817":{
            "cityId":"023817",
            "pref":"青森県",
            "city":"板柳町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｲﾀﾔﾅｷﾞﾏﾁ"
        },
        "023841":{
            "cityId":"023841",
            "pref":"青森県",
            "city":"鶴田町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾂﾙﾀﾏﾁ"
        },
        "023876":{
            "cityId":"023876",
            "pref":"青森県",
            "city":"中泊町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾅｶﾄﾞﾏﾘﾏﾁ"
        },
        "024015":{
            "cityId":"024015",
            "pref":"青森県",
            "city":"野辺地町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾉﾍｼﾞﾏﾁ"
        },
        "024023":{
            "cityId":"024023",
            "pref":"青森県",
            "city":"七戸町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｼﾁﾉﾍﾏﾁ"
        },
        "024058":{
            "cityId":"024058",
            "pref":"青森県",
            "city":"六戸町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾛｸﾉﾍﾏﾁ"
        },
        "024066":{
            "cityId":"024066",
            "pref":"青森県",
            "city":"横浜町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾖｺﾊﾏﾏﾁ"
        },
        "024082":{
            "cityId":"024082",
            "pref":"青森県",
            "city":"東北町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾄｳﾎｸﾏﾁ"
        },
        "024112":{
            "cityId":"024112",
            "pref":"青森県",
            "city":"六ヶ所村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾛｯｶｼｮﾑﾗ"
        },
        "024121":{
            "cityId":"024121",
            "pref":"青森県",
            "city":"おいらせ町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｵｲﾗｾﾁｮｳ"
        },
        "024236":{
            "cityId":"024236",
            "pref":"青森県",
            "city":"大間町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｵｵﾏﾏﾁ"
        },
        "024244":{
            "cityId":"024244",
            "pref":"青森県",
            "city":"東通村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾋｶﾞｼﾄﾞｵﾘﾑﾗ"
        },
        "024252":{
            "cityId":"024252",
            "pref":"青森県",
            "city":"風間浦村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｶｻﾞﾏｳﾗﾑﾗ"
        },
        "024261":{
            "cityId":"024261",
            "pref":"青森県",
            "city":"佐井村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｻｲﾑﾗ"
        },
        "024414":{
            "cityId":"024414",
            "pref":"青森県",
            "city":"三戸町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｻﾝﾉﾍﾏﾁ"
        },
        "024422":{
            "cityId":"024422",
            "pref":"青森県",
            "city":"五戸町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｺﾞﾉﾍﾏﾁ"
        },
        "024431":{
            "cityId":"024431",
            "pref":"青森県",
            "city":"田子町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾀｯｺﾏﾁ"
        },
        "024457":{
            "cityId":"024457",
            "pref":"青森県",
            "city":"南部町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾅﾝﾌﾞﾁｮｳ"
        },
        "024465":{
            "cityId":"024465",
            "pref":"青森県",
            "city":"階上町",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ﾊｼｶﾐﾁｮｳ"
        },
        "024503":{
            "cityId":"024503",
            "pref":"青森県",
            "city":"新郷村",
            "prefKana":"ｱｵﾓﾘｹﾝ",
            "cityKana":"ｼﾝｺﾞｳﾑﾗ"
        }
    },
    "岩手":{
        "030007":{
            "cityId":"030007",
            "pref":"岩手県",
            "city":"",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":""
        },
        "032018":{
            "cityId":"032018",
            "pref":"岩手県",
            "city":"盛岡市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾓﾘｵｶｼ"
        },
        "032026":{
            "cityId":"032026",
            "pref":"岩手県",
            "city":"宮古市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾐﾔｺｼ"
        },
        "032034":{
            "cityId":"032034",
            "pref":"岩手県",
            "city":"大船渡市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｵｵﾌﾅﾄｼ"
        },
        "032051":{
            "cityId":"032051",
            "pref":"岩手県",
            "city":"花巻市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾊﾅﾏｷｼ"
        },
        "032069":{
            "cityId":"032069",
            "pref":"岩手県",
            "city":"北上市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｷﾀｶﾐｼ"
        },
        "032077":{
            "cityId":"032077",
            "pref":"岩手県",
            "city":"久慈市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｸｼﾞｼ"
        },
        "032085":{
            "cityId":"032085",
            "pref":"岩手県",
            "city":"遠野市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾄｵﾉｼ"
        },
        "032093":{
            "cityId":"032093",
            "pref":"岩手県",
            "city":"一関市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｲﾁﾉｾｷｼ"
        },
        "032107":{
            "cityId":"032107",
            "pref":"岩手県",
            "city":"陸前高田市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾘｸｾﾞﾝﾀｶﾀｼ"
        },
        "032115":{
            "cityId":"032115",
            "pref":"岩手県",
            "city":"釜石市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｶﾏｲｼｼ"
        },
        "032131":{
            "cityId":"032131",
            "pref":"岩手県",
            "city":"二戸市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾆﾉﾍｼ"
        },
        "032140":{
            "cityId":"032140",
            "pref":"岩手県",
            "city":"八幡平市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾊﾁﾏﾝﾀｲｼ"
        },
        "032158":{
            "cityId":"032158",
            "pref":"岩手県",
            "city":"奥州市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｵｳｼｭｳｼ"
        },
        "032166":{
            "cityId":"032166",
            "pref":"岩手県",
            "city":"滝沢市",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾀｷｻﾞﾜｼ"
        },
        "033014":{
            "cityId":"033014",
            "pref":"岩手県",
            "city":"雫石町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｼｽﾞｸｲｼﾁｮｳ"
        },
        "033022":{
            "cityId":"033022",
            "pref":"岩手県",
            "city":"葛巻町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｸｽﾞﾏｷﾏﾁ"
        },
        "033031":{
            "cityId":"033031",
            "pref":"岩手県",
            "city":"岩手町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｲﾜﾃﾏﾁ"
        },
        "033219":{
            "cityId":"033219",
            "pref":"岩手県",
            "city":"紫波町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｼﾜﾁｮｳ"
        },
        "033227":{
            "cityId":"033227",
            "pref":"岩手県",
            "city":"矢巾町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾔﾊﾊﾞﾁｮｳ"
        },
        "033669":{
            "cityId":"033669",
            "pref":"岩手県",
            "city":"西和賀町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾆｼﾜｶﾞﾏﾁ"
        },
        "033812":{
            "cityId":"033812",
            "pref":"岩手県",
            "city":"金ケ崎町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｶﾈｶﾞｻｷﾁｮｳ"
        },
        "034029":{
            "cityId":"034029",
            "pref":"岩手県",
            "city":"平泉町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾋﾗｲｽﾞﾐﾁｮｳ"
        },
        "034410":{
            "cityId":"034410",
            "pref":"岩手県",
            "city":"住田町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｽﾐﾀﾁｮｳ"
        },
        "034614":{
            "cityId":"034614",
            "pref":"岩手県",
            "city":"大槌町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｵｵﾂﾁﾁｮｳ"
        },
        "034827":{
            "cityId":"034827",
            "pref":"岩手県",
            "city":"山田町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾔﾏﾀﾞﾏﾁ"
        },
        "034835":{
            "cityId":"034835",
            "pref":"岩手県",
            "city":"岩泉町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｲﾜｲｽﾞﾐﾁｮｳ"
        },
        "034843":{
            "cityId":"034843",
            "pref":"岩手県",
            "city":"田野畑村",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾀﾉﾊﾀﾑﾗ"
        },
        "034851":{
            "cityId":"034851",
            "pref":"岩手県",
            "city":"普代村",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾌﾀﾞｲﾑﾗ"
        },
        "035017":{
            "cityId":"035017",
            "pref":"岩手県",
            "city":"軽米町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｶﾙﾏｲﾏﾁ"
        },
        "035033":{
            "cityId":"035033",
            "pref":"岩手県",
            "city":"野田村",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾉﾀﾞﾑﾗ"
        },
        "035068":{
            "cityId":"035068",
            "pref":"岩手県",
            "city":"九戸村",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｸﾉﾍﾑﾗ"
        },
        "035076":{
            "cityId":"035076",
            "pref":"岩手県",
            "city":"洋野町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ﾋﾛﾉﾁｮｳ"
        },
        "035246":{
            "cityId":"035246",
            "pref":"岩手県",
            "city":"一戸町",
            "prefKana":"ｲﾜﾃｹﾝ",
            "cityKana":"ｲﾁﾉﾍﾏﾁ"
        }
    },
    "宮城":{
        "040002":{
            "cityId":"040002",
            "pref":"宮城県",
            "city":"",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":""
        },
        "041009":{
            "cityId":"041009",
            "pref":"宮城県",
            "city":"仙台市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｾﾝﾀﾞｲｼ"
        },
        "042021":{
            "cityId":"042021",
            "pref":"宮城県",
            "city":"石巻市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｲｼﾉﾏｷｼ"
        },
        "042030":{
            "cityId":"042030",
            "pref":"宮城県",
            "city":"塩竈市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼｵｶﾞﾏｼ"
        },
        "042056":{
            "cityId":"042056",
            "pref":"宮城県",
            "city":"気仙沼市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｹｾﾝﾇﾏｼ"
        },
        "042064":{
            "cityId":"042064",
            "pref":"宮城県",
            "city":"白石市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼﾛｲｼｼ"
        },
        "042072":{
            "cityId":"042072",
            "pref":"宮城県",
            "city":"名取市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾅﾄﾘｼ"
        },
        "042081":{
            "cityId":"042081",
            "pref":"宮城県",
            "city":"角田市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｶｸﾀﾞｼ"
        },
        "042099":{
            "cityId":"042099",
            "pref":"宮城県",
            "city":"多賀城市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾀｶﾞｼﾞｮｳｼ"
        },
        "042111":{
            "cityId":"042111",
            "pref":"宮城県",
            "city":"岩沼市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｲﾜﾇﾏｼ"
        },
        "042129":{
            "cityId":"042129",
            "pref":"宮城県",
            "city":"登米市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾄﾒｼ"
        },
        "042137":{
            "cityId":"042137",
            "pref":"宮城県",
            "city":"栗原市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｸﾘﾊﾗｼ"
        },
        "042145":{
            "cityId":"042145",
            "pref":"宮城県",
            "city":"東松島市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾋｶﾞｼﾏﾂｼﾏｼ"
        },
        "042153":{
            "cityId":"042153",
            "pref":"宮城県",
            "city":"大崎市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｵｵｻｷｼ"
        },
        "042161":{
            "cityId":"042161",
            "pref":"宮城県",
            "city":"富谷市",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾄﾐﾔｼ"
        },
        "043010":{
            "cityId":"043010",
            "pref":"宮城県",
            "city":"蔵王町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｻﾞｵｳﾏﾁ"
        },
        "043028":{
            "cityId":"043028",
            "pref":"宮城県",
            "city":"七ヶ宿町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼﾁｶｼｭｸﾏﾁ"
        },
        "043214":{
            "cityId":"043214",
            "pref":"宮城県",
            "city":"大河原町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｵｵｶﾞﾜﾗﾏﾁ"
        },
        "043222":{
            "cityId":"043222",
            "pref":"宮城県",
            "city":"村田町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾑﾗﾀﾏﾁ"
        },
        "043231":{
            "cityId":"043231",
            "pref":"宮城県",
            "city":"柴田町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼﾊﾞﾀﾏﾁ"
        },
        "043249":{
            "cityId":"043249",
            "pref":"宮城県",
            "city":"川崎町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｶﾜｻｷﾏﾁ"
        },
        "043419":{
            "cityId":"043419",
            "pref":"宮城県",
            "city":"丸森町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾏﾙﾓﾘﾏﾁ"
        },
        "043613":{
            "cityId":"043613",
            "pref":"宮城県",
            "city":"亘理町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾜﾀﾘﾁｮｳ"
        },
        "043621":{
            "cityId":"043621",
            "pref":"宮城県",
            "city":"山元町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾔﾏﾓﾄﾁｮｳ"
        },
        "044016":{
            "cityId":"044016",
            "pref":"宮城県",
            "city":"松島町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾏﾂｼﾏﾏﾁ"
        },
        "044041":{
            "cityId":"044041",
            "pref":"宮城県",
            "city":"七ヶ浜町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼﾁｶﾞﾊﾏﾏﾁ"
        },
        "044067":{
            "cityId":"044067",
            "pref":"宮城県",
            "city":"利府町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾘﾌﾁｮｳ"
        },
        "044211":{
            "cityId":"044211",
            "pref":"宮城県",
            "city":"大和町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾀｲﾜﾁｮｳ"
        },
        "044229":{
            "cityId":"044229",
            "pref":"宮城県",
            "city":"大郷町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｵｵｻﾄﾁｮｳ"
        },
        "044245":{
            "cityId":"044245",
            "pref":"宮城県",
            "city":"大衡村",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｵｵﾋﾗﾑﾗ"
        },
        "044440":{
            "cityId":"044440",
            "pref":"宮城県",
            "city":"色麻町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｼｶﾏﾁｮｳ"
        },
        "044458":{
            "cityId":"044458",
            "pref":"宮城県",
            "city":"加美町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｶﾐﾏﾁ"
        },
        "045012":{
            "cityId":"045012",
            "pref":"宮城県",
            "city":"涌谷町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾜｸﾔﾁｮｳ"
        },
        "045055":{
            "cityId":"045055",
            "pref":"宮城県",
            "city":"美里町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾐｻﾄﾏﾁ"
        },
        "045811":{
            "cityId":"045811",
            "pref":"宮城県",
            "city":"女川町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ｵﾅｶﾞﾜﾁｮｳ"
        },
        "046060":{
            "cityId":"046060",
            "pref":"宮城県",
            "city":"南三陸町",
            "prefKana":"ﾐﾔｷﾞｹﾝ",
            "cityKana":"ﾐﾅﾐｻﾝﾘｸﾁｮｳ"
        }
    },
    "秋田":{
        "050008":{
            "cityId":"050008",
            "pref":"秋田県",
            "city":"",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":""
        },
        "052019":{
            "cityId":"052019",
            "pref":"秋田県",
            "city":"秋田市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｱｷﾀｼ"
        },
        "052027":{
            "cityId":"052027",
            "pref":"秋田県",
            "city":"能代市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾉｼﾛｼ"
        },
        "052035":{
            "cityId":"052035",
            "pref":"秋田県",
            "city":"横手市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾖｺﾃｼ"
        },
        "052043":{
            "cityId":"052043",
            "pref":"秋田県",
            "city":"大館市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｵｵﾀﾞﾃｼ"
        },
        "052060":{
            "cityId":"052060",
            "pref":"秋田県",
            "city":"男鹿市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｵｶﾞｼ"
        },
        "052078":{
            "cityId":"052078",
            "pref":"秋田県",
            "city":"湯沢市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾕｻﾞﾜｼ"
        },
        "052094":{
            "cityId":"052094",
            "pref":"秋田県",
            "city":"鹿角市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｶﾂﾞﾉｼ"
        },
        "052108":{
            "cityId":"052108",
            "pref":"秋田県",
            "city":"由利本荘市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾕﾘﾎﾝｼﾞｮｳｼ"
        },
        "052116":{
            "cityId":"052116",
            "pref":"秋田県",
            "city":"潟上市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｶﾀｶﾞﾐｼ"
        },
        "052124":{
            "cityId":"052124",
            "pref":"秋田県",
            "city":"大仙市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾀﾞｲｾﾝｼ"
        },
        "052132":{
            "cityId":"052132",
            "pref":"秋田県",
            "city":"北秋田市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｷﾀｱｷﾀｼ"
        },
        "052141":{
            "cityId":"052141",
            "pref":"秋田県",
            "city":"にかほ市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾆｶﾎｼ"
        },
        "052159":{
            "cityId":"052159",
            "pref":"秋田県",
            "city":"仙北市",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｾﾝﾎﾞｸｼ"
        },
        "053031":{
            "cityId":"053031",
            "pref":"秋田県",
            "city":"小坂町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｺｻｶﾏﾁ"
        },
        "053279":{
            "cityId":"053279",
            "pref":"秋田県",
            "city":"上小阿仁村",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｶﾐｺｱﾆﾑﾗ"
        },
        "053465":{
            "cityId":"053465",
            "pref":"秋田県",
            "city":"藤里町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾌｼﾞｻﾄﾏﾁ"
        },
        "053481":{
            "cityId":"053481",
            "pref":"秋田県",
            "city":"三種町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾐﾀﾈﾁｮｳ"
        },
        "053490":{
            "cityId":"053490",
            "pref":"秋田県",
            "city":"八峰町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾊｯﾎﾟｳﾁｮｳ"
        },
        "053619":{
            "cityId":"053619",
            "pref":"秋田県",
            "city":"五城目町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｺﾞｼﾞｮｳﾒﾏﾁ"
        },
        "053635":{
            "cityId":"053635",
            "pref":"秋田県",
            "city":"八郎潟町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾊﾁﾛｳｶﾞﾀﾏﾁ"
        },
        "053660":{
            "cityId":"053660",
            "pref":"秋田県",
            "city":"井川町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｲｶﾜﾏﾁ"
        },
        "053686":{
            "cityId":"053686",
            "pref":"秋田県",
            "city":"大潟村",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｵｵｶﾞﾀﾑﾗ"
        },
        "054348":{
            "cityId":"054348",
            "pref":"秋田県",
            "city":"美郷町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾐｻﾄﾁｮｳ"
        },
        "054631":{
            "cityId":"054631",
            "pref":"秋田県",
            "city":"羽後町",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ｳｺﾞﾏﾁ"
        },
        "054640":{
            "cityId":"054640",
            "pref":"秋田県",
            "city":"東成瀬村",
            "prefKana":"ｱｷﾀｹﾝ",
            "cityKana":"ﾋｶﾞｼﾅﾙｾﾑﾗ"
        }
    },
    "山形":{
        "060003":{
            "cityId":"060003",
            "pref":"山形県",
            "city":"",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":""
        },
        "062014":{
            "cityId":"062014",
            "pref":"山形県",
            "city":"山形市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾔﾏｶﾞﾀｼ"
        },
        "062022":{
            "cityId":"062022",
            "pref":"山形県",
            "city":"米沢市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾖﾈｻﾞﾜｼ"
        },
        "062031":{
            "cityId":"062031",
            "pref":"山形県",
            "city":"鶴岡市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾂﾙｵｶｼ"
        },
        "062049":{
            "cityId":"062049",
            "pref":"山形県",
            "city":"酒田市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｻｶﾀｼ"
        },
        "062057":{
            "cityId":"062057",
            "pref":"山形県",
            "city":"新庄市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｼﾝｼﾞｮｳｼ"
        },
        "062065":{
            "cityId":"062065",
            "pref":"山形県",
            "city":"寒河江市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｻｶﾞｴｼ"
        },
        "062073":{
            "cityId":"062073",
            "pref":"山形県",
            "city":"上山市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｶﾐﾉﾔﾏｼ"
        },
        "062081":{
            "cityId":"062081",
            "pref":"山形県",
            "city":"村山市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾑﾗﾔﾏｼ"
        },
        "062090":{
            "cityId":"062090",
            "pref":"山形県",
            "city":"長井市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾅｶﾞｲｼ"
        },
        "062103":{
            "cityId":"062103",
            "pref":"山形県",
            "city":"天童市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾃﾝﾄﾞｳｼ"
        },
        "062111":{
            "cityId":"062111",
            "pref":"山形県",
            "city":"東根市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾋｶﾞｼﾈｼ"
        },
        "062120":{
            "cityId":"062120",
            "pref":"山形県",
            "city":"尾花沢市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｵﾊﾞﾅｻﾞﾜｼ"
        },
        "062138":{
            "cityId":"062138",
            "pref":"山形県",
            "city":"南陽市",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾅﾝﾖｳｼ"
        },
        "063011":{
            "cityId":"063011",
            "pref":"山形県",
            "city":"山辺町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾔﾏﾉﾍﾞﾏﾁ"
        },
        "063029":{
            "cityId":"063029",
            "pref":"山形県",
            "city":"中山町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾅｶﾔﾏﾏﾁ"
        },
        "063215":{
            "cityId":"063215",
            "pref":"山形県",
            "city":"河北町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｶﾎｸﾁｮｳ"
        },
        "063223":{
            "cityId":"063223",
            "pref":"山形県",
            "city":"西川町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾆｼｶﾜﾏﾁ"
        },
        "063231":{
            "cityId":"063231",
            "pref":"山形県",
            "city":"朝日町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｱｻﾋﾏﾁ"
        },
        "063240":{
            "cityId":"063240",
            "pref":"山形県",
            "city":"大江町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｵｵｴﾏﾁ"
        },
        "063410":{
            "cityId":"063410",
            "pref":"山形県",
            "city":"大石田町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｵｵｲｼﾀﾞﾏﾁ"
        },
        "063614":{
            "cityId":"063614",
            "pref":"山形県",
            "city":"金山町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｶﾈﾔﾏﾏﾁ"
        },
        "063622":{
            "cityId":"063622",
            "pref":"山形県",
            "city":"最上町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾓｶﾞﾐﾏﾁ"
        },
        "063631":{
            "cityId":"063631",
            "pref":"山形県",
            "city":"舟形町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾌﾅｶﾞﾀﾏﾁ"
        },
        "063649":{
            "cityId":"063649",
            "pref":"山形県",
            "city":"真室川町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾏﾑﾛｶﾞﾜﾏﾁ"
        },
        "063657":{
            "cityId":"063657",
            "pref":"山形県",
            "city":"大蔵村",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｵｵｸﾗﾑﾗ"
        },
        "063665":{
            "cityId":"063665",
            "pref":"山形県",
            "city":"鮭川村",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｻｹｶﾞﾜﾑﾗ"
        },
        "063673":{
            "cityId":"063673",
            "pref":"山形県",
            "city":"戸沢村",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾄｻﾞﾜﾑﾗ"
        },
        "063819":{
            "cityId":"063819",
            "pref":"山形県",
            "city":"高畠町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾀｶﾊﾀﾏﾁ"
        },
        "063827":{
            "cityId":"063827",
            "pref":"山形県",
            "city":"川西町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｶﾜﾆｼﾏﾁ"
        },
        "064017":{
            "cityId":"064017",
            "pref":"山形県",
            "city":"小国町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｵｸﾞﾆﾏﾁ"
        },
        "064025":{
            "cityId":"064025",
            "pref":"山形県",
            "city":"白鷹町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｼﾗﾀｶﾏﾁ"
        },
        "064033":{
            "cityId":"064033",
            "pref":"山形県",
            "city":"飯豊町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｲｲﾃﾞﾏﾁ"
        },
        "064262":{
            "cityId":"064262",
            "pref":"山形県",
            "city":"三川町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾐｶﾜﾏﾁ"
        },
        "064289":{
            "cityId":"064289",
            "pref":"山形県",
            "city":"庄内町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ｼﾖｳﾅｲﾏﾁ"
        },
        "064611":{
            "cityId":"064611",
            "pref":"山形県",
            "city":"遊佐町",
            "prefKana":"ﾔﾏｶﾞﾀｹﾝ",
            "cityKana":"ﾕｻﾞﾏﾁ"
        }
    },
    "福島":{
        "070009":{
            "cityId":"070009",
            "pref":"福島県",
            "city":"",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":""
        },
        "072010":{
            "cityId":"072010",
            "pref":"福島県",
            "city":"福島市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾌｸｼﾏｼ"
        },
        "072028":{
            "cityId":"072028",
            "pref":"福島県",
            "city":"会津若松市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｱｲﾂﾞﾜｶﾏﾂｼ"
        },
        "072036":{
            "cityId":"072036",
            "pref":"福島県",
            "city":"郡山市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｺｵﾘﾔﾏｼ"
        },
        "072044":{
            "cityId":"072044",
            "pref":"福島県",
            "city":"いわき市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｲﾜｷｼ"
        },
        "072052":{
            "cityId":"072052",
            "pref":"福島県",
            "city":"白河市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｼﾗｶﾜｼ"
        },
        "072079":{
            "cityId":"072079",
            "pref":"福島県",
            "city":"須賀川市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｽｶｶﾞﾜｼ"
        },
        "072087":{
            "cityId":"072087",
            "pref":"福島県",
            "city":"喜多方市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｷﾀｶﾀｼ"
        },
        "072095":{
            "cityId":"072095",
            "pref":"福島県",
            "city":"相馬市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｿｳﾏｼ"
        },
        "072109":{
            "cityId":"072109",
            "pref":"福島県",
            "city":"二本松市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾆﾎﾝﾏﾂｼ"
        },
        "072117":{
            "cityId":"072117",
            "pref":"福島県",
            "city":"田村市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾀﾑﾗｼ"
        },
        "072125":{
            "cityId":"072125",
            "pref":"福島県",
            "city":"南相馬市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐｿｳﾏｼ"
        },
        "072133":{
            "cityId":"072133",
            "pref":"福島県",
            "city":"伊達市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾀﾞﾃｼ"
        },
        "072141":{
            "cityId":"072141",
            "pref":"福島県",
            "city":"本宮市",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾓﾄﾐﾔｼ"
        },
        "073016":{
            "cityId":"073016",
            "pref":"福島県",
            "city":"桑折町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｺｵﾘﾏﾁ"
        },
        "073032":{
            "cityId":"073032",
            "pref":"福島県",
            "city":"国見町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｸﾆﾐﾏﾁ"
        },
        "073083":{
            "cityId":"073083",
            "pref":"福島県",
            "city":"川俣町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｶﾜﾏﾀﾏﾁ"
        },
        "073229":{
            "cityId":"073229",
            "pref":"福島県",
            "city":"大玉村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｵｵﾀﾏﾑﾗ"
        },
        "073423":{
            "cityId":"073423",
            "pref":"福島県",
            "city":"鏡石町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｶｶﾞﾐｲｼﾏﾁ"
        },
        "073440":{
            "cityId":"073440",
            "pref":"福島県",
            "city":"天栄村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾃﾝｴｲﾑﾗ"
        },
        "073628":{
            "cityId":"073628",
            "pref":"福島県",
            "city":"下郷町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｼﾓｺﾞｳﾏﾁ"
        },
        "073644":{
            "cityId":"073644",
            "pref":"福島県",
            "city":"檜枝岐村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾋﾉｴﾏﾀﾑﾗ"
        },
        "073679":{
            "cityId":"073679",
            "pref":"福島県",
            "city":"只見町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾀﾀﾞﾐﾏﾁ"
        },
        "073687":{
            "cityId":"073687",
            "pref":"福島県",
            "city":"南会津町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐｱｲﾂﾞﾏﾁ"
        },
        "074021":{
            "cityId":"074021",
            "pref":"福島県",
            "city":"北塩原村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｷﾀｼｵﾊﾞﾗﾑﾗ"
        },
        "074055":{
            "cityId":"074055",
            "pref":"福島県",
            "city":"西会津町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾆｼｱｲﾂﾞﾏﾁ"
        },
        "074071":{
            "cityId":"074071",
            "pref":"福島県",
            "city":"磐梯町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾊﾞﾝﾀﾞｲﾏﾁ"
        },
        "074080":{
            "cityId":"074080",
            "pref":"福島県",
            "city":"猪苗代町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｲﾅﾜｼﾛﾏﾁ"
        },
        "074217":{
            "cityId":"074217",
            "pref":"福島県",
            "city":"会津坂下町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｱｲﾂﾞﾊﾞﾝｹﾞﾏﾁ"
        },
        "074225":{
            "cityId":"074225",
            "pref":"福島県",
            "city":"湯川村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾕｶﾞﾜﾑﾗ"
        },
        "074233":{
            "cityId":"074233",
            "pref":"福島県",
            "city":"柳津町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾔﾅｲﾂﾞﾏﾁ"
        },
        "074446":{
            "cityId":"074446",
            "pref":"福島県",
            "city":"三島町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾐｼﾏﾏﾁ"
        },
        "074454":{
            "cityId":"074454",
            "pref":"福島県",
            "city":"金山町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｶﾈﾔﾏﾏﾁ"
        },
        "074462":{
            "cityId":"074462",
            "pref":"福島県",
            "city":"昭和村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｼｮｳﾜﾑﾗ"
        },
        "074471":{
            "cityId":"074471",
            "pref":"福島県",
            "city":"会津美里町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｱｲﾂﾞﾐｻﾄﾏﾁ"
        },
        "074616":{
            "cityId":"074616",
            "pref":"福島県",
            "city":"西郷村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾆｼｺﾞｳﾑﾗ"
        },
        "074641":{
            "cityId":"074641",
            "pref":"福島県",
            "city":"泉崎村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｲｽﾞﾐｻﾞｷﾑﾗ"
        },
        "074659":{
            "cityId":"074659",
            "pref":"福島県",
            "city":"中島村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾅｶｼﾞﾏﾑﾗ"
        },
        "074667":{
            "cityId":"074667",
            "pref":"福島県",
            "city":"矢吹町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾔﾌﾞｷﾏﾁ"
        },
        "074811":{
            "cityId":"074811",
            "pref":"福島県",
            "city":"棚倉町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾀﾅｸﾞﾗﾏﾁ"
        },
        "074829":{
            "cityId":"074829",
            "pref":"福島県",
            "city":"矢祭町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾔﾏﾂﾘﾏﾁ"
        },
        "074837":{
            "cityId":"074837",
            "pref":"福島県",
            "city":"塙町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾊﾅﾜﾏﾁ"
        },
        "074845":{
            "cityId":"074845",
            "pref":"福島県",
            "city":"鮫川村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｻﾒｶﾞﾜﾑﾗ"
        },
        "075019":{
            "cityId":"075019",
            "pref":"福島県",
            "city":"石川町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｲｼｶﾜﾏﾁ"
        },
        "075027":{
            "cityId":"075027",
            "pref":"福島県",
            "city":"玉川村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾀﾏｶﾜﾑﾗ"
        },
        "075035":{
            "cityId":"075035",
            "pref":"福島県",
            "city":"平田村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾋﾗﾀﾑﾗ"
        },
        "075043":{
            "cityId":"075043",
            "pref":"福島県",
            "city":"浅川町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｱｻｶﾜﾏﾁ"
        },
        "075051":{
            "cityId":"075051",
            "pref":"福島県",
            "city":"古殿町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾌﾙﾄﾞﾉﾏﾁ"
        },
        "075213":{
            "cityId":"075213",
            "pref":"福島県",
            "city":"三春町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾐﾊﾙﾏﾁ"
        },
        "075221":{
            "cityId":"075221",
            "pref":"福島県",
            "city":"小野町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｵﾉﾏﾁ"
        },
        "075418":{
            "cityId":"075418",
            "pref":"福島県",
            "city":"広野町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾋﾛﾉﾏﾁ"
        },
        "075426":{
            "cityId":"075426",
            "pref":"福島県",
            "city":"楢葉町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾅﾗﾊﾏﾁ"
        },
        "075434":{
            "cityId":"075434",
            "pref":"福島県",
            "city":"富岡町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾄﾐｵｶﾏﾁ"
        },
        "075442":{
            "cityId":"075442",
            "pref":"福島県",
            "city":"川内村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｶﾜｳﾁﾑﾗ"
        },
        "075451":{
            "cityId":"075451",
            "pref":"福島県",
            "city":"大熊町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｵｵｸﾏﾏﾁ"
        },
        "075469":{
            "cityId":"075469",
            "pref":"福島県",
            "city":"双葉町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾌﾀﾊﾞﾏﾁ"
        },
        "075477":{
            "cityId":"075477",
            "pref":"福島県",
            "city":"浪江町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ﾅﾐｴﾏﾁ"
        },
        "075485":{
            "cityId":"075485",
            "pref":"福島県",
            "city":"葛尾村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｶﾂﾗｵﾑﾗ"
        },
        "075612":{
            "cityId":"075612",
            "pref":"福島県",
            "city":"新地町",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｼﾝﾁﾏﾁ"
        },
        "075647":{
            "cityId":"075647",
            "pref":"福島県",
            "city":"飯舘村",
            "prefKana":"ﾌｸｼﾏｹﾝ",
            "cityKana":"ｲｲﾀﾃﾑﾗ"
        }
    },
    "茨城":{
        "080004":{
            "cityId":"080004",
            "pref":"茨城県",
            "city":"",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":""
        },
        "082015":{
            "cityId":"082015",
            "pref":"茨城県",
            "city":"水戸市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾐﾄｼ"
        },
        "082023":{
            "cityId":"082023",
            "pref":"茨城県",
            "city":"日立市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾋﾀﾁｼ"
        },
        "082031":{
            "cityId":"082031",
            "pref":"茨城県",
            "city":"土浦市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾂﾁｳﾗｼ"
        },
        "082040":{
            "cityId":"082040",
            "pref":"茨城県",
            "city":"古河市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｺｶﾞｼ"
        },
        "082058":{
            "cityId":"082058",
            "pref":"茨城県",
            "city":"石岡市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｲｼｵｶｼ"
        },
        "082074":{
            "cityId":"082074",
            "pref":"茨城県",
            "city":"結城市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾕｳｷｼ"
        },
        "082082":{
            "cityId":"082082",
            "pref":"茨城県",
            "city":"龍ケ崎市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾘｭｳｶﾞｻｷｼ"
        },
        "082104":{
            "cityId":"082104",
            "pref":"茨城県",
            "city":"下妻市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｼﾓﾂﾏｼ"
        },
        "082112":{
            "cityId":"082112",
            "pref":"茨城県",
            "city":"常総市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｼﾞｮｳｿｳｼ"
        },
        "082121":{
            "cityId":"082121",
            "pref":"茨城県",
            "city":"常陸太田市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾋﾀﾁｵｵﾀｼ"
        },
        "082147":{
            "cityId":"082147",
            "pref":"茨城県",
            "city":"高萩市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾀｶﾊｷﾞｼ"
        },
        "082155":{
            "cityId":"082155",
            "pref":"茨城県",
            "city":"北茨城市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｷﾀｲﾊﾞﾗｷｼ"
        },
        "082163":{
            "cityId":"082163",
            "pref":"茨城県",
            "city":"笠間市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｶｻﾏｼ"
        },
        "082171":{
            "cityId":"082171",
            "pref":"茨城県",
            "city":"取手市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾄﾘﾃﾞｼ"
        },
        "082198":{
            "cityId":"082198",
            "pref":"茨城県",
            "city":"牛久市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｳｼｸｼ"
        },
        "082201":{
            "cityId":"082201",
            "pref":"茨城県",
            "city":"つくば市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾂｸﾊﾞｼ"
        },
        "082210":{
            "cityId":"082210",
            "pref":"茨城県",
            "city":"ひたちなか市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾋﾀﾁﾅｶｼ"
        },
        "082228":{
            "cityId":"082228",
            "pref":"茨城県",
            "city":"鹿嶋市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｶｼﾏｼ"
        },
        "082236":{
            "cityId":"082236",
            "pref":"茨城県",
            "city":"潮来市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｲﾀｺｼ"
        },
        "082244":{
            "cityId":"082244",
            "pref":"茨城県",
            "city":"守谷市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾓﾘﾔｼ"
        },
        "082252":{
            "cityId":"082252",
            "pref":"茨城県",
            "city":"常陸大宮市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾋﾀﾁｵｵﾐﾔｼ"
        },
        "082261":{
            "cityId":"082261",
            "pref":"茨城県",
            "city":"那珂市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾅｶｼ"
        },
        "082279":{
            "cityId":"082279",
            "pref":"茨城県",
            "city":"筑西市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾁｸｾｲｼ"
        },
        "082287":{
            "cityId":"082287",
            "pref":"茨城県",
            "city":"坂東市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾊﾞﾝﾄﾞｳｼ"
        },
        "082295":{
            "cityId":"082295",
            "pref":"茨城県",
            "city":"稲敷市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｲﾅｼｷｼ"
        },
        "082309":{
            "cityId":"082309",
            "pref":"茨城県",
            "city":"かすみがうら市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｶｽﾐｶﾞｳﾗｼ"
        },
        "082317":{
            "cityId":"082317",
            "pref":"茨城県",
            "city":"桜川市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｻｸﾗｶﾞﾜｼ"
        },
        "082325":{
            "cityId":"082325",
            "pref":"茨城県",
            "city":"神栖市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｶﾐｽｼ"
        },
        "082333":{
            "cityId":"082333",
            "pref":"茨城県",
            "city":"行方市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾅﾒｶﾞﾀｼ"
        },
        "082341":{
            "cityId":"082341",
            "pref":"茨城県",
            "city":"鉾田市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾎｺﾀｼ"
        },
        "082350":{
            "cityId":"082350",
            "pref":"茨城県",
            "city":"つくばみらい市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾂｸﾊﾞﾐﾗｲｼ"
        },
        "082368":{
            "cityId":"082368",
            "pref":"茨城県",
            "city":"小美玉市",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｵﾐﾀﾏｼ"
        },
        "083020":{
            "cityId":"083020",
            "pref":"茨城県",
            "city":"茨城町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｲﾊﾞﾗｷﾏﾁ"
        },
        "083097":{
            "cityId":"083097",
            "pref":"茨城県",
            "city":"大洗町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｵｵｱﾗｲﾏﾁ"
        },
        "083101":{
            "cityId":"083101",
            "pref":"茨城県",
            "city":"城里町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｼﾛｻﾄﾏﾁ"
        },
        "083411":{
            "cityId":"083411",
            "pref":"茨城県",
            "city":"東海村",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾄｳｶｲﾑﾗ"
        },
        "083640":{
            "cityId":"083640",
            "pref":"茨城県",
            "city":"大子町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾀﾞｲｺﾞﾏﾁ"
        },
        "084425":{
            "cityId":"084425",
            "pref":"茨城県",
            "city":"美浦村",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾐﾎﾑﾗ"
        },
        "084433":{
            "cityId":"084433",
            "pref":"茨城県",
            "city":"阿見町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｱﾐﾏﾁ"
        },
        "084476":{
            "cityId":"084476",
            "pref":"茨城県",
            "city":"河内町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｶﾜﾁﾏﾁ"
        },
        "085219":{
            "cityId":"085219",
            "pref":"茨城県",
            "city":"八千代町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾔﾁﾖﾏﾁ"
        },
        "085421":{
            "cityId":"085421",
            "pref":"茨城県",
            "city":"五霞町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｺﾞｶﾏﾁ"
        },
        "085464":{
            "cityId":"085464",
            "pref":"茨城県",
            "city":"境町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ｻｶｲﾏﾁ"
        },
        "085642":{
            "cityId":"085642",
            "pref":"茨城県",
            "city":"利根町",
            "prefKana":"ｲﾊﾞﾗｷｹﾝ",
            "cityKana":"ﾄﾈﾏﾁ"
        }
    },
    "栃木":{
        "090000":{
            "cityId":"090000",
            "pref":"栃木県",
            "city":"",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":""
        },
        "092011":{
            "cityId":"092011",
            "pref":"栃木県",
            "city":"宇都宮市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｳﾂﾉﾐﾔｼ"
        },
        "092029":{
            "cityId":"092029",
            "pref":"栃木県",
            "city":"足利市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｱｼｶｶﾞｼ"
        },
        "092037":{
            "cityId":"092037",
            "pref":"栃木県",
            "city":"栃木市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾄﾁｷﾞｼ"
        },
        "092045":{
            "cityId":"092045",
            "pref":"栃木県",
            "city":"佐野市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｻﾉｼ"
        },
        "092053":{
            "cityId":"092053",
            "pref":"栃木県",
            "city":"鹿沼市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｶﾇﾏｼ"
        },
        "092061":{
            "cityId":"092061",
            "pref":"栃木県",
            "city":"日光市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾆｯｺｳｼ"
        },
        "092088":{
            "cityId":"092088",
            "pref":"栃木県",
            "city":"小山市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｵﾔﾏｼ"
        },
        "092096":{
            "cityId":"092096",
            "pref":"栃木県",
            "city":"真岡市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾓｵｶｼ"
        },
        "092100":{
            "cityId":"092100",
            "pref":"栃木県",
            "city":"大田原市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｵｵﾀﾜﾗｼ"
        },
        "092118":{
            "cityId":"092118",
            "pref":"栃木県",
            "city":"矢板市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾔｲﾀｼ"
        },
        "092134":{
            "cityId":"092134",
            "pref":"栃木県",
            "city":"那須塩原市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾅｽｼｵﾊﾞﾗｼ"
        },
        "092142":{
            "cityId":"092142",
            "pref":"栃木県",
            "city":"さくら市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｻｸﾗｼ"
        },
        "092151":{
            "cityId":"092151",
            "pref":"栃木県",
            "city":"那須烏山市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾅｽｶﾗｽﾔﾏｼ"
        },
        "092169":{
            "cityId":"092169",
            "pref":"栃木県",
            "city":"下野市",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｼﾓﾂｹｼ"
        },
        "093017":{
            "cityId":"093017",
            "pref":"栃木県",
            "city":"上三川町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｶﾐﾉｶﾜﾏﾁ"
        },
        "093424":{
            "cityId":"093424",
            "pref":"栃木県",
            "city":"益子町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾏｼｺﾏﾁ"
        },
        "093432":{
            "cityId":"093432",
            "pref":"栃木県",
            "city":"茂木町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾓﾃｷﾞﾏﾁ"
        },
        "093441":{
            "cityId":"093441",
            "pref":"栃木県",
            "city":"市貝町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｲﾁｶｲﾏﾁ"
        },
        "093459":{
            "cityId":"093459",
            "pref":"栃木県",
            "city":"芳賀町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾊｶﾞﾏﾁ"
        },
        "093611":{
            "cityId":"093611",
            "pref":"栃木県",
            "city":"壬生町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾐﾌﾞﾏﾁ"
        },
        "093645":{
            "cityId":"093645",
            "pref":"栃木県",
            "city":"野木町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾉｷﾞﾏﾁ"
        },
        "093840":{
            "cityId":"093840",
            "pref":"栃木県",
            "city":"塩谷町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ｼｵﾔﾏﾁ"
        },
        "093866":{
            "cityId":"093866",
            "pref":"栃木県",
            "city":"高根沢町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾀｶﾈｻﾞﾜﾏﾁ"
        },
        "094072":{
            "cityId":"094072",
            "pref":"栃木県",
            "city":"那須町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾅｽﾏﾁ"
        },
        "094111":{
            "cityId":"094111",
            "pref":"栃木県",
            "city":"那珂川町",
            "prefKana":"ﾄﾁｷﾞｹﾝ",
            "cityKana":"ﾅｶｶﾞﾜﾏﾁ"
        }
    },
    "群馬":{
        "100005":{
            "cityId":100005,
            "pref":"群馬県",
            "city":"",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":""
        },
        "102016":{
            "cityId":102016,
            "pref":"群馬県",
            "city":"前橋市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾏｴﾊﾞｼｼ"
        },
        "102024":{
            "cityId":102024,
            "pref":"群馬県",
            "city":"高崎市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾀｶｻｷｼ"
        },
        "102032":{
            "cityId":102032,
            "pref":"群馬県",
            "city":"桐生市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｷﾘｭｳｼ"
        },
        "102041":{
            "cityId":102041,
            "pref":"群馬県",
            "city":"伊勢崎市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｲｾｻｷｼ"
        },
        "102059":{
            "cityId":102059,
            "pref":"群馬県",
            "city":"太田市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｵｵﾀｼ"
        },
        "102067":{
            "cityId":102067,
            "pref":"群馬県",
            "city":"沼田市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾇﾏﾀｼ"
        },
        "102075":{
            "cityId":102075,
            "pref":"群馬県",
            "city":"館林市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾀﾃﾊﾞﾔｼｼ"
        },
        "102083":{
            "cityId":102083,
            "pref":"群馬県",
            "city":"渋川市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｼﾌﾞｶﾜｼ"
        },
        "102091":{
            "cityId":102091,
            "pref":"群馬県",
            "city":"藤岡市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾌｼﾞｵｶｼ"
        },
        "102105":{
            "cityId":102105,
            "pref":"群馬県",
            "city":"富岡市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾄﾐｵｶｼ"
        },
        "102113":{
            "cityId":102113,
            "pref":"群馬県",
            "city":"安中市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｱﾝﾅｶｼ"
        },
        "102121":{
            "cityId":102121,
            "pref":"群馬県",
            "city":"みどり市",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾐﾄﾞﾘｼ"
        },
        "103446":{
            "cityId":103446,
            "pref":"群馬県",
            "city":"榛東村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｼﾝﾄｳﾑﾗ"
        },
        "103454":{
            "cityId":103454,
            "pref":"群馬県",
            "city":"吉岡町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾖｼｵｶﾏﾁ"
        },
        "103667":{
            "cityId":103667,
            "pref":"群馬県",
            "city":"上野村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｳｴﾉﾑﾗ"
        },
        "103675":{
            "cityId":103675,
            "pref":"群馬県",
            "city":"神流町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｶﾝﾅﾏﾁ"
        },
        "103829":{
            "cityId":103829,
            "pref":"群馬県",
            "city":"下仁田町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｼﾓﾆﾀﾏﾁ"
        },
        "103837":{
            "cityId":103837,
            "pref":"群馬県",
            "city":"南牧村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾅﾝﾓｸﾑﾗ"
        },
        "103845":{
            "cityId":103845,
            "pref":"群馬県",
            "city":"甘楽町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｶﾝﾗﾏﾁ"
        },
        "104213":{
            "cityId":104213,
            "pref":"群馬県",
            "city":"中之条町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾅｶﾉｼﾞﾖｳﾏﾁ"
        },
        "104248":{
            "cityId":104248,
            "pref":"群馬県",
            "city":"長野原町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾅｶﾞﾉﾊﾗﾏﾁ"
        },
        "104256":{
            "cityId":104256,
            "pref":"群馬県",
            "city":"嬬恋村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾂﾏｺﾞｲﾑﾗ"
        },
        "104264":{
            "cityId":104264,
            "pref":"群馬県",
            "city":"草津町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｸｻﾂﾏﾁ"
        },
        "104281":{
            "cityId":104281,
            "pref":"群馬県",
            "city":"高山村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾀｶﾔﾏﾑﾗ"
        },
        "104299":{
            "cityId":104299,
            "pref":"群馬県",
            "city":"東吾妻町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼｱｶﾞﾂﾏﾏﾁ"
        },
        "104434":{
            "cityId":104434,
            "pref":"群馬県",
            "city":"片品村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｶﾀｼﾅﾑﾗ"
        },
        "104442":{
            "cityId":104442,
            "pref":"群馬県",
            "city":"川場村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｶﾜﾊﾞﾑﾗ"
        },
        "104485":{
            "cityId":104485,
            "pref":"群馬県",
            "city":"昭和村",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｼｮｳﾜﾑﾗ"
        },
        "104493":{
            "cityId":104493,
            "pref":"群馬県",
            "city":"みなかみ町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾐﾅｶﾐﾏﾁ"
        },
        "104647":{
            "cityId":104647,
            "pref":"群馬県",
            "city":"玉村町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾀﾏﾑﾗﾏﾁ"
        },
        "105210":{
            "cityId":105210,
            "pref":"群馬県",
            "city":"板倉町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｲﾀｸﾗﾏﾁ"
        },
        "105228":{
            "cityId":105228,
            "pref":"群馬県",
            "city":"明和町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾒｲﾜﾏﾁ"
        },
        "105236":{
            "cityId":105236,
            "pref":"群馬県",
            "city":"千代田町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ﾁﾖﾀﾞﾏﾁ"
        },
        "105244":{
            "cityId":105244,
            "pref":"群馬県",
            "city":"大泉町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｵｵｲｽﾞﾐﾏﾁ"
        },
        "105252":{
            "cityId":105252,
            "pref":"群馬県",
            "city":"邑楽町",
            "prefKana":"ｸﾞﾝﾏｹﾝ",
            "cityKana":"ｵｳﾗﾏﾁ"
        }
    },
    "埼玉":{
        "110001":{
            "cityId":110001,
            "pref":"埼玉県",
            "city":"",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":""
        },
        "111007":{
            "cityId":111007,
            "pref":"埼玉県",
            "city":"さいたま市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｻｲﾀﾏｼ"
        },
        "112011":{
            "cityId":112011,
            "pref":"埼玉県",
            "city":"川越市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶﾜｺﾞｴｼ"
        },
        "112020":{
            "cityId":112020,
            "pref":"埼玉県",
            "city":"熊谷市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｸﾏｶﾞﾔｼ"
        },
        "112038":{
            "cityId":112038,
            "pref":"埼玉県",
            "city":"川口市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶﾜｸﾞﾁｼ"
        },
        "112062":{
            "cityId":112062,
            "pref":"埼玉県",
            "city":"行田市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｷﾞﾖｳﾀﾞｼ"
        },
        "112071":{
            "cityId":112071,
            "pref":"埼玉県",
            "city":"秩父市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾁﾁﾌﾞｼ"
        },
        "112089":{
            "cityId":112089,
            "pref":"埼玉県",
            "city":"所沢市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾄｺﾛｻﾞﾜｼ"
        },
        "112097":{
            "cityId":112097,
            "pref":"埼玉県",
            "city":"飯能市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾊﾝﾉｳｼ"
        },
        "112101":{
            "cityId":112101,
            "pref":"埼玉県",
            "city":"加須市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶｿﾞｼ"
        },
        "112119":{
            "cityId":112119,
            "pref":"埼玉県",
            "city":"本庄市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾎﾝｼﾞﾖｳｼ"
        },
        "112127":{
            "cityId":112127,
            "pref":"埼玉県",
            "city":"東松山市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼﾏﾂﾔﾏｼ"
        },
        "112143":{
            "cityId":112143,
            "pref":"埼玉県",
            "city":"春日部市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶｽｶﾍﾞｼ"
        },
        "112151":{
            "cityId":112151,
            "pref":"埼玉県",
            "city":"狭山市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｻﾔﾏｼ"
        },
        "112160":{
            "cityId":112160,
            "pref":"埼玉県",
            "city":"羽生市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾊﾆﾕｳｼ"
        },
        "112178":{
            "cityId":112178,
            "pref":"埼玉県",
            "city":"鴻巣市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｺｳﾉｽｼ"
        },
        "112186":{
            "cityId":112186,
            "pref":"埼玉県",
            "city":"深谷市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾌｶﾔｼ"
        },
        "112194":{
            "cityId":112194,
            "pref":"埼玉県",
            "city":"上尾市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｱｹﾞｵｼ"
        },
        "112216":{
            "cityId":112216,
            "pref":"埼玉県",
            "city":"草加市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｿｳｶｼ"
        },
        "112224":{
            "cityId":112224,
            "pref":"埼玉県",
            "city":"越谷市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｺｼｶﾞﾔｼ"
        },
        "112232":{
            "cityId":112232,
            "pref":"埼玉県",
            "city":"蕨市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾜﾗﾋﾞｼ"
        },
        "112241":{
            "cityId":112241,
            "pref":"埼玉県",
            "city":"戸田市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾄﾀﾞｼ"
        },
        "112259":{
            "cityId":112259,
            "pref":"埼玉県",
            "city":"入間市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｲﾙﾏｼ"
        },
        "112275":{
            "cityId":112275,
            "pref":"埼玉県",
            "city":"朝霞市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｱｻｶｼ"
        },
        "112283":{
            "cityId":112283,
            "pref":"埼玉県",
            "city":"志木市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｼｷｼ"
        },
        "112291":{
            "cityId":112291,
            "pref":"埼玉県",
            "city":"和光市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾜｺｳｼ"
        },
        "112305":{
            "cityId":112305,
            "pref":"埼玉県",
            "city":"新座市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾆｲｻﾞｼ"
        },
        "112313":{
            "cityId":112313,
            "pref":"埼玉県",
            "city":"桶川市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｵｹｶﾞﾜｼ"
        },
        "112321":{
            "cityId":112321,
            "pref":"埼玉県",
            "city":"久喜市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｸｷｼ"
        },
        "112330":{
            "cityId":112330,
            "pref":"埼玉県",
            "city":"北本市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｷﾀﾓﾄｼ"
        },
        "112348":{
            "cityId":112348,
            "pref":"埼玉県",
            "city":"八潮市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾔｼｵｼ"
        },
        "112356":{
            "cityId":112356,
            "pref":"埼玉県",
            "city":"富士見市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾌｼﾞﾐｼ"
        },
        "112372":{
            "cityId":112372,
            "pref":"埼玉県",
            "city":"三郷市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾐｻﾄｼ"
        },
        "112381":{
            "cityId":112381,
            "pref":"埼玉県",
            "city":"蓮田市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾊｽﾀﾞｼ"
        },
        "112399":{
            "cityId":112399,
            "pref":"埼玉県",
            "city":"坂戸市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｻｶﾄﾞｼ"
        },
        "112402":{
            "cityId":112402,
            "pref":"埼玉県",
            "city":"幸手市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｻｯﾃｼ"
        },
        "112411":{
            "cityId":112411,
            "pref":"埼玉県",
            "city":"鶴ヶ島市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾂﾙｶﾞｼﾏｼ"
        },
        "112429":{
            "cityId":112429,
            "pref":"埼玉県",
            "city":"日高市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾋﾀﾞｶｼ"
        },
        "112437":{
            "cityId":112437,
            "pref":"埼玉県",
            "city":"吉川市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾖｼｶﾜｼ"
        },
        "112453":{
            "cityId":112453,
            "pref":"埼玉県",
            "city":"ふじみ野市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾌｼﾞﾐﾉｼ"
        },
        "112461":{
            "cityId":112461,
            "pref":"埼玉県",
            "city":"白岡市",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｼﾗｵｶｼ"
        },
        "113018":{
            "cityId":113018,
            "pref":"埼玉県",
            "city":"伊奈町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｲﾅﾏﾁ"
        },
        "113247":{
            "cityId":113247,
            "pref":"埼玉県",
            "city":"三芳町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾐﾖｼﾏﾁ"
        },
        "113263":{
            "cityId":113263,
            "pref":"埼玉県",
            "city":"毛呂山町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾓﾛﾔﾏﾏﾁ"
        },
        "113271":{
            "cityId":113271,
            "pref":"埼玉県",
            "city":"越生町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｵｺﾞｾﾏﾁ"
        },
        "113417":{
            "cityId":113417,
            "pref":"埼玉県",
            "city":"滑川町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾅﾒｶﾞﾜﾏﾁ"
        },
        "113425":{
            "cityId":113425,
            "pref":"埼玉県",
            "city":"嵐山町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾗﾝｻﾞﾝﾏﾁ"
        },
        "113433":{
            "cityId":113433,
            "pref":"埼玉県",
            "city":"小川町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｵｶﾞﾜﾏﾁ"
        },
        "113468":{
            "cityId":113468,
            "pref":"埼玉県",
            "city":"川島町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶﾜｼﾞﾏﾏﾁ"
        },
        "113476":{
            "cityId":113476,
            "pref":"埼玉県",
            "city":"吉見町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾖｼﾐﾏﾁ"
        },
        "113484":{
            "cityId":113484,
            "pref":"埼玉県",
            "city":"鳩山町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾊﾄﾔﾏﾏﾁ"
        },
        "113492":{
            "cityId":113492,
            "pref":"埼玉県",
            "city":"ときがわ町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾄｷｶﾞﾜﾏﾁ"
        },
        "113611":{
            "cityId":113611,
            "pref":"埼玉県",
            "city":"横瀬町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾖｺｾﾞﾏﾁ"
        },
        "113620":{
            "cityId":113620,
            "pref":"埼玉県",
            "city":"皆野町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾐﾅﾉﾏﾁ"
        },
        "113638":{
            "cityId":113638,
            "pref":"埼玉県",
            "city":"長瀞町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾅｶﾞﾄﾛﾏﾁ"
        },
        "113654":{
            "cityId":113654,
            "pref":"埼玉県",
            "city":"小鹿野町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｵｶﾞﾉﾏﾁ"
        },
        "113697":{
            "cityId":113697,
            "pref":"埼玉県",
            "city":"東秩父村",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼﾁﾁﾌﾞﾑﾗ"
        },
        "113816":{
            "cityId":113816,
            "pref":"埼玉県",
            "city":"美里町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾐｻﾄﾏﾁ"
        },
        "113832":{
            "cityId":113832,
            "pref":"埼玉県",
            "city":"神川町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶﾐｶﾜﾏﾁ"
        },
        "113859":{
            "cityId":113859,
            "pref":"埼玉県",
            "city":"上里町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｶﾐｻﾄﾏﾁ"
        },
        "114081":{
            "cityId":114081,
            "pref":"埼玉県",
            "city":"寄居町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾖﾘｲﾏﾁ"
        },
        "114421":{
            "cityId":114421,
            "pref":"埼玉県",
            "city":"宮代町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾐﾔｼﾛﾏﾁ"
        },
        "114642":{
            "cityId":114642,
            "pref":"埼玉県",
            "city":"杉戸町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ｽｷﾞﾄﾏﾁ"
        },
        "114651":{
            "cityId":114651,
            "pref":"埼玉県",
            "city":"松伏町",
            "prefKana":"ｻｲﾀﾏｹﾝ",
            "cityKana":"ﾏﾂﾌﾞｼﾏﾁ"
        }
    },
    "千葉":{
        "120006":{
            "cityId":120006,
            "pref":"千葉県",
            "city":"",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":""
        },
        "121002":{
            "cityId":121002,
            "pref":"千葉県",
            "city":"千葉市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾁﾊﾞｼ"
        },
        "122025":{
            "cityId":122025,
            "pref":"千葉県",
            "city":"銚子市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾁｮｳｼｼ"
        },
        "122033":{
            "cityId":122033,
            "pref":"千葉県",
            "city":"市川市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｲﾁｶﾜｼ"
        },
        "122041":{
            "cityId":122041,
            "pref":"千葉県",
            "city":"船橋市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾌﾅﾊﾞｼｼ"
        },
        "122050":{
            "cityId":122050,
            "pref":"千葉県",
            "city":"館山市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾀﾃﾔﾏｼ"
        },
        "122068":{
            "cityId":122068,
            "pref":"千葉県",
            "city":"木更津市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｷｻﾗﾂﾞｼ"
        },
        "122076":{
            "cityId":122076,
            "pref":"千葉県",
            "city":"松戸市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾏﾂﾄﾞｼ"
        },
        "122084":{
            "cityId":122084,
            "pref":"千葉県",
            "city":"野田市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾉﾀﾞｼ"
        },
        "122106":{
            "cityId":122106,
            "pref":"千葉県",
            "city":"茂原市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾓﾊﾞﾗｼ"
        },
        "122114":{
            "cityId":122114,
            "pref":"千葉県",
            "city":"成田市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾅﾘﾀｼ"
        },
        "122122":{
            "cityId":122122,
            "pref":"千葉県",
            "city":"佐倉市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｻｸﾗｼ"
        },
        "122131":{
            "cityId":122131,
            "pref":"千葉県",
            "city":"東金市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾄｳｶﾞﾈｼ"
        },
        "122157":{
            "cityId":122157,
            "pref":"千葉県",
            "city":"旭市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｱｻﾋｼ"
        },
        "122165":{
            "cityId":122165,
            "pref":"千葉県",
            "city":"習志野市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾅﾗｼﾉｼ"
        },
        "122173":{
            "cityId":122173,
            "pref":"千葉県",
            "city":"柏市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｶｼﾜｼ"
        },
        "122181":{
            "cityId":122181,
            "pref":"千葉県",
            "city":"勝浦市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｶﾂｳﾗｼ"
        },
        "122190":{
            "cityId":122190,
            "pref":"千葉県",
            "city":"市原市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｲﾁﾊﾗｼ"
        },
        "122203":{
            "cityId":122203,
            "pref":"千葉県",
            "city":"流山市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾅｶﾞﾚﾔﾏｼ"
        },
        "122211":{
            "cityId":122211,
            "pref":"千葉県",
            "city":"八千代市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾔﾁﾖｼ"
        },
        "122220":{
            "cityId":122220,
            "pref":"千葉県",
            "city":"我孫子市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｱﾋﾞｺｼ"
        },
        "122238":{
            "cityId":122238,
            "pref":"千葉県",
            "city":"鴨川市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｶﾓｶﾞﾜｼ"
        },
        "122246":{
            "cityId":122246,
            "pref":"千葉県",
            "city":"鎌ケ谷市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｶﾏｶﾞﾔｼ"
        },
        "122254":{
            "cityId":122254,
            "pref":"千葉県",
            "city":"君津市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｷﾐﾂｼ"
        },
        "122262":{
            "cityId":122262,
            "pref":"千葉県",
            "city":"富津市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾌｯﾂｼ"
        },
        "122271":{
            "cityId":122271,
            "pref":"千葉県",
            "city":"浦安市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｳﾗﾔｽｼ"
        },
        "122289":{
            "cityId":122289,
            "pref":"千葉県",
            "city":"四街道市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾖﾂｶｲﾄﾞｳｼ"
        },
        "122297":{
            "cityId":122297,
            "pref":"千葉県",
            "city":"袖ケ浦市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｿﾃﾞｶﾞｳﾗｼ"
        },
        "122301":{
            "cityId":122301,
            "pref":"千葉県",
            "city":"八街市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾔﾁﾏﾀｼ"
        },
        "122319":{
            "cityId":122319,
            "pref":"千葉県",
            "city":"印西市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｲﾝｻﾞｲｼ"
        },
        "122327":{
            "cityId":122327,
            "pref":"千葉県",
            "city":"白井市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｼﾛｲｼ"
        },
        "122335":{
            "cityId":122335,
            "pref":"千葉県",
            "city":"富里市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾄﾐｻﾄｼ"
        },
        "122343":{
            "cityId":122343,
            "pref":"千葉県",
            "city":"南房総市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾐﾅﾐﾎﾞｳｿｳｼ"
        },
        "122351":{
            "cityId":122351,
            "pref":"千葉県",
            "city":"匝瑳市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｿｳｻｼ"
        },
        "122360":{
            "cityId":122360,
            "pref":"千葉県",
            "city":"香取市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｶﾄﾘｼ"
        },
        "122378":{
            "cityId":122378,
            "pref":"千葉県",
            "city":"山武市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｻﾝﾑｼ"
        },
        "122386":{
            "cityId":122386,
            "pref":"千葉県",
            "city":"いすみ市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｲｽﾐｼ"
        },
        "122394":{
            "cityId":122394,
            "pref":"千葉県",
            "city":"大網白里市",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｵｵｱﾐｼﾗｻﾄｼ"
        },
        "123226":{
            "cityId":123226,
            "pref":"千葉県",
            "city":"酒々井町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｼｽｲﾏﾁ"
        },
        "123293":{
            "cityId":123293,
            "pref":"千葉県",
            "city":"栄町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｻｶｴﾏﾁ"
        },
        "123421":{
            "cityId":123421,
            "pref":"千葉県",
            "city":"神崎町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｺｳｻﾞｷﾏﾁ"
        },
        "123471":{
            "cityId":123471,
            "pref":"千葉県",
            "city":"多古町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾀｺﾏﾁ"
        },
        "123498":{
            "cityId":123498,
            "pref":"千葉県",
            "city":"東庄町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾄｳﾉｼｮｳﾏﾁ"
        },
        "124036":{
            "cityId":124036,
            "pref":"千葉県",
            "city":"九十九里町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｸｼﾞﾕｳｸﾘﾏﾁ"
        },
        "124095":{
            "cityId":124095,
            "pref":"千葉県",
            "city":"芝山町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｼﾊﾞﾔﾏﾏﾁ"
        },
        "124109":{
            "cityId":124109,
            "pref":"千葉県",
            "city":"横芝光町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾖｺｼﾊﾞﾋｶﾘﾏﾁ"
        },
        "124214":{
            "cityId":124214,
            "pref":"千葉県",
            "city":"一宮町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｲﾁﾉﾐﾔﾏﾁ"
        },
        "124222":{
            "cityId":124222,
            "pref":"千葉県",
            "city":"睦沢町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾑﾂｻﾞﾜﾏﾁ"
        },
        "124231":{
            "cityId":124231,
            "pref":"千葉県",
            "city":"長生村",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾁｮｳｾｲﾑﾗ"
        },
        "124249":{
            "cityId":124249,
            "pref":"千葉県",
            "city":"白子町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｼﾗｺﾏﾁ"
        },
        "124265":{
            "cityId":124265,
            "pref":"千葉県",
            "city":"長柄町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾅｶﾞﾗﾏﾁ"
        },
        "124273":{
            "cityId":124273,
            "pref":"千葉県",
            "city":"長南町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ﾁｮｳﾅﾝﾏﾁ"
        },
        "124419":{
            "cityId":124419,
            "pref":"千葉県",
            "city":"大多喜町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｵｵﾀｷﾏﾁ"
        },
        "124435":{
            "cityId":124435,
            "pref":"千葉県",
            "city":"御宿町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｵﾝｼﾞﾕｸﾏﾁ"
        },
        "124630":{
            "cityId":124630,
            "pref":"千葉県",
            "city":"鋸南町",
            "prefKana":"ﾁﾊﾞｹﾝ",
            "cityKana":"ｷﾖﾅﾝﾏﾁ"
        }
    },
    "東京":{
        "130001":{
            "cityId":130001,
            "pref":"東京都",
            "city":"",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":""
        },
        "131016":{
            "cityId":131016,
            "pref":"東京都",
            "city":"千代田区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾁﾖﾀﾞｸ"
        },
        "131024":{
            "cityId":131024,
            "pref":"東京都",
            "city":"中央区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾁｭｳｵｳｸ"
        },
        "131032":{
            "cityId":131032,
            "pref":"東京都",
            "city":"港区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾐﾅﾄｸ"
        },
        "131041":{
            "cityId":131041,
            "pref":"東京都",
            "city":"新宿区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｼﾝｼﾞｭｸｸ"
        },
        "131059":{
            "cityId":131059,
            "pref":"東京都",
            "city":"文京区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾌﾞﾝｷｮｳｸ"
        },
        "131067":{
            "cityId":131067,
            "pref":"東京都",
            "city":"台東区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾀｲﾄｳｸ"
        },
        "131075":{
            "cityId":131075,
            "pref":"東京都",
            "city":"墨田区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｽﾐﾀﾞｸ"
        },
        "131083":{
            "cityId":131083,
            "pref":"東京都",
            "city":"江東区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺｳﾄｳｸ"
        },
        "131091":{
            "cityId":131091,
            "pref":"東京都",
            "city":"品川区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｼﾅｶﾞﾜｸ"
        },
        "131105":{
            "cityId":131105,
            "pref":"東京都",
            "city":"目黒区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾒｸﾞﾛｸ"
        },
        "131113":{
            "cityId":131113,
            "pref":"東京都",
            "city":"大田区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｵｵﾀｸ"
        },
        "131121":{
            "cityId":131121,
            "pref":"東京都",
            "city":"世田谷区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｾﾀｶﾞﾔｸ"
        },
        "131130":{
            "cityId":131130,
            "pref":"東京都",
            "city":"渋谷区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｼﾌﾞﾔｸ"
        },
        "131148":{
            "cityId":131148,
            "pref":"東京都",
            "city":"中野区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾅｶﾉｸ"
        },
        "131156":{
            "cityId":131156,
            "pref":"東京都",
            "city":"杉並区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｽｷﾞﾅﾐｸ"
        },
        "131164":{
            "cityId":131164,
            "pref":"東京都",
            "city":"豊島区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾄｼﾏｸ"
        },
        "131172":{
            "cityId":131172,
            "pref":"東京都",
            "city":"北区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｷﾀｸ"
        },
        "131181":{
            "cityId":131181,
            "pref":"東京都",
            "city":"荒川区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｱﾗｶﾜｸ"
        },
        "131199":{
            "cityId":131199,
            "pref":"東京都",
            "city":"板橋区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｲﾀﾊﾞｼｸ"
        },
        "131202":{
            "cityId":131202,
            "pref":"東京都",
            "city":"練馬区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾈﾘﾏｸ"
        },
        "131211":{
            "cityId":131211,
            "pref":"東京都",
            "city":"足立区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｱﾀﾞﾁｸ"
        },
        "131229":{
            "cityId":131229,
            "pref":"東京都",
            "city":"葛飾区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｶﾂｼｶｸ"
        },
        "131237":{
            "cityId":131237,
            "pref":"東京都",
            "city":"江戸川区",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｴﾄﾞｶﾞﾜｸ"
        },
        "132012":{
            "cityId":132012,
            "pref":"東京都",
            "city":"八王子市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾊﾁｵｳｼﾞｼ"
        },
        "132021":{
            "cityId":132021,
            "pref":"東京都",
            "city":"立川市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾀﾁｶﾜｼ"
        },
        "132039":{
            "cityId":132039,
            "pref":"東京都",
            "city":"武蔵野市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾑｻｼﾉｼ"
        },
        "132047":{
            "cityId":132047,
            "pref":"東京都",
            "city":"三鷹市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾐﾀｶｼ"
        },
        "132055":{
            "cityId":132055,
            "pref":"東京都",
            "city":"青梅市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｵｳﾒｼ"
        },
        "132063":{
            "cityId":132063,
            "pref":"東京都",
            "city":"府中市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾌﾁｭｳｼ"
        },
        "132071":{
            "cityId":132071,
            "pref":"東京都",
            "city":"昭島市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｱｷｼﾏｼ"
        },
        "132080":{
            "cityId":132080,
            "pref":"東京都",
            "city":"調布市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾁｮｳﾌｼ"
        },
        "132098":{
            "cityId":132098,
            "pref":"東京都",
            "city":"町田市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾏﾁﾀﾞｼ"
        },
        "132101":{
            "cityId":132101,
            "pref":"東京都",
            "city":"小金井市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺｶﾞﾈｲｼ"
        },
        "132110":{
            "cityId":132110,
            "pref":"東京都",
            "city":"小平市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺﾀﾞｲﾗｼ"
        },
        "132128":{
            "cityId":132128,
            "pref":"東京都",
            "city":"日野市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋﾉｼ"
        },
        "132136":{
            "cityId":132136,
            "pref":"東京都",
            "city":"東村山市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋｶﾞｼﾑﾗﾔﾏｼ"
        },
        "132144":{
            "cityId":132144,
            "pref":"東京都",
            "city":"国分寺市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺｸﾌﾞﾝｼﾞｼ"
        },
        "132152":{
            "cityId":132152,
            "pref":"東京都",
            "city":"国立市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｸﾆﾀﾁｼ"
        },
        "132187":{
            "cityId":132187,
            "pref":"東京都",
            "city":"福生市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾌｯｻｼ"
        },
        "132195":{
            "cityId":132195,
            "pref":"東京都",
            "city":"狛江市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺﾏｴｼ"
        },
        "132209":{
            "cityId":132209,
            "pref":"東京都",
            "city":"東大和市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋｶﾞｼﾔﾏﾄｼ"
        },
        "132217":{
            "cityId":132217,
            "pref":"東京都",
            "city":"清瀬市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｷﾖｾｼ"
        },
        "132225":{
            "cityId":132225,
            "pref":"東京都",
            "city":"東久留米市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋｶﾞｼｸﾙﾒｼ"
        },
        "132233":{
            "cityId":132233,
            "pref":"東京都",
            "city":"武蔵村山市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾑｻｼﾑﾗﾔﾏｼ"
        },
        "132241":{
            "cityId":132241,
            "pref":"東京都",
            "city":"多摩市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾀﾏｼ"
        },
        "132250":{
            "cityId":132250,
            "pref":"東京都",
            "city":"稲城市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｲﾅｷﾞｼ"
        },
        "132276":{
            "cityId":132276,
            "pref":"東京都",
            "city":"羽村市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾊﾑﾗｼ"
        },
        "132284":{
            "cityId":132284,
            "pref":"東京都",
            "city":"あきる野市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｱｷﾙﾉｼ"
        },
        "132292":{
            "cityId":132292,
            "pref":"東京都",
            "city":"西東京市",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾆｼﾄｳｷｮｳｼ"
        },
        "133035":{
            "cityId":133035,
            "pref":"東京都",
            "city":"瑞穂町",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾐｽﾞﾎﾏﾁ"
        },
        "133051":{
            "cityId":133051,
            "pref":"東京都",
            "city":"日の出町",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋﾉﾃﾞﾏﾁ"
        },
        "133078":{
            "cityId":133078,
            "pref":"東京都",
            "city":"檜原村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾋﾉﾊﾗﾑﾗ"
        },
        "133086":{
            "cityId":133086,
            "pref":"東京都",
            "city":"奥多摩町",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｵｸﾀﾏﾏﾁ"
        },
        "133612":{
            "cityId":133612,
            "pref":"東京都",
            "city":"大島町",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｵｵｼﾏﾏﾁ"
        },
        "133621":{
            "cityId":133621,
            "pref":"東京都",
            "city":"利島村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾄｼﾏﾑﾗ"
        },
        "133639":{
            "cityId":133639,
            "pref":"東京都",
            "city":"新島村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾆｲｼﾞﾏﾑﾗ"
        },
        "133647":{
            "cityId":133647,
            "pref":"東京都",
            "city":"神津島村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｺｳﾂﾞｼﾏﾑﾗ"
        },
        "133817":{
            "cityId":133817,
            "pref":"東京都",
            "city":"三宅村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾐﾔｹﾑﾗ"
        },
        "133825":{
            "cityId":133825,
            "pref":"東京都",
            "city":"御蔵島村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾐｸﾗｼﾞﾏﾑﾗ"
        },
        "134015":{
            "cityId":134015,
            "pref":"東京都",
            "city":"八丈町",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ﾊﾁｼﾞｮｳﾏﾁ"
        },
        "134023":{
            "cityId":134023,
            "pref":"東京都",
            "city":"青ヶ島村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｱｵｶﾞｼﾏﾑﾗ"
        },
        "134210":{
            "cityId":134210,
            "pref":"東京都",
            "city":"小笠原村",
            "prefKana":"ﾄｳｷｮｳﾄ",
            "cityKana":"ｵｶﾞｻﾜﾗﾑﾗ"
        }
    },
    "神奈川":{
        "140007":{
            "cityId":140007,
            "pref":"神奈川県",
            "city":"",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":""
        },
        "141003":{
            "cityId":141003,
            "pref":"神奈川県",
            "city":"横浜市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾖｺﾊﾏｼ"
        },
        "141305":{
            "cityId":141305,
            "pref":"神奈川県",
            "city":"川崎市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｶﾜｻｷｼ"
        },
        "141500":{
            "cityId":141500,
            "pref":"神奈川県",
            "city":"相模原市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｻｶﾞﾐﾊﾗｼ"
        },
        "142018":{
            "cityId":142018,
            "pref":"神奈川県",
            "city":"横須賀市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾖｺｽｶｼ"
        },
        "142034":{
            "cityId":142034,
            "pref":"神奈川県",
            "city":"平塚市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾋﾗﾂｶｼ"
        },
        "142042":{
            "cityId":142042,
            "pref":"神奈川県",
            "city":"鎌倉市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｶﾏｸﾗｼ"
        },
        "142051":{
            "cityId":142051,
            "pref":"神奈川県",
            "city":"藤沢市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾌｼﾞｻﾜｼ"
        },
        "142069":{
            "cityId":142069,
            "pref":"神奈川県",
            "city":"小田原市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｵﾀﾞﾜﾗｼ"
        },
        "142077":{
            "cityId":142077,
            "pref":"神奈川県",
            "city":"茅ヶ崎市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾁｶﾞｻｷｼ"
        },
        "142085":{
            "cityId":142085,
            "pref":"神奈川県",
            "city":"逗子市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｽﾞｼｼ"
        },
        "142107":{
            "cityId":142107,
            "pref":"神奈川県",
            "city":"三浦市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾐｳﾗｼ"
        },
        "142115":{
            "cityId":142115,
            "pref":"神奈川県",
            "city":"秦野市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾊﾀﾞﾉｼ"
        },
        "142123":{
            "cityId":142123,
            "pref":"神奈川県",
            "city":"厚木市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｱﾂｷﾞｼ"
        },
        "142131":{
            "cityId":142131,
            "pref":"神奈川県",
            "city":"大和市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾔﾏﾄｼ"
        },
        "142140":{
            "cityId":142140,
            "pref":"神奈川県",
            "city":"伊勢原市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｲｾﾊﾗｼ"
        },
        "142158":{
            "cityId":142158,
            "pref":"神奈川県",
            "city":"海老名市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｴﾋﾞﾅｼ"
        },
        "142166":{
            "cityId":142166,
            "pref":"神奈川県",
            "city":"座間市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｻﾞﾏｼ"
        },
        "142174":{
            "cityId":142174,
            "pref":"神奈川県",
            "city":"南足柄市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾐﾅﾐｱｼｶﾞﾗｼ"
        },
        "142182":{
            "cityId":142182,
            "pref":"神奈川県",
            "city":"綾瀬市",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｱﾔｾｼ"
        },
        "143014":{
            "cityId":143014,
            "pref":"神奈川県",
            "city":"葉山町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾊﾔﾏﾏﾁ"
        },
        "143219":{
            "cityId":143219,
            "pref":"神奈川県",
            "city":"寒川町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｻﾑｶﾜﾏﾁ"
        },
        "143413":{
            "cityId":143413,
            "pref":"神奈川県",
            "city":"大磯町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｵｵｲｿﾏﾁ"
        },
        "143421":{
            "cityId":143421,
            "pref":"神奈川県",
            "city":"二宮町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾆﾉﾐﾔﾏﾁ"
        },
        "143618":{
            "cityId":143618,
            "pref":"神奈川県",
            "city":"中井町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾅｶｲﾏﾁ"
        },
        "143626":{
            "cityId":143626,
            "pref":"神奈川県",
            "city":"大井町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｵｵｲﾏﾁ"
        },
        "143634":{
            "cityId":143634,
            "pref":"神奈川県",
            "city":"松田町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾏﾂﾀﾞﾏﾁ"
        },
        "143642":{
            "cityId":143642,
            "pref":"神奈川県",
            "city":"山北町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾔﾏｷﾀﾏﾁ"
        },
        "143669":{
            "cityId":143669,
            "pref":"神奈川県",
            "city":"開成町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｶｲｾｲﾏﾁ"
        },
        "143821":{
            "cityId":143821,
            "pref":"神奈川県",
            "city":"箱根町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾊｺﾈﾏﾁ"
        },
        "143839":{
            "cityId":143839,
            "pref":"神奈川県",
            "city":"真鶴町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾏﾅﾂﾙﾏﾁ"
        },
        "143847":{
            "cityId":143847,
            "pref":"神奈川県",
            "city":"湯河原町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ﾕｶﾞﾜﾗﾏﾁ"
        },
        "144011":{
            "cityId":144011,
            "pref":"神奈川県",
            "city":"愛川町",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｱｲｶﾜﾏﾁ"
        },
        "144029":{
            "cityId":144029,
            "pref":"神奈川県",
            "city":"清川村",
            "prefKana":"ｶﾅｶﾞﾜｹﾝ",
            "cityKana":"ｷﾖｶﾜﾑﾗ"
        }
    },
    "新潟":{
        "150002":{
            "cityId":150002,
            "pref":"新潟県",
            "city":"",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":""
        },
        "151009":{
            "cityId":151009,
            "pref":"新潟県",
            "city":"新潟市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾆｲｶﾞﾀｼ"
        },
        "152021":{
            "cityId":152021,
            "pref":"新潟県",
            "city":"長岡市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾅｶﾞｵｶｼ"
        },
        "152048":{
            "cityId":152048,
            "pref":"新潟県",
            "city":"三条市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｻﾝｼﾞｮｳｼ"
        },
        "152056":{
            "cityId":152056,
            "pref":"新潟県",
            "city":"柏崎市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｶｼﾜｻﾞｷｼ"
        },
        "152064":{
            "cityId":152064,
            "pref":"新潟県",
            "city":"新発田市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｼﾊﾞﾀｼ"
        },
        "152081":{
            "cityId":152081,
            "pref":"新潟県",
            "city":"小千谷市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｵﾁﾞﾔｼ"
        },
        "152099":{
            "cityId":152099,
            "pref":"新潟県",
            "city":"加茂市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｶﾓｼ"
        },
        "152102":{
            "cityId":152102,
            "pref":"新潟県",
            "city":"十日町市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾄｵｶﾏﾁｼ"
        },
        "152111":{
            "cityId":152111,
            "pref":"新潟県",
            "city":"見附市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾐﾂｹｼ"
        },
        "152129":{
            "cityId":152129,
            "pref":"新潟県",
            "city":"村上市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾑﾗｶﾐｼ"
        },
        "152137":{
            "cityId":152137,
            "pref":"新潟県",
            "city":"燕市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾂﾊﾞﾒｼ"
        },
        "152161":{
            "cityId":152161,
            "pref":"新潟県",
            "city":"糸魚川市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｲﾄｲｶﾞﾜｼ"
        },
        "152170":{
            "cityId":152170,
            "pref":"新潟県",
            "city":"妙高市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾐｮｳｺｳｼ"
        },
        "152188":{
            "cityId":152188,
            "pref":"新潟県",
            "city":"五泉市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｺﾞｾﾝｼ"
        },
        "152226":{
            "cityId":152226,
            "pref":"新潟県",
            "city":"上越市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｼﾞｮｳｴﾂｼ"
        },
        "152234":{
            "cityId":152234,
            "pref":"新潟県",
            "city":"阿賀野市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｱｶﾞﾉｼ"
        },
        "152242":{
            "cityId":152242,
            "pref":"新潟県",
            "city":"佐渡市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｻﾄﾞｼ"
        },
        "152251":{
            "cityId":152251,
            "pref":"新潟県",
            "city":"魚沼市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｳｵﾇﾏｼ"
        },
        "152269":{
            "cityId":152269,
            "pref":"新潟県",
            "city":"南魚沼市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾐﾅﾐｳｵﾇﾏｼ"
        },
        "152277":{
            "cityId":152277,
            "pref":"新潟県",
            "city":"胎内市",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾀｲﾅｲｼ"
        },
        "153079":{
            "cityId":153079,
            "pref":"新潟県",
            "city":"聖籠町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｾｲﾛｳﾏﾁ"
        },
        "153427":{
            "cityId":153427,
            "pref":"新潟県",
            "city":"弥彦村",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾔﾋｺﾑﾗ"
        },
        "153613":{
            "cityId":153613,
            "pref":"新潟県",
            "city":"田上町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾀｶﾞﾐﾏﾁ"
        },
        "153851":{
            "cityId":153851,
            "pref":"新潟県",
            "city":"阿賀町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｱｶﾞﾏﾁ"
        },
        "154059":{
            "cityId":154059,
            "pref":"新潟県",
            "city":"出雲崎町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｲｽﾞﾓｻﾞｷﾏﾁ"
        },
        "154610":{
            "cityId":154610,
            "pref":"新潟県",
            "city":"湯沢町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾕｻﾞﾜﾏﾁ"
        },
        "154822":{
            "cityId":154822,
            "pref":"新潟県",
            "city":"津南町",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ﾂﾅﾝﾏﾁ"
        },
        "155047":{
            "cityId":155047,
            "pref":"新潟県",
            "city":"刈羽村",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｶﾘﾜﾑﾗ"
        },
        "155811":{
            "cityId":155811,
            "pref":"新潟県",
            "city":"関川村",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｾｷｶﾜﾑﾗ"
        },
        "155861":{
            "cityId":155861,
            "pref":"新潟県",
            "city":"粟島浦村",
            "prefKana":"ﾆｲｶﾞﾀｹﾝ",
            "cityKana":"ｱﾜｼﾏｳﾗﾑﾗ"
        }
    },
    "富山":{
        "160008":{
            "cityId":160008,
            "pref":"富山県",
            "city":"",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":""
        },
        "162019":{
            "cityId":162019,
            "pref":"富山県",
            "city":"富山市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾄﾔﾏｼ"
        },
        "162027":{
            "cityId":162027,
            "pref":"富山県",
            "city":"高岡市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾀｶｵｶｼ"
        },
        "162043":{
            "cityId":162043,
            "pref":"富山県",
            "city":"魚津市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｳｵﾂﾞｼ"
        },
        "162051":{
            "cityId":162051,
            "pref":"富山県",
            "city":"氷見市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾋﾐｼ"
        },
        "162060":{
            "cityId":162060,
            "pref":"富山県",
            "city":"滑川市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾅﾒﾘｶﾜｼ"
        },
        "162078":{
            "cityId":162078,
            "pref":"富山県",
            "city":"黒部市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｸﾛﾍﾞｼ"
        },
        "162086":{
            "cityId":162086,
            "pref":"富山県",
            "city":"砺波市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾄﾅﾐｼ"
        },
        "162094":{
            "cityId":162094,
            "pref":"富山県",
            "city":"小矢部市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｵﾔﾍﾞｼ"
        },
        "162108":{
            "cityId":162108,
            "pref":"富山県",
            "city":"南砺市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾅﾝﾄｼ"
        },
        "162116":{
            "cityId":162116,
            "pref":"富山県",
            "city":"射水市",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｲﾐｽﾞｼ"
        },
        "163210":{
            "cityId":163210,
            "pref":"富山県",
            "city":"舟橋村",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾌﾅﾊｼﾑﾗ"
        },
        "163228":{
            "cityId":163228,
            "pref":"富山県",
            "city":"上市町",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｶﾐｲﾁﾏﾁ"
        },
        "163236":{
            "cityId":163236,
            "pref":"富山県",
            "city":"立山町",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾀﾃﾔﾏﾏﾁ"
        },
        "163422":{
            "cityId":163422,
            "pref":"富山県",
            "city":"入善町",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ﾆｭｳｾﾞﾝﾏﾁ"
        },
        "163431":{
            "cityId":163431,
            "pref":"富山県",
            "city":"朝日町",
            "prefKana":"ﾄﾔﾏｹﾝ",
            "cityKana":"ｱｻﾋﾏﾁ"
        }
    },
    "石川":{
        "170003":{
            "cityId":170003,
            "pref":"石川県",
            "city":"",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":""
        },
        "172014":{
            "cityId":172014,
            "pref":"石川県",
            "city":"金沢市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｶﾅｻﾞﾜｼ"
        },
        "172022":{
            "cityId":172022,
            "pref":"石川県",
            "city":"七尾市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾅﾅｵｼ"
        },
        "172031":{
            "cityId":172031,
            "pref":"石川県",
            "city":"小松市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｺﾏﾂｼ"
        },
        "172049":{
            "cityId":172049,
            "pref":"石川県",
            "city":"輪島市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾜｼﾞﾏｼ"
        },
        "172057":{
            "cityId":172057,
            "pref":"石川県",
            "city":"珠洲市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｽｽﾞｼ"
        },
        "172065":{
            "cityId":172065,
            "pref":"石川県",
            "city":"加賀市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｶｶﾞｼ"
        },
        "172073":{
            "cityId":172073,
            "pref":"石川県",
            "city":"羽咋市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾊｸｲｼ"
        },
        "172090":{
            "cityId":172090,
            "pref":"石川県",
            "city":"かほく市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｶﾎｸｼ"
        },
        "172103":{
            "cityId":172103,
            "pref":"石川県",
            "city":"白山市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾊｸｻﾝｼ"
        },
        "172111":{
            "cityId":172111,
            "pref":"石川県",
            "city":"能美市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾉﾐｼ"
        },
        "172120":{
            "cityId":172120,
            "pref":"石川県",
            "city":"野々市市",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾉﾉｲﾁｼ"
        },
        "173240":{
            "cityId":173240,
            "pref":"石川県",
            "city":"川北町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｶﾜｷﾀﾏﾁ"
        },
        "173614":{
            "cityId":173614,
            "pref":"石川県",
            "city":"津幡町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾂﾊﾞﾀﾏﾁ"
        },
        "173657":{
            "cityId":173657,
            "pref":"石川県",
            "city":"内灘町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｳﾁﾅﾀﾞﾏﾁ"
        },
        "173843":{
            "cityId":173843,
            "pref":"石川県",
            "city":"志賀町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｼｶﾏﾁ"
        },
        "173860":{
            "cityId":173860,
            "pref":"石川県",
            "city":"宝達志水町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾎｳﾀﾞﾂｼﾐｽﾞﾁｮｳ"
        },
        "174076":{
            "cityId":174076,
            "pref":"石川県",
            "city":"中能登町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾅｶﾉﾄﾏﾁ"
        },
        "174611":{
            "cityId":174611,
            "pref":"石川県",
            "city":"穴水町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ｱﾅﾐｽﾞﾏﾁ"
        },
        "174637":{
            "cityId":174637,
            "pref":"石川県",
            "city":"能登町",
            "prefKana":"ｲｼｶﾜｹﾝ",
            "cityKana":"ﾉﾄﾁｮｳ"
        }
    },
    "福井":{
        "180009":{
            "cityId":180009,
            "pref":"福井県",
            "city":"",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":""
        },
        "182010":{
            "cityId":182010,
            "pref":"福井県",
            "city":"福井市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾌｸｲｼ"
        },
        "182028":{
            "cityId":182028,
            "pref":"福井県",
            "city":"敦賀市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾂﾙｶﾞｼ"
        },
        "182044":{
            "cityId":182044,
            "pref":"福井県",
            "city":"小浜市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｵﾊﾞﾏｼ"
        },
        "182052":{
            "cityId":182052,
            "pref":"福井県",
            "city":"大野市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｵｵﾉｼ"
        },
        "182061":{
            "cityId":182061,
            "pref":"福井県",
            "city":"勝山市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｶﾂﾔﾏｼ"
        },
        "182079":{
            "cityId":182079,
            "pref":"福井県",
            "city":"鯖江市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｻﾊﾞｴｼ"
        },
        "182087":{
            "cityId":182087,
            "pref":"福井県",
            "city":"あわら市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｱﾜﾗｼ"
        },
        "182095":{
            "cityId":182095,
            "pref":"福井県",
            "city":"越前市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｴﾁｾﾞﾝｼ"
        },
        "182109":{
            "cityId":182109,
            "pref":"福井県",
            "city":"坂井市",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｻｶｲｼ"
        },
        "183229":{
            "cityId":183229,
            "pref":"福井県",
            "city":"永平寺町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｴｲﾍｲｼﾞﾁｮｳ"
        },
        "183822":{
            "cityId":183822,
            "pref":"福井県",
            "city":"池田町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｲｹﾀﾞﾁｮｳ"
        },
        "184047":{
            "cityId":184047,
            "pref":"福井県",
            "city":"南越前町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾐﾅﾐｴﾁｾﾞﾝﾁｮｳ"
        },
        "184233":{
            "cityId":184233,
            "pref":"福井県",
            "city":"越前町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｴﾁｾﾞﾝﾁｮｳ"
        },
        "184420":{
            "cityId":184420,
            "pref":"福井県",
            "city":"美浜町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾐﾊﾏﾁｮｳ"
        },
        "184811":{
            "cityId":184811,
            "pref":"福井県",
            "city":"高浜町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾀｶﾊﾏﾁｮｳ"
        },
        "184837":{
            "cityId":184837,
            "pref":"福井県",
            "city":"おおい町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ｵｵｲﾁｮｳ"
        },
        "185019":{
            "cityId":185019,
            "pref":"福井県",
            "city":"若狭町",
            "prefKana":"ﾌｸｲｹﾝ",
            "cityKana":"ﾜｶｻﾁｮｳ"
        }
    },
    "山梨":{
        "190004":{
            "cityId":190004,
            "pref":"山梨県",
            "city":"",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":""
        },
        "192015":{
            "cityId":192015,
            "pref":"山梨県",
            "city":"甲府市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｺｳﾌｼ"
        },
        "192023":{
            "cityId":192023,
            "pref":"山梨県",
            "city":"富士吉田市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾌｼﾞﾖｼﾀﾞｼ"
        },
        "192040":{
            "cityId":192040,
            "pref":"山梨県",
            "city":"都留市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾂﾙｼ"
        },
        "192058":{
            "cityId":192058,
            "pref":"山梨県",
            "city":"山梨市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾔﾏﾅｼｼ"
        },
        "192066":{
            "cityId":192066,
            "pref":"山梨県",
            "city":"大月市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｵｵﾂｷｼ"
        },
        "192074":{
            "cityId":192074,
            "pref":"山梨県",
            "city":"韮崎市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾆﾗｻｷｼ"
        },
        "192082":{
            "cityId":192082,
            "pref":"山梨県",
            "city":"南アルプス市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾐﾅﾐｱﾙﾌﾟｽｼ"
        },
        "192091":{
            "cityId":192091,
            "pref":"山梨県",
            "city":"北杜市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾎｸﾄｼ"
        },
        "192104":{
            "cityId":192104,
            "pref":"山梨県",
            "city":"甲斐市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｶｲｼ"
        },
        "192112":{
            "cityId":192112,
            "pref":"山梨県",
            "city":"笛吹市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾌｴﾌｷｼ"
        },
        "192121":{
            "cityId":192121,
            "pref":"山梨県",
            "city":"上野原市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｳｴﾉﾊﾗｼ"
        },
        "192139":{
            "cityId":192139,
            "pref":"山梨県",
            "city":"甲州市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｺｳｼｭｳｼ"
        },
        "192147":{
            "cityId":192147,
            "pref":"山梨県",
            "city":"中央市",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾁｭｳｵｳｼ"
        },
        "193461":{
            "cityId":193461,
            "pref":"山梨県",
            "city":"市川三郷町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｲﾁｶﾜﾐｻﾄﾁｮｳ"
        },
        "193640":{
            "cityId":193640,
            "pref":"山梨県",
            "city":"早川町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾊﾔｶﾜﾁｮｳ"
        },
        "193658":{
            "cityId":193658,
            "pref":"山梨県",
            "city":"身延町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾐﾉﾌﾞﾁｮｳ"
        },
        "193666":{
            "cityId":193666,
            "pref":"山梨県",
            "city":"南部町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾅﾝﾌﾞﾁｮｳ"
        },
        "193682":{
            "cityId":193682,
            "pref":"山梨県",
            "city":"富士川町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾌｼﾞｶﾜﾁｮｳ"
        },
        "193844":{
            "cityId":193844,
            "pref":"山梨県",
            "city":"昭和町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｼｮｳﾜﾁｮｳ"
        },
        "194221":{
            "cityId":194221,
            "pref":"山梨県",
            "city":"道志村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾄﾞｳｼﾑﾗ"
        },
        "194239":{
            "cityId":194239,
            "pref":"山梨県",
            "city":"西桂町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾆｼｶﾂﾗﾁｮｳ"
        },
        "194247":{
            "cityId":194247,
            "pref":"山梨県",
            "city":"忍野村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｵｼﾉﾑﾗ"
        },
        "194255":{
            "cityId":194255,
            "pref":"山梨県",
            "city":"山中湖村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾔﾏﾅｶｺﾑﾗ"
        },
        "194298":{
            "cityId":194298,
            "pref":"山梨県",
            "city":"鳴沢村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾅﾙｻﾜﾑﾗ"
        },
        "194301":{
            "cityId":194301,
            "pref":"山梨県",
            "city":"富士河口湖町",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾌｼﾞｶﾜｸﾞﾁｺﾏﾁ"
        },
        "194425":{
            "cityId":194425,
            "pref":"山梨県",
            "city":"小菅村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ｺｽｹﾞﾑﾗ"
        },
        "194433":{
            "cityId":194433,
            "pref":"山梨県",
            "city":"丹波山村",
            "prefKana":"ﾔﾏﾅｼｹﾝ",
            "cityKana":"ﾀﾊﾞﾔﾏﾑﾗ"
        }
    },
    "長野":{
        "200000":{
            "cityId":200000,
            "pref":"長野県",
            "city":"",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":""
        },
        "202011":{
            "cityId":202011,
            "pref":"長野県",
            "city":"長野市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾅｶﾞﾉｼ"
        },
        "202029":{
            "cityId":202029,
            "pref":"長野県",
            "city":"松本市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾏﾂﾓﾄｼ"
        },
        "202037":{
            "cityId":202037,
            "pref":"長野県",
            "city":"上田市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｳｴﾀﾞｼ"
        },
        "202045":{
            "cityId":202045,
            "pref":"長野県",
            "city":"岡谷市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｶﾔｼ"
        },
        "202053":{
            "cityId":202053,
            "pref":"長野県",
            "city":"飯田市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｲﾀﾞｼ"
        },
        "202061":{
            "cityId":202061,
            "pref":"長野県",
            "city":"諏訪市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｽﾜｼ"
        },
        "202070":{
            "cityId":202070,
            "pref":"長野県",
            "city":"須坂市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｽｻﾞｶｼ"
        },
        "202088":{
            "cityId":202088,
            "pref":"長野県",
            "city":"小諸市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｺﾓﾛｼ"
        },
        "202096":{
            "cityId":202096,
            "pref":"長野県",
            "city":"伊那市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲﾅｼ"
        },
        "202100":{
            "cityId":202100,
            "pref":"長野県",
            "city":"駒ヶ根市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｺﾏｶﾞﾈｼ"
        },
        "202118":{
            "cityId":202118,
            "pref":"長野県",
            "city":"中野市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾅｶﾉｼ"
        },
        "202126":{
            "cityId":202126,
            "pref":"長野県",
            "city":"大町市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｵﾏﾁｼ"
        },
        "202134":{
            "cityId":202134,
            "pref":"長野県",
            "city":"飯山市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｲﾔﾏｼ"
        },
        "202142":{
            "cityId":202142,
            "pref":"長野県",
            "city":"茅野市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾁﾉｼ"
        },
        "202151":{
            "cityId":202151,
            "pref":"長野県",
            "city":"塩尻市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｼｵｼﾞﾘｼ"
        },
        "202177":{
            "cityId":202177,
            "pref":"長野県",
            "city":"佐久市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｻｸｼ"
        },
        "202185":{
            "cityId":202185,
            "pref":"長野県",
            "city":"千曲市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾁｸﾏｼ"
        },
        "202193":{
            "cityId":202193,
            "pref":"長野県",
            "city":"東御市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾄｳﾐｼ"
        },
        "202207":{
            "cityId":202207,
            "pref":"長野県",
            "city":"安曇野市",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱﾂﾞﾐﾉｼ"
        },
        "203033":{
            "cityId":203033,
            "pref":"長野県",
            "city":"小海町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｺｳﾐﾏﾁ"
        },
        "203041":{
            "cityId":203041,
            "pref":"長野県",
            "city":"川上村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｶﾜｶﾐﾑﾗ"
        },
        "203050":{
            "cityId":203050,
            "pref":"長野県",
            "city":"南牧村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾅﾐﾏｷﾑﾗ"
        },
        "203068":{
            "cityId":203068,
            "pref":"長野県",
            "city":"南相木村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾅﾐｱｲｷﾑﾗ"
        },
        "203076":{
            "cityId":203076,
            "pref":"長野県",
            "city":"北相木村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｷﾀｱｲｷﾑﾗ"
        },
        "203092":{
            "cityId":203092,
            "pref":"長野県",
            "city":"佐久穂町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｻｸﾎﾏﾁ"
        },
        "203211":{
            "cityId":203211,
            "pref":"長野県",
            "city":"軽井沢町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｶﾙｲｻﾞﾜﾏﾁ"
        },
        "203238":{
            "cityId":203238,
            "pref":"長野県",
            "city":"御代田町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾖﾀﾏﾁ"
        },
        "203246":{
            "cityId":203246,
            "pref":"長野県",
            "city":"立科町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾀﾃｼﾅﾏﾁ"
        },
        "203491":{
            "cityId":203491,
            "pref":"長野県",
            "city":"青木村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱｵｷﾑﾗ"
        },
        "203505":{
            "cityId":203505,
            "pref":"長野県",
            "city":"長和町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾅｶﾞﾜﾏﾁ"
        },
        "203611":{
            "cityId":203611,
            "pref":"長野県",
            "city":"下諏訪町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｼﾓｽﾜﾏﾁ"
        },
        "203629":{
            "cityId":203629,
            "pref":"長野県",
            "city":"富士見町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾌｼﾞﾐﾏﾁ"
        },
        "203637":{
            "cityId":203637,
            "pref":"長野県",
            "city":"原村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾊﾗﾑﾗ"
        },
        "203823":{
            "cityId":203823,
            "pref":"長野県",
            "city":"辰野町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾀﾂﾉﾏﾁ"
        },
        "203831":{
            "cityId":203831,
            "pref":"長野県",
            "city":"箕輪町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾉﾜﾏﾁ"
        },
        "203840":{
            "cityId":203840,
            "pref":"長野県",
            "city":"飯島町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｲｼﾞﾏﾏﾁ"
        },
        "203858":{
            "cityId":203858,
            "pref":"長野県",
            "city":"南箕輪村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾅﾐﾐﾉﾜﾑﾗ"
        },
        "203866":{
            "cityId":203866,
            "pref":"長野県",
            "city":"中川村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾅｶｶﾞﾜﾑﾗ"
        },
        "203882":{
            "cityId":203882,
            "pref":"長野県",
            "city":"宮田村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾐﾔﾀﾞﾑﾗ"
        },
        "204021":{
            "cityId":204021,
            "pref":"長野県",
            "city":"松川町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾏﾂｶﾜﾏﾁ"
        },
        "204030":{
            "cityId":204030,
            "pref":"長野県",
            "city":"高森町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾀｶﾓﾘﾏﾁ"
        },
        "204048":{
            "cityId":204048,
            "pref":"長野県",
            "city":"阿南町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱﾅﾝﾁｮｳ"
        },
        "204072":{
            "cityId":204072,
            "pref":"長野県",
            "city":"阿智村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱﾁﾑﾗ"
        },
        "204099":{
            "cityId":204099,
            "pref":"長野県",
            "city":"平谷村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾋﾗﾔﾑﾗ"
        },
        "204102":{
            "cityId":204102,
            "pref":"長野県",
            "city":"根羽村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾈﾊﾞﾑﾗ"
        },
        "204111":{
            "cityId":204111,
            "pref":"長野県",
            "city":"下條村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｼﾓｼﾞｮｳﾑﾗ"
        },
        "204129":{
            "cityId":204129,
            "pref":"長野県",
            "city":"売木村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｳﾙｷﾞﾑﾗ"
        },
        "204137":{
            "cityId":204137,
            "pref":"長野県",
            "city":"天龍村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾃﾝﾘｭｳﾑﾗ"
        },
        "204145":{
            "cityId":204145,
            "pref":"長野県",
            "city":"泰阜村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾔｽｵｶﾑﾗ"
        },
        "204153":{
            "cityId":204153,
            "pref":"長野県",
            "city":"喬木村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾀｶｷﾞﾑﾗ"
        },
        "204161":{
            "cityId":204161,
            "pref":"長野県",
            "city":"豊丘村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾄﾖｵｶﾑﾗ"
        },
        "204170":{
            "cityId":204170,
            "pref":"長野県",
            "city":"大鹿村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｵｼｶﾑﾗ"
        },
        "204226":{
            "cityId":204226,
            "pref":"長野県",
            "city":"上松町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱｹﾞﾏﾂﾏﾁ"
        },
        "204234":{
            "cityId":204234,
            "pref":"長野県",
            "city":"南木曽町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾅｷﾞｿﾏﾁ"
        },
        "204251":{
            "cityId":204251,
            "pref":"長野県",
            "city":"木祖村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｷｿﾑﾗ"
        },
        "204293":{
            "cityId":204293,
            "pref":"長野県",
            "city":"王滝村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｳﾀｷﾑﾗ"
        },
        "204307":{
            "cityId":204307,
            "pref":"長野県",
            "city":"大桑村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｵｸﾜﾑﾗ"
        },
        "204323":{
            "cityId":204323,
            "pref":"長野県",
            "city":"木曽町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｷｿﾏﾁ"
        },
        "204463":{
            "cityId":204463,
            "pref":"長野県",
            "city":"麻績村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵﾐﾑﾗ"
        },
        "204480":{
            "cityId":204480,
            "pref":"長野県",
            "city":"生坂村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｸｻｶﾑﾗ"
        },
        "204501":{
            "cityId":204501,
            "pref":"長野県",
            "city":"山形村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾔﾏｶﾞﾀﾑﾗ"
        },
        "204510":{
            "cityId":204510,
            "pref":"長野県",
            "city":"朝日村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｱｻﾋﾑﾗ"
        },
        "204528":{
            "cityId":204528,
            "pref":"長野県",
            "city":"筑北村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾁｸﾎｸﾑﾗ"
        },
        "204811":{
            "cityId":204811,
            "pref":"長野県",
            "city":"池田町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｹﾀﾞﾏﾁ"
        },
        "204820":{
            "cityId":204820,
            "pref":"長野県",
            "city":"松川村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾏﾂｶﾜﾑﾗ"
        },
        "204854":{
            "cityId":204854,
            "pref":"長野県",
            "city":"白馬村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾊｸﾊﾞﾑﾗ"
        },
        "204862":{
            "cityId":204862,
            "pref":"長野県",
            "city":"小谷村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵﾀﾘﾑﾗ"
        },
        "205214":{
            "cityId":205214,
            "pref":"長野県",
            "city":"坂城町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｻｶｷﾏﾁ"
        },
        "205419":{
            "cityId":205419,
            "pref":"長野県",
            "city":"小布施町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵﾌﾞｾﾏﾁ"
        },
        "205435":{
            "cityId":205435,
            "pref":"長野県",
            "city":"高山村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾀｶﾔﾏﾑﾗ"
        },
        "205613":{
            "cityId":205613,
            "pref":"長野県",
            "city":"山ノ内町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾔﾏﾉｳﾁﾏﾁ"
        },
        "205621":{
            "cityId":205621,
            "pref":"長野県",
            "city":"木島平村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｷｼﾞﾏﾀﾞｲﾗﾑﾗ"
        },
        "205630":{
            "cityId":205630,
            "pref":"長野県",
            "city":"野沢温泉村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ﾉｻﾞﾜｵﾝｾﾝﾑﾗ"
        },
        "205834":{
            "cityId":205834,
            "pref":"長野県",
            "city":"信濃町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｼﾅﾉﾏﾁ"
        },
        "205885":{
            "cityId":205885,
            "pref":"長野県",
            "city":"小川村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｵｶﾞﾜﾑﾗ"
        },
        "205907":{
            "cityId":205907,
            "pref":"長野県",
            "city":"飯綱町",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｲｲﾂﾞﾅﾏﾁ"
        },
        "206024":{
            "cityId":206024,
            "pref":"長野県",
            "city":"栄村",
            "prefKana":"ﾅｶﾞﾉｹﾝ",
            "cityKana":"ｻｶｴﾑﾗ"
        }
    },
    "岐阜":{
        "210005":{
            "cityId":210005,
            "pref":"岐阜県",
            "city":"",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":""
        },
        "212016":{
            "cityId":212016,
            "pref":"岐阜県",
            "city":"岐阜市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｷﾞﾌｼ"
        },
        "212024":{
            "cityId":212024,
            "pref":"岐阜県",
            "city":"大垣市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｵｵｶﾞｷｼ"
        },
        "212032":{
            "cityId":212032,
            "pref":"岐阜県",
            "city":"高山市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾀｶﾔﾏｼ"
        },
        "212041":{
            "cityId":212041,
            "pref":"岐阜県",
            "city":"多治見市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾀｼﾞﾐｼ"
        },
        "212059":{
            "cityId":212059,
            "pref":"岐阜県",
            "city":"関市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｾｷｼ"
        },
        "212067":{
            "cityId":212067,
            "pref":"岐阜県",
            "city":"中津川市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾅｶﾂｶﾞﾜｼ"
        },
        "212075":{
            "cityId":212075,
            "pref":"岐阜県",
            "city":"美濃市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾐﾉｼ"
        },
        "212083":{
            "cityId":212083,
            "pref":"岐阜県",
            "city":"瑞浪市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾐｽﾞﾅﾐｼ"
        },
        "212091":{
            "cityId":212091,
            "pref":"岐阜県",
            "city":"羽島市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾊｼﾏｼ"
        },
        "212105":{
            "cityId":212105,
            "pref":"岐阜県",
            "city":"恵那市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｴﾅｼ"
        },
        "212113":{
            "cityId":212113,
            "pref":"岐阜県",
            "city":"美濃加茂市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾐﾉｶﾓｼ"
        },
        "212121":{
            "cityId":212121,
            "pref":"岐阜県",
            "city":"土岐市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾄｷｼ"
        },
        "212130":{
            "cityId":212130,
            "pref":"岐阜県",
            "city":"各務原市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｶｶﾐｶﾞﾊﾗｼ"
        },
        "212148":{
            "cityId":212148,
            "pref":"岐阜県",
            "city":"可児市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｶﾆｼ"
        },
        "212156":{
            "cityId":212156,
            "pref":"岐阜県",
            "city":"山県市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾔﾏｶﾞﾀｼ"
        },
        "212164":{
            "cityId":212164,
            "pref":"岐阜県",
            "city":"瑞穂市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾐｽﾞﾎｼ"
        },
        "212172":{
            "cityId":212172,
            "pref":"岐阜県",
            "city":"飛騨市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾋﾀﾞｼ"
        },
        "212181":{
            "cityId":212181,
            "pref":"岐阜県",
            "city":"本巣市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾓﾄｽｼ"
        },
        "212199":{
            "cityId":212199,
            "pref":"岐阜県",
            "city":"郡上市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｸﾞｼﾞｮｳｼ"
        },
        "212202":{
            "cityId":212202,
            "pref":"岐阜県",
            "city":"下呂市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｹﾞﾛｼ"
        },
        "212211":{
            "cityId":212211,
            "pref":"岐阜県",
            "city":"海津市",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｶｲﾂﾞｼ"
        },
        "213021":{
            "cityId":213021,
            "pref":"岐阜県",
            "city":"岐南町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｷﾞﾅﾝﾁｮｳ"
        },
        "213039":{
            "cityId":213039,
            "pref":"岐阜県",
            "city":"笠松町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｶｻﾏﾂﾁｮｳ"
        },
        "213411":{
            "cityId":213411,
            "pref":"岐阜県",
            "city":"養老町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾖｳﾛｳﾁｮｳ"
        },
        "213616":{
            "cityId":213616,
            "pref":"岐阜県",
            "city":"垂井町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾀﾙｲﾁｮｳ"
        },
        "213624":{
            "cityId":213624,
            "pref":"岐阜県",
            "city":"関ケ原町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｾｷｶﾞﾊﾗﾁｮｳ"
        },
        "213811":{
            "cityId":213811,
            "pref":"岐阜県",
            "city":"神戸町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｺﾞｳﾄﾞﾁｮｳ"
        },
        "213829":{
            "cityId":213829,
            "pref":"岐阜県",
            "city":"輪之内町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾜﾉｳﾁﾁｮｳ"
        },
        "213837":{
            "cityId":213837,
            "pref":"岐阜県",
            "city":"安八町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｱﾝﾊﾟﾁﾁｮｳ"
        },
        "214019":{
            "cityId":214019,
            "pref":"岐阜県",
            "city":"揖斐川町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｲﾋﾞｶﾞﾜﾁｮｳ"
        },
        "214035":{
            "cityId":214035,
            "pref":"岐阜県",
            "city":"大野町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｵｵﾉﾁｮｳ"
        },
        "214043":{
            "cityId":214043,
            "pref":"岐阜県",
            "city":"池田町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｲｹﾀﾞﾁｮｳ"
        },
        "214213":{
            "cityId":214213,
            "pref":"岐阜県",
            "city":"北方町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｷﾀｶﾞﾀﾁｮｳ"
        },
        "215015":{
            "cityId":215015,
            "pref":"岐阜県",
            "city":"坂祝町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｻｶﾎｷﾞﾁｮｳ"
        },
        "215023":{
            "cityId":215023,
            "pref":"岐阜県",
            "city":"富加町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾄﾐｶﾁｮｳ"
        },
        "215031":{
            "cityId":215031,
            "pref":"岐阜県",
            "city":"川辺町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｶﾜﾍﾞﾁｮｳ"
        },
        "215040":{
            "cityId":215040,
            "pref":"岐阜県",
            "city":"七宗町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾋﾁｿｳﾁｮｳ"
        },
        "215058":{
            "cityId":215058,
            "pref":"岐阜県",
            "city":"八百津町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾔｵﾂﾁｮｳ"
        },
        "215066":{
            "cityId":215066,
            "pref":"岐阜県",
            "city":"白川町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｼﾗｶﾜﾁｮｳ"
        },
        "215074":{
            "cityId":215074,
            "pref":"岐阜県",
            "city":"東白川村",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾋｶﾞｼｼﾗｶﾜﾑﾗ"
        },
        "215210":{
            "cityId":215210,
            "pref":"岐阜県",
            "city":"御嵩町",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ﾐﾀｹﾁｮｳ"
        },
        "216046":{
            "cityId":216046,
            "pref":"岐阜県",
            "city":"白川村",
            "prefKana":"ｷﾞﾌｹﾝ",
            "cityKana":"ｼﾗｶﾜﾑﾗ"
        }
    },
    "静岡":{
        "220001":{
            "cityId":220001,
            "pref":"静岡県",
            "city":"",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":""
        },
        "221007":{
            "cityId":221007,
            "pref":"静岡県",
            "city":"静岡市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｼｽﾞｵｶｼ"
        },
        "221309":{
            "cityId":221309,
            "pref":"静岡県",
            "city":"浜松市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾊﾏﾏﾂｼ"
        },
        "222038":{
            "cityId":222038,
            "pref":"静岡県",
            "city":"沼津市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾇﾏﾂﾞｼ"
        },
        "222054":{
            "cityId":222054,
            "pref":"静岡県",
            "city":"熱海市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｱﾀﾐｼ"
        },
        "222062":{
            "cityId":222062,
            "pref":"静岡県",
            "city":"三島市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾐｼﾏｼ"
        },
        "222071":{
            "cityId":222071,
            "pref":"静岡県",
            "city":"富士宮市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾌｼﾞﾉﾐﾔｼ"
        },
        "222089":{
            "cityId":222089,
            "pref":"静岡県",
            "city":"伊東市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｲﾄｳｼ"
        },
        "222097":{
            "cityId":222097,
            "pref":"静岡県",
            "city":"島田市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｼﾏﾀﾞｼ"
        },
        "222101":{
            "cityId":222101,
            "pref":"静岡県",
            "city":"富士市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾌｼﾞｼ"
        },
        "222119":{
            "cityId":222119,
            "pref":"静岡県",
            "city":"磐田市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｲﾜﾀｼ"
        },
        "222127":{
            "cityId":222127,
            "pref":"静岡県",
            "city":"焼津市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾔｲﾂﾞｼ"
        },
        "222135":{
            "cityId":222135,
            "pref":"静岡県",
            "city":"掛川市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｶｹｶﾞﾜｼ"
        },
        "222143":{
            "cityId":222143,
            "pref":"静岡県",
            "city":"藤枝市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾌｼﾞｴﾀﾞｼ"
        },
        "222151":{
            "cityId":222151,
            "pref":"静岡県",
            "city":"御殿場市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｺﾞﾃﾝﾊﾞｼ"
        },
        "222160":{
            "cityId":222160,
            "pref":"静岡県",
            "city":"袋井市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾌｸﾛｲｼ"
        },
        "222194":{
            "cityId":222194,
            "pref":"静岡県",
            "city":"下田市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｼﾓﾀﾞｼ"
        },
        "222208":{
            "cityId":222208,
            "pref":"静岡県",
            "city":"裾野市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｽｿﾉｼ"
        },
        "222216":{
            "cityId":222216,
            "pref":"静岡県",
            "city":"湖西市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｺｻｲｼ"
        },
        "222224":{
            "cityId":222224,
            "pref":"静岡県",
            "city":"伊豆市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｲｽﾞｼ"
        },
        "222232":{
            "cityId":222232,
            "pref":"静岡県",
            "city":"御前崎市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｵﾏｴｻﾞｷｼ"
        },
        "222241":{
            "cityId":222241,
            "pref":"静岡県",
            "city":"菊川市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｷｸｶﾞﾜｼ"
        },
        "222259":{
            "cityId":222259,
            "pref":"静岡県",
            "city":"伊豆の国市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｲｽﾞﾉｸﾆｼ"
        },
        "222267":{
            "cityId":222267,
            "pref":"静岡県",
            "city":"牧之原市",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾏｷﾉﾊﾗｼ"
        },
        "223018":{
            "cityId":223018,
            "pref":"静岡県",
            "city":"東伊豆町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾋｶﾞｼｲｽﾞﾁｮｳ"
        },
        "223026":{
            "cityId":223026,
            "pref":"静岡県",
            "city":"河津町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｶﾜﾂﾞﾁｮｳ"
        },
        "223042":{
            "cityId":223042,
            "pref":"静岡県",
            "city":"南伊豆町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾐﾅﾐｲｽﾞﾁｮｳ"
        },
        "223051":{
            "cityId":223051,
            "pref":"静岡県",
            "city":"松崎町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾏﾂｻﾞｷﾁｮｳ"
        },
        "223069":{
            "cityId":223069,
            "pref":"静岡県",
            "city":"西伊豆町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾆｼｲｽﾞﾁｮｳ"
        },
        "223255":{
            "cityId":223255,
            "pref":"静岡県",
            "city":"函南町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｶﾝﾅﾐﾁｮｳ"
        },
        "223417":{
            "cityId":223417,
            "pref":"静岡県",
            "city":"清水町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｼﾐｽﾞﾁｮｳ"
        },
        "223425":{
            "cityId":223425,
            "pref":"静岡県",
            "city":"長泉町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾅｶﾞｲｽﾞﾐﾁｮｳ"
        },
        "223441":{
            "cityId":223441,
            "pref":"静岡県",
            "city":"小山町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｵﾔﾏﾁｮｳ"
        },
        "224243":{
            "cityId":224243,
            "pref":"静岡県",
            "city":"吉田町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾖｼﾀﾞﾁｮｳ"
        },
        "224294":{
            "cityId":224294,
            "pref":"静岡県",
            "city":"川根本町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ｶﾜﾈﾎﾝﾁｮｳ"
        },
        "224618":{
            "cityId":224618,
            "pref":"静岡県",
            "city":"森町",
            "prefKana":"ｼｽﾞｵｶｹﾝ",
            "cityKana":"ﾓﾘﾏﾁ"
        }
    },
    "愛知":{
        "230006":{
            "cityId":230006,
            "pref":"愛知県",
            "city":"",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":""
        },
        "231002":{
            "cityId":231002,
            "pref":"愛知県",
            "city":"名古屋市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾅｺﾞﾔｼ"
        },
        "232017":{
            "cityId":232017,
            "pref":"愛知県",
            "city":"豊橋市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖﾊｼｼ"
        },
        "232025":{
            "cityId":232025,
            "pref":"愛知県",
            "city":"岡崎市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｵｶｻﾞｷｼ"
        },
        "232033":{
            "cityId":232033,
            "pref":"愛知県",
            "city":"一宮市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｲﾁﾉﾐﾔｼ"
        },
        "232041":{
            "cityId":232041,
            "pref":"愛知県",
            "city":"瀬戸市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｾﾄｼ"
        },
        "232050":{
            "cityId":232050,
            "pref":"愛知県",
            "city":"半田市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾊﾝﾀﾞｼ"
        },
        "232068":{
            "cityId":232068,
            "pref":"愛知県",
            "city":"春日井市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｶｽｶﾞｲｼ"
        },
        "232076":{
            "cityId":232076,
            "pref":"愛知県",
            "city":"豊川市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖｶﾜｼ"
        },
        "232084":{
            "cityId":232084,
            "pref":"愛知県",
            "city":"津島市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾂｼﾏｼ"
        },
        "232092":{
            "cityId":232092,
            "pref":"愛知県",
            "city":"碧南市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾍｷﾅﾝｼ"
        },
        "232106":{
            "cityId":232106,
            "pref":"愛知県",
            "city":"刈谷市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｶﾘﾔｼ"
        },
        "232114":{
            "cityId":232114,
            "pref":"愛知県",
            "city":"豊田市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖﾀｼ"
        },
        "232122":{
            "cityId":232122,
            "pref":"愛知県",
            "city":"安城市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｱﾝｼﾞｮｳｼ"
        },
        "232131":{
            "cityId":232131,
            "pref":"愛知県",
            "city":"西尾市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾆｼｵｼ"
        },
        "232149":{
            "cityId":232149,
            "pref":"愛知県",
            "city":"蒲郡市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｶﾞﾏｺﾞｵﾘｼ"
        },
        "232157":{
            "cityId":232157,
            "pref":"愛知県",
            "city":"犬山市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｲﾇﾔﾏｼ"
        },
        "232165":{
            "cityId":232165,
            "pref":"愛知県",
            "city":"常滑市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄｺﾅﾒｼ"
        },
        "232173":{
            "cityId":232173,
            "pref":"愛知県",
            "city":"江南市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｺｳﾅﾝｼ"
        },
        "232190":{
            "cityId":232190,
            "pref":"愛知県",
            "city":"小牧市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｺﾏｷｼ"
        },
        "232203":{
            "cityId":232203,
            "pref":"愛知県",
            "city":"稲沢市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｲﾅｻﾞﾜｼ"
        },
        "232211":{
            "cityId":232211,
            "pref":"愛知県",
            "city":"新城市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｼﾝｼﾛｼ"
        },
        "232220":{
            "cityId":232220,
            "pref":"愛知県",
            "city":"東海市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄｳｶｲｼ"
        },
        "232238":{
            "cityId":232238,
            "pref":"愛知県",
            "city":"大府市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｵｵﾌﾞｼ"
        },
        "232246":{
            "cityId":232246,
            "pref":"愛知県",
            "city":"知多市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾁﾀｼ"
        },
        "232254":{
            "cityId":232254,
            "pref":"愛知県",
            "city":"知立市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾁﾘｭｳｼ"
        },
        "232262":{
            "cityId":232262,
            "pref":"愛知県",
            "city":"尾張旭市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｵﾜﾘｱｻﾋｼ"
        },
        "232271":{
            "cityId":232271,
            "pref":"愛知県",
            "city":"高浜市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾀｶﾊﾏｼ"
        },
        "232289":{
            "cityId":232289,
            "pref":"愛知県",
            "city":"岩倉市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｲﾜｸﾗｼ"
        },
        "232297":{
            "cityId":232297,
            "pref":"愛知県",
            "city":"豊明市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖｱｹｼ"
        },
        "232301":{
            "cityId":232301,
            "pref":"愛知県",
            "city":"日進市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾆｯｼﾝｼ"
        },
        "232319":{
            "cityId":232319,
            "pref":"愛知県",
            "city":"田原市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾀﾊﾗｼ"
        },
        "232327":{
            "cityId":232327,
            "pref":"愛知県",
            "city":"愛西市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｱｲｻｲｼ"
        },
        "232335":{
            "cityId":232335,
            "pref":"愛知県",
            "city":"清須市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｷﾖｽｼ"
        },
        "232343":{
            "cityId":232343,
            "pref":"愛知県",
            "city":"北名古屋市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｷﾀﾅｺﾞﾔｼ"
        },
        "232351":{
            "cityId":232351,
            "pref":"愛知県",
            "city":"弥富市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾔﾄﾐｼ"
        },
        "232360":{
            "cityId":232360,
            "pref":"愛知県",
            "city":"みよし市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾐﾖｼｼ"
        },
        "232378":{
            "cityId":232378,
            "pref":"愛知県",
            "city":"あま市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｱﾏｼ"
        },
        "232386":{
            "cityId":232386,
            "pref":"愛知県",
            "city":"長久手市",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾅｶﾞｸﾃｼ"
        },
        "233021":{
            "cityId":233021,
            "pref":"愛知県",
            "city":"東郷町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄｳｺﾞｳﾁｮｳ"
        },
        "233421":{
            "cityId":233421,
            "pref":"愛知県",
            "city":"豊山町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖﾔﾏﾁｮｳ"
        },
        "233617":{
            "cityId":233617,
            "pref":"愛知県",
            "city":"大口町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｵｵｸﾞﾁﾁｮｳ"
        },
        "233625":{
            "cityId":233625,
            "pref":"愛知県",
            "city":"扶桑町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾌｿｳﾁｮｳ"
        },
        "234249":{
            "cityId":234249,
            "pref":"愛知県",
            "city":"大治町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｵｵﾊﾙﾁｮｳ"
        },
        "234257":{
            "cityId":234257,
            "pref":"愛知県",
            "city":"蟹江町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｶﾆｴﾁｮｳ"
        },
        "234273":{
            "cityId":234273,
            "pref":"愛知県",
            "city":"飛島村",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾋﾞｼﾏﾑﾗ"
        },
        "234419":{
            "cityId":234419,
            "pref":"愛知県",
            "city":"阿久比町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｱｸﾞｲﾁｮｳ"
        },
        "234427":{
            "cityId":234427,
            "pref":"愛知県",
            "city":"東浦町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾋｶﾞｼｳﾗﾁｮｳ"
        },
        "234451":{
            "cityId":234451,
            "pref":"愛知県",
            "city":"南知多町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾐﾅﾐﾁﾀﾁｮｳ"
        },
        "234460":{
            "cityId":234460,
            "pref":"愛知県",
            "city":"美浜町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾐﾊﾏﾁｮｳ"
        },
        "234478":{
            "cityId":234478,
            "pref":"愛知県",
            "city":"武豊町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾀｹﾄﾖﾁｮｳ"
        },
        "235016":{
            "cityId":235016,
            "pref":"愛知県",
            "city":"幸田町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｺｳﾀﾁｮｳ"
        },
        "235610":{
            "cityId":235610,
            "pref":"愛知県",
            "city":"設楽町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ｼﾀﾗﾁｮｳ"
        },
        "235628":{
            "cityId":235628,
            "pref":"愛知県",
            "city":"東栄町",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄｳｴｲﾁｮｳ"
        },
        "235636":{
            "cityId":235636,
            "pref":"愛知県",
            "city":"豊根村",
            "prefKana":"ｱｲﾁｹﾝ",
            "cityKana":"ﾄﾖﾈﾑﾗ"
        }
    },
    "三重":{
        "240001":{
            "cityId":240001,
            "pref":"三重県",
            "city":"",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":""
        },
        "242012":{
            "cityId":242012,
            "pref":"三重県",
            "city":"津市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾂｼ"
        },
        "242021":{
            "cityId":242021,
            "pref":"三重県",
            "city":"四日市市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾖｯｶｲﾁｼ"
        },
        "242039":{
            "cityId":242039,
            "pref":"三重県",
            "city":"伊勢市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｲｾｼ"
        },
        "242047":{
            "cityId":242047,
            "pref":"三重県",
            "city":"松阪市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾏﾂｻｶｼ"
        },
        "242055":{
            "cityId":242055,
            "pref":"三重県",
            "city":"桑名市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｸﾜﾅｼ"
        },
        "242071":{
            "cityId":242071,
            "pref":"三重県",
            "city":"鈴鹿市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｽｽﾞｶｼ"
        },
        "242080":{
            "cityId":242080,
            "pref":"三重県",
            "city":"名張市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾅﾊﾞﾘｼ"
        },
        "242098":{
            "cityId":242098,
            "pref":"三重県",
            "city":"尾鷲市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｵﾜｾｼ"
        },
        "242101":{
            "cityId":242101,
            "pref":"三重県",
            "city":"亀山市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｶﾒﾔﾏｼ"
        },
        "242110":{
            "cityId":242110,
            "pref":"三重県",
            "city":"鳥羽市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾄﾊﾞｼ"
        },
        "242128":{
            "cityId":242128,
            "pref":"三重県",
            "city":"熊野市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｸﾏﾉｼ"
        },
        "242144":{
            "cityId":242144,
            "pref":"三重県",
            "city":"いなべ市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｲﾅﾍﾞｼ"
        },
        "242152":{
            "cityId":242152,
            "pref":"三重県",
            "city":"志摩市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｼﾏｼ"
        },
        "242161":{
            "cityId":242161,
            "pref":"三重県",
            "city":"伊賀市",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｲｶﾞｼ"
        },
        "243035":{
            "cityId":243035,
            "pref":"三重県",
            "city":"木曽岬町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｷｿｻｷﾁｮｳ"
        },
        "243248":{
            "cityId":243248,
            "pref":"三重県",
            "city":"東員町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾄｳｲﾝﾁｮｳ"
        },
        "243418":{
            "cityId":243418,
            "pref":"三重県",
            "city":"菰野町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｺﾓﾉﾁｮｳ"
        },
        "243434":{
            "cityId":243434,
            "pref":"三重県",
            "city":"朝日町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｱｻﾋﾁｮｳ"
        },
        "243442":{
            "cityId":243442,
            "pref":"三重県",
            "city":"川越町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｶﾜｺﾞｴﾁｮｳ"
        },
        "244414":{
            "cityId":244414,
            "pref":"三重県",
            "city":"多気町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾀｷﾁｮｳ"
        },
        "244422":{
            "cityId":244422,
            "pref":"三重県",
            "city":"明和町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾒｲﾜﾁｮｳ"
        },
        "244431":{
            "cityId":244431,
            "pref":"三重県",
            "city":"大台町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｵｵﾀﾞｲﾁｮｳ"
        },
        "244619":{
            "cityId":244619,
            "pref":"三重県",
            "city":"玉城町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾀﾏｷﾁｮｳ"
        },
        "244708":{
            "cityId":244708,
            "pref":"三重県",
            "city":"度会町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾜﾀﾗｲﾁｮｳ"
        },
        "244716":{
            "cityId":244716,
            "pref":"三重県",
            "city":"大紀町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾀｲｷﾁｮｳ"
        },
        "244724":{
            "cityId":244724,
            "pref":"三重県",
            "city":"南伊勢町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾐﾅﾐｲｾﾁｮｳ"
        },
        "245437":{
            "cityId":245437,
            "pref":"三重県",
            "city":"紀北町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｷﾎｸﾁｮｳ"
        },
        "245615":{
            "cityId":245615,
            "pref":"三重県",
            "city":"御浜町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ﾐﾊﾏﾁｮｳ"
        },
        "245623":{
            "cityId":245623,
            "pref":"三重県",
            "city":"紀宝町",
            "prefKana":"ﾐｴｹﾝ",
            "cityKana":"ｷﾎｳﾁｮｳ"
        }
    },
    "滋賀":{
        "250007":{
            "cityId":250007,
            "pref":"滋賀県",
            "city":"",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":""
        },
        "252018":{
            "cityId":252018,
            "pref":"滋賀県",
            "city":"大津市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｵｵﾂｼ"
        },
        "252026":{
            "cityId":252026,
            "pref":"滋賀県",
            "city":"彦根市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾋｺﾈｼ"
        },
        "252034":{
            "cityId":252034,
            "pref":"滋賀県",
            "city":"長浜市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾅｶﾞﾊﾏｼ"
        },
        "252042":{
            "cityId":252042,
            "pref":"滋賀県",
            "city":"近江八幡市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｵｳﾐﾊﾁﾏﾝｼ"
        },
        "252069":{
            "cityId":252069,
            "pref":"滋賀県",
            "city":"草津市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｸｻﾂｼ"
        },
        "252077":{
            "cityId":252077,
            "pref":"滋賀県",
            "city":"守山市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾓﾘﾔﾏｼ"
        },
        "252085":{
            "cityId":252085,
            "pref":"滋賀県",
            "city":"栗東市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾘｯﾄｳｼ"
        },
        "252093":{
            "cityId":252093,
            "pref":"滋賀県",
            "city":"甲賀市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｺｳｶｼ"
        },
        "252107":{
            "cityId":252107,
            "pref":"滋賀県",
            "city":"野洲市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾔｽｼ"
        },
        "252115":{
            "cityId":252115,
            "pref":"滋賀県",
            "city":"湖南市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｺﾅﾝｼ"
        },
        "252123":{
            "cityId":252123,
            "pref":"滋賀県",
            "city":"高島市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾀｶｼﾏｼ"
        },
        "252131":{
            "cityId":252131,
            "pref":"滋賀県",
            "city":"東近江市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾋｶﾞｼｵｳﾐｼ"
        },
        "252140":{
            "cityId":252140,
            "pref":"滋賀県",
            "city":"米原市",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾏｲﾊﾞﾗｼ"
        },
        "253839":{
            "cityId":253839,
            "pref":"滋賀県",
            "city":"日野町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾋﾉﾁｮｳ"
        },
        "253847":{
            "cityId":253847,
            "pref":"滋賀県",
            "city":"竜王町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾘﾕｳｵｳﾁｮｳ"
        },
        "254258":{
            "cityId":254258,
            "pref":"滋賀県",
            "city":"愛荘町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｱｲｼｮｳﾁｮｳ"
        },
        "254410":{
            "cityId":254410,
            "pref":"滋賀県",
            "city":"豊郷町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾄﾖｻﾄﾁｮｳ"
        },
        "254428":{
            "cityId":254428,
            "pref":"滋賀県",
            "city":"甲良町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ｺｳﾗﾁｮｳ"
        },
        "254436":{
            "cityId":254436,
            "pref":"滋賀県",
            "city":"多賀町",
            "prefKana":"ｼｶﾞｹﾝ",
            "cityKana":"ﾀｶﾞﾁｮｳ"
        }
    },
    "京都":{
        "260002":{
            "cityId":260002,
            "pref":"京都府",
            "city":"",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":""
        },
        "261009":{
            "cityId":261009,
            "pref":"京都府",
            "city":"京都市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｷｮｳﾄｼ"
        },
        "262013":{
            "cityId":262013,
            "pref":"京都府",
            "city":"福知山市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾌｸﾁﾔﾏｼ"
        },
        "262021":{
            "cityId":262021,
            "pref":"京都府",
            "city":"舞鶴市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾏｲﾂﾞﾙｼ"
        },
        "262030":{
            "cityId":262030,
            "pref":"京都府",
            "city":"綾部市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｱﾔﾍﾞｼ"
        },
        "262048":{
            "cityId":262048,
            "pref":"京都府",
            "city":"宇治市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｳｼﾞｼ"
        },
        "262056":{
            "cityId":262056,
            "pref":"京都府",
            "city":"宮津市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾐﾔﾂﾞｼ"
        },
        "262064":{
            "cityId":262064,
            "pref":"京都府",
            "city":"亀岡市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｶﾒｵｶｼ"
        },
        "262072":{
            "cityId":262072,
            "pref":"京都府",
            "city":"城陽市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｼﾞｮｳﾖｳｼ"
        },
        "262081":{
            "cityId":262081,
            "pref":"京都府",
            "city":"向日市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾑｺｳｼ"
        },
        "262099":{
            "cityId":262099,
            "pref":"京都府",
            "city":"長岡京市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾅｶﾞｵｶｷｮｳｼ"
        },
        "262102":{
            "cityId":262102,
            "pref":"京都府",
            "city":"八幡市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾔﾜﾀｼ"
        },
        "262111":{
            "cityId":262111,
            "pref":"京都府",
            "city":"京田辺市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｷｮｳﾀﾅﾍﾞｼ"
        },
        "262129":{
            "cityId":262129,
            "pref":"京都府",
            "city":"京丹後市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｷｮｳﾀﾝｺﾞｼ"
        },
        "262137":{
            "cityId":262137,
            "pref":"京都府",
            "city":"南丹市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾅﾝﾀﾝｼ"
        },
        "262145":{
            "cityId":262145,
            "pref":"京都府",
            "city":"木津川市",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｷﾂﾞｶﾞﾜｼ"
        },
        "263036":{
            "cityId":263036,
            "pref":"京都府",
            "city":"大山崎町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｵｵﾔﾏｻﾞｷﾁｮｳ"
        },
        "263222":{
            "cityId":263222,
            "pref":"京都府",
            "city":"久御山町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｸﾐﾔﾏﾁｮｳ"
        },
        "263435":{
            "cityId":263435,
            "pref":"京都府",
            "city":"井手町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｲﾃﾞﾁｮｳ"
        },
        "263443":{
            "cityId":263443,
            "pref":"京都府",
            "city":"宇治田原町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｳｼﾞﾀﾜﾗﾁｮｳ"
        },
        "263648":{
            "cityId":263648,
            "pref":"京都府",
            "city":"笠置町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｶｻｷﾞﾁｮｳ"
        },
        "263656":{
            "cityId":263656,
            "pref":"京都府",
            "city":"和束町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾜﾂﾞｶﾁｮｳ"
        },
        "263664":{
            "cityId":263664,
            "pref":"京都府",
            "city":"精華町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｾｲｶﾁｮｳ"
        },
        "263672":{
            "cityId":263672,
            "pref":"京都府",
            "city":"南山城村",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾐﾅﾐﾔﾏｼﾛﾑﾗ"
        },
        "264075":{
            "cityId":264075,
            "pref":"京都府",
            "city":"京丹波町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｷｮｳﾀﾝﾊﾞﾁｮｳ"
        },
        "264636":{
            "cityId":264636,
            "pref":"京都府",
            "city":"伊根町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ｲﾈﾁｮｳ"
        },
        "264652":{
            "cityId":264652,
            "pref":"京都府",
            "city":"与謝野町",
            "prefKana":"ｷｮｳﾄﾌ",
            "cityKana":"ﾖｻﾉﾁｮｳ"
        }
    },
    "大阪":{
        "270008":{
            "cityId":270008,
            "pref":"大阪府",
            "city":"",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":""
        },
        "271004":{
            "cityId":271004,
            "pref":"大阪府",
            "city":"大阪市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｵｵｻｶｼ"
        },
        "271403":{
            "cityId":271403,
            "pref":"大阪府",
            "city":"堺市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｻｶｲｼ"
        },
        "272027":{
            "cityId":272027,
            "pref":"大阪府",
            "city":"岸和田市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｷｼﾜﾀﾞｼ"
        },
        "272035":{
            "cityId":272035,
            "pref":"大阪府",
            "city":"豊中市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾄﾖﾅｶｼ"
        },
        "272043":{
            "cityId":272043,
            "pref":"大阪府",
            "city":"池田市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｲｹﾀﾞｼ"
        },
        "272051":{
            "cityId":272051,
            "pref":"大阪府",
            "city":"吹田市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｽｲﾀｼ"
        },
        "272060":{
            "cityId":272060,
            "pref":"大阪府",
            "city":"泉大津市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｲｽﾞﾐｵｵﾂｼ"
        },
        "272078":{
            "cityId":272078,
            "pref":"大阪府",
            "city":"高槻市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀｶﾂｷｼ"
        },
        "272086":{
            "cityId":272086,
            "pref":"大阪府",
            "city":"貝塚市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶｲﾂﾞｶｼ"
        },
        "272094":{
            "cityId":272094,
            "pref":"大阪府",
            "city":"守口市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾓﾘｸﾞﾁｼ"
        },
        "272108":{
            "cityId":272108,
            "pref":"大阪府",
            "city":"枚方市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾋﾗｶﾀｼ"
        },
        "272116":{
            "cityId":272116,
            "pref":"大阪府",
            "city":"茨木市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｲﾊﾞﾗｷｼ"
        },
        "272124":{
            "cityId":272124,
            "pref":"大阪府",
            "city":"八尾市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾔｵｼ"
        },
        "272132":{
            "cityId":272132,
            "pref":"大阪府",
            "city":"泉佐野市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｲｽﾞﾐｻﾉｼ"
        },
        "272141":{
            "cityId":272141,
            "pref":"大阪府",
            "city":"富田林市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾄﾝﾀﾞﾊﾞﾔｼｼ"
        },
        "272159":{
            "cityId":272159,
            "pref":"大阪府",
            "city":"寝屋川市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾈﾔｶﾞﾜｼ"
        },
        "272167":{
            "cityId":272167,
            "pref":"大阪府",
            "city":"河内長野市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶﾜﾁﾅｶﾞﾉｼ"
        },
        "272175":{
            "cityId":272175,
            "pref":"大阪府",
            "city":"松原市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾏﾂﾊﾞﾗｼ"
        },
        "272183":{
            "cityId":272183,
            "pref":"大阪府",
            "city":"大東市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀﾞｲﾄｳｼ"
        },
        "272191":{
            "cityId":272191,
            "pref":"大阪府",
            "city":"和泉市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｲｽﾞﾐｼ"
        },
        "272205":{
            "cityId":272205,
            "pref":"大阪府",
            "city":"箕面市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾐﾉｵｼ"
        },
        "272213":{
            "cityId":272213,
            "pref":"大阪府",
            "city":"柏原市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶｼﾜﾗｼ"
        },
        "272221":{
            "cityId":272221,
            "pref":"大阪府",
            "city":"羽曳野市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾊﾋﾞｷﾉｼ"
        },
        "272230":{
            "cityId":272230,
            "pref":"大阪府",
            "city":"門真市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶﾄﾞﾏｼ"
        },
        "272248":{
            "cityId":272248,
            "pref":"大阪府",
            "city":"摂津市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｾｯﾂｼ"
        },
        "272256":{
            "cityId":272256,
            "pref":"大阪府",
            "city":"高石市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀｶｲｼｼ"
        },
        "272264":{
            "cityId":272264,
            "pref":"大阪府",
            "city":"藤井寺市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾌｼﾞｲﾃﾞﾗｼ"
        },
        "272272":{
            "cityId":272272,
            "pref":"大阪府",
            "city":"東大阪市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾋｶﾞｼｵｵｻｶｼ"
        },
        "272281":{
            "cityId":272281,
            "pref":"大阪府",
            "city":"泉南市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｾﾝﾅﾝｼ"
        },
        "272299":{
            "cityId":272299,
            "pref":"大阪府",
            "city":"四條畷市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｼｼﾞﾖｳﾅﾜﾃｼ"
        },
        "272302":{
            "cityId":272302,
            "pref":"大阪府",
            "city":"交野市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶﾀﾉｼ"
        },
        "272311":{
            "cityId":272311,
            "pref":"大阪府",
            "city":"大阪狭山市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｵｵｻｶｻﾔﾏｼ"
        },
        "272329":{
            "cityId":272329,
            "pref":"大阪府",
            "city":"阪南市",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾊﾝﾅﾝｼ"
        },
        "273015":{
            "cityId":273015,
            "pref":"大阪府",
            "city":"島本町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｼﾏﾓﾄﾁｮｳ"
        },
        "273210":{
            "cityId":273210,
            "pref":"大阪府",
            "city":"豊能町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾄﾖﾉﾁｮｳ"
        },
        "273228":{
            "cityId":273228,
            "pref":"大阪府",
            "city":"能勢町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾉｾﾁｮｳ"
        },
        "273414":{
            "cityId":273414,
            "pref":"大阪府",
            "city":"忠岡町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀﾀﾞｵｶﾁｮｳ"
        },
        "273619":{
            "cityId":273619,
            "pref":"大阪府",
            "city":"熊取町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｸﾏﾄﾘﾁｮｳ"
        },
        "273627":{
            "cityId":273627,
            "pref":"大阪府",
            "city":"田尻町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀｼﾞﾘﾁｮｳ"
        },
        "273660":{
            "cityId":273660,
            "pref":"大阪府",
            "city":"岬町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾐｻｷﾁｮｳ"
        },
        "273813":{
            "cityId":273813,
            "pref":"大阪府",
            "city":"太子町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾀｲｼﾁｮｳ"
        },
        "273821":{
            "cityId":273821,
            "pref":"大阪府",
            "city":"河南町",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ｶﾅﾝﾁｮｳ"
        },
        "273830":{
            "cityId":273830,
            "pref":"大阪府",
            "city":"千早赤阪村",
            "prefKana":"ｵｵｻｶﾌ",
            "cityKana":"ﾁﾊﾔｱｶｻｶﾑﾗ"
        }
    },
    "兵庫":{
        "280003":{
            "cityId":280003,
            "pref":"兵庫県",
            "city":"",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":""
        },
        "281000":{
            "cityId":281000,
            "pref":"兵庫県",
            "city":"神戸市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｺｳﾍﾞｼ"
        },
        "282014":{
            "cityId":282014,
            "pref":"兵庫県",
            "city":"姫路市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾋﾒｼﾞｼ"
        },
        "282022":{
            "cityId":282022,
            "pref":"兵庫県",
            "city":"尼崎市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱﾏｶﾞｻｷｼ"
        },
        "282031":{
            "cityId":282031,
            "pref":"兵庫県",
            "city":"明石市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱｶｼｼ"
        },
        "282049":{
            "cityId":282049,
            "pref":"兵庫県",
            "city":"西宮市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾆｼﾉﾐﾔｼ"
        },
        "282057":{
            "cityId":282057,
            "pref":"兵庫県",
            "city":"洲本市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｽﾓﾄｼ"
        },
        "282065":{
            "cityId":282065,
            "pref":"兵庫県",
            "city":"芦屋市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱｼﾔｼ"
        },
        "282073":{
            "cityId":282073,
            "pref":"兵庫県",
            "city":"伊丹市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｲﾀﾐｼ"
        },
        "282081":{
            "cityId":282081,
            "pref":"兵庫県",
            "city":"相生市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱｲｵｲｼ"
        },
        "282090":{
            "cityId":282090,
            "pref":"兵庫県",
            "city":"豊岡市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾄﾖｵｶｼ"
        },
        "282103":{
            "cityId":282103,
            "pref":"兵庫県",
            "city":"加古川市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶｺｶﾞﾜｼ"
        },
        "282120":{
            "cityId":282120,
            "pref":"兵庫県",
            "city":"赤穂市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱｺｳｼ"
        },
        "282138":{
            "cityId":282138,
            "pref":"兵庫県",
            "city":"西脇市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾆｼﾜｷｼ"
        },
        "282146":{
            "cityId":282146,
            "pref":"兵庫県",
            "city":"宝塚市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀｶﾗﾂﾞｶｼ"
        },
        "282154":{
            "cityId":282154,
            "pref":"兵庫県",
            "city":"三木市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾐｷｼ"
        },
        "282162":{
            "cityId":282162,
            "pref":"兵庫県",
            "city":"高砂市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀｶｻｺﾞｼ"
        },
        "282171":{
            "cityId":282171,
            "pref":"兵庫県",
            "city":"川西市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶﾜﾆｼｼ"
        },
        "282189":{
            "cityId":282189,
            "pref":"兵庫県",
            "city":"小野市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｵﾉｼ"
        },
        "282197":{
            "cityId":282197,
            "pref":"兵庫県",
            "city":"三田市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｻﾝﾀﾞｼ"
        },
        "282201":{
            "cityId":282201,
            "pref":"兵庫県",
            "city":"加西市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶｻｲｼ"
        },
        "282219":{
            "cityId":282219,
            "pref":"兵庫県",
            "city":"丹波篠山市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀﾝﾊﾞｻｻﾔﾏｼ"
        },
        "282227":{
            "cityId":282227,
            "pref":"兵庫県",
            "city":"養父市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾔﾌﾞｼ"
        },
        "282235":{
            "cityId":282235,
            "pref":"兵庫県",
            "city":"丹波市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀﾝﾊﾞｼ"
        },
        "282243":{
            "cityId":282243,
            "pref":"兵庫県",
            "city":"南あわじ市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾐﾅﾐｱﾜｼﾞｼ"
        },
        "282251":{
            "cityId":282251,
            "pref":"兵庫県",
            "city":"朝来市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱｻｺﾞｼ"
        },
        "282260":{
            "cityId":282260,
            "pref":"兵庫県",
            "city":"淡路市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｱﾜｼﾞｼ"
        },
        "282278":{
            "cityId":282278,
            "pref":"兵庫県",
            "city":"宍粟市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｼｿｳｼ"
        },
        "282286":{
            "cityId":282286,
            "pref":"兵庫県",
            "city":"加東市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶﾄｳｼ"
        },
        "282294":{
            "cityId":282294,
            "pref":"兵庫県",
            "city":"たつの市",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀﾂﾉｼ"
        },
        "283011":{
            "cityId":283011,
            "pref":"兵庫県",
            "city":"猪名川町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｲﾅｶﾞﾜﾁｮｳ"
        },
        "283657":{
            "cityId":283657,
            "pref":"兵庫県",
            "city":"多可町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀｶﾁｮｳ"
        },
        "283819":{
            "cityId":283819,
            "pref":"兵庫県",
            "city":"稲美町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｲﾅﾐﾁｮｳ"
        },
        "283827":{
            "cityId":283827,
            "pref":"兵庫県",
            "city":"播磨町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾊﾘﾏﾁｮｳ"
        },
        "284424":{
            "cityId":284424,
            "pref":"兵庫県",
            "city":"市川町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｲﾁｶﾜﾁｮｳ"
        },
        "284432":{
            "cityId":284432,
            "pref":"兵庫県",
            "city":"福崎町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾌｸｻｷﾁｮｳ"
        },
        "284467":{
            "cityId":284467,
            "pref":"兵庫県",
            "city":"神河町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶﾐｶﾜﾁｮｳ"
        },
        "284645":{
            "cityId":284645,
            "pref":"兵庫県",
            "city":"太子町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ﾀｲｼﾁｮｳ"
        },
        "284815":{
            "cityId":284815,
            "pref":"兵庫県",
            "city":"上郡町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶﾐｺﾞｵﾘﾁｮｳ"
        },
        "285013":{
            "cityId":285013,
            "pref":"兵庫県",
            "city":"佐用町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｻﾖｳﾁｮｳ"
        },
        "285854":{
            "cityId":285854,
            "pref":"兵庫県",
            "city":"香美町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｶﾐﾁｮｳ"
        },
        "285862":{
            "cityId":285862,
            "pref":"兵庫県",
            "city":"新温泉町",
            "prefKana":"ﾋｮｳｺﾞｹﾝ",
            "cityKana":"ｼﾝｵﾝｾﾝﾁｮｳ"
        }
    },
    "奈良":{
        "290009":{
            "cityId":290009,
            "pref":"奈良県",
            "city":"",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":""
        },
        "292010":{
            "cityId":292010,
            "pref":"奈良県",
            "city":"奈良市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾅﾗｼ"
        },
        "292028":{
            "cityId":292028,
            "pref":"奈良県",
            "city":"大和高田市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾔﾏﾄﾀｶﾀﾞｼ"
        },
        "292036":{
            "cityId":292036,
            "pref":"奈良県",
            "city":"大和郡山市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾔﾏﾄｺｵﾘﾔﾏｼ"
        },
        "292044":{
            "cityId":292044,
            "pref":"奈良県",
            "city":"天理市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾃﾝﾘｼ"
        },
        "292052":{
            "cityId":292052,
            "pref":"奈良県",
            "city":"橿原市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶｼﾊﾗｼ"
        },
        "292061":{
            "cityId":292061,
            "pref":"奈良県",
            "city":"桜井市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｻｸﾗｲｼ"
        },
        "292079":{
            "cityId":292079,
            "pref":"奈良県",
            "city":"五條市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｺﾞｼﾞｮｳｼ"
        },
        "292087":{
            "cityId":292087,
            "pref":"奈良県",
            "city":"御所市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｺﾞｾｼ"
        },
        "292095":{
            "cityId":292095,
            "pref":"奈良県",
            "city":"生駒市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｲｺﾏｼ"
        },
        "292109":{
            "cityId":292109,
            "pref":"奈良県",
            "city":"香芝市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶｼﾊﾞｼ"
        },
        "292117":{
            "cityId":292117,
            "pref":"奈良県",
            "city":"葛城市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾂﾗｷﾞｼ"
        },
        "292125":{
            "cityId":292125,
            "pref":"奈良県",
            "city":"宇陀市",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｳﾀﾞｼ"
        },
        "293229":{
            "cityId":293229,
            "pref":"奈良県",
            "city":"山添村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾔﾏｿﾞｴﾑﾗ"
        },
        "293423":{
            "cityId":293423,
            "pref":"奈良県",
            "city":"平群町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾍｸﾞﾘﾁｮｳ"
        },
        "293431":{
            "cityId":293431,
            "pref":"奈良県",
            "city":"三郷町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｻﾝｺﾞｳﾁｮｳ"
        },
        "293440":{
            "cityId":293440,
            "pref":"奈良県",
            "city":"斑鳩町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｲｶﾙｶﾞﾁｮｳ"
        },
        "293458":{
            "cityId":293458,
            "pref":"奈良県",
            "city":"安堵町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｱﾝﾄﾞﾁｮｳ"
        },
        "293610":{
            "cityId":293610,
            "pref":"奈良県",
            "city":"川西町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾜﾆｼﾁｮｳ"
        },
        "293628":{
            "cityId":293628,
            "pref":"奈良県",
            "city":"三宅町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾐﾔｹﾁｮｳ"
        },
        "293636":{
            "cityId":293636,
            "pref":"奈良県",
            "city":"田原本町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾀﾜﾗﾓﾄﾁｮｳ"
        },
        "293857":{
            "cityId":293857,
            "pref":"奈良県",
            "city":"曽爾村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｿﾆﾑﾗ"
        },
        "293865":{
            "cityId":293865,
            "pref":"奈良県",
            "city":"御杖村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾐﾂｴﾑﾗ"
        },
        "294012":{
            "cityId":294012,
            "pref":"奈良県",
            "city":"高取町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾀｶﾄﾘﾁｮｳ"
        },
        "294021":{
            "cityId":294021,
            "pref":"奈良県",
            "city":"明日香村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｱｽｶﾑﾗ"
        },
        "294241":{
            "cityId":294241,
            "pref":"奈良県",
            "city":"上牧町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾝﾏｷﾁｮｳ"
        },
        "294250":{
            "cityId":294250,
            "pref":"奈良県",
            "city":"王寺町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｵｳｼﾞﾁｮｳ"
        },
        "294268":{
            "cityId":294268,
            "pref":"奈良県",
            "city":"広陵町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｺｳﾘﾖｳﾁｮｳ"
        },
        "294276":{
            "cityId":294276,
            "pref":"奈良県",
            "city":"河合町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾜｲﾁｮｳ"
        },
        "294411":{
            "cityId":294411,
            "pref":"奈良県",
            "city":"吉野町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾖｼﾉﾁｮｳ"
        },
        "294420":{
            "cityId":294420,
            "pref":"奈良県",
            "city":"大淀町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｵｵﾖﾄﾞﾁｮｳ"
        },
        "294438":{
            "cityId":294438,
            "pref":"奈良県",
            "city":"下市町",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｼﾓｲﾁﾁｮｳ"
        },
        "294446":{
            "cityId":294446,
            "pref":"奈良県",
            "city":"黒滝村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｸﾛﾀｷﾑﾗ"
        },
        "294462":{
            "cityId":294462,
            "pref":"奈良県",
            "city":"天川村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾃﾝｶﾜﾑﾗ"
        },
        "294471":{
            "cityId":294471,
            "pref":"奈良県",
            "city":"野迫川村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾉｾｶﾞﾜﾑﾗ"
        },
        "294497":{
            "cityId":294497,
            "pref":"奈良県",
            "city":"十津川村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾄﾂｶﾜﾑﾗ"
        },
        "294501":{
            "cityId":294501,
            "pref":"奈良県",
            "city":"下北山村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｼﾓｷﾀﾔﾏﾑﾗ"
        },
        "294519":{
            "cityId":294519,
            "pref":"奈良県",
            "city":"上北山村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾐｷﾀﾔﾏﾑﾗ"
        },
        "294527":{
            "cityId":294527,
            "pref":"奈良県",
            "city":"川上村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ｶﾜｶﾐﾑﾗ"
        },
        "294535":{
            "cityId":294535,
            "pref":"奈良県",
            "city":"東吉野村",
            "prefKana":"ﾅﾗｹﾝ",
            "cityKana":"ﾋｶﾞｼﾖｼﾉﾑﾗ"
        }
    },
    "和歌山":{
        "300004":{
            "cityId":300004,
            "pref":"和歌山県",
            "city":"",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":""
        },
        "302015":{
            "cityId":302015,
            "pref":"和歌山県",
            "city":"和歌山市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾜｶﾔﾏｼ"
        },
        "302023":{
            "cityId":302023,
            "pref":"和歌山県",
            "city":"海南市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｶｲﾅﾝｼ"
        },
        "302031":{
            "cityId":302031,
            "pref":"和歌山県",
            "city":"橋本市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾊｼﾓﾄｼ"
        },
        "302040":{
            "cityId":302040,
            "pref":"和歌山県",
            "city":"有田市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｱﾘﾀﾞｼ"
        },
        "302058":{
            "cityId":302058,
            "pref":"和歌山県",
            "city":"御坊市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｺﾞﾎﾞｳｼ"
        },
        "302066":{
            "cityId":302066,
            "pref":"和歌山県",
            "city":"田辺市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾀﾅﾍﾞｼ"
        },
        "302074":{
            "cityId":302074,
            "pref":"和歌山県",
            "city":"新宮市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｼﾝｸﾞｳｼ"
        },
        "302082":{
            "cityId":302082,
            "pref":"和歌山県",
            "city":"紀の川市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｷﾉｶﾜｼ"
        },
        "302091":{
            "cityId":302091,
            "pref":"和歌山県",
            "city":"岩出市",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｲﾜﾃﾞｼ"
        },
        "303046":{
            "cityId":303046,
            "pref":"和歌山県",
            "city":"紀美野町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｷﾐﾉﾁｮｳ"
        },
        "303411":{
            "cityId":303411,
            "pref":"和歌山県",
            "city":"かつらぎ町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｶﾂﾗｷﾞﾁｮｳ"
        },
        "303437":{
            "cityId":303437,
            "pref":"和歌山県",
            "city":"九度山町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｸﾄﾞﾔﾏﾁｮｳ"
        },
        "303445":{
            "cityId":303445,
            "pref":"和歌山県",
            "city":"高野町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｺｳﾔﾁｮｳ"
        },
        "303615":{
            "cityId":303615,
            "pref":"和歌山県",
            "city":"湯浅町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾕｱｻﾁｮｳ"
        },
        "303623":{
            "cityId":303623,
            "pref":"和歌山県",
            "city":"広川町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾋﾛｶﾞﾜﾁｮｳ"
        },
        "303666":{
            "cityId":303666,
            "pref":"和歌山県",
            "city":"有田川町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｱﾘﾀﾞｶﾞﾜﾁｮｳ"
        },
        "303810":{
            "cityId":303810,
            "pref":"和歌山県",
            "city":"美浜町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾐﾊﾏﾁｮｳ"
        },
        "303828":{
            "cityId":303828,
            "pref":"和歌山県",
            "city":"日高町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾋﾀﾞｶﾁｮｳ"
        },
        "303836":{
            "cityId":303836,
            "pref":"和歌山県",
            "city":"由良町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾕﾗﾁｮｳ"
        },
        "303909":{
            "cityId":303909,
            "pref":"和歌山県",
            "city":"印南町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｲﾅﾐﾁｮｳ"
        },
        "303917":{
            "cityId":303917,
            "pref":"和歌山県",
            "city":"みなべ町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾐﾅﾍﾞﾁｮｳ"
        },
        "303925":{
            "cityId":303925,
            "pref":"和歌山県",
            "city":"日高川町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾋﾀﾞｶｶﾞﾜﾁｮｳ"
        },
        "304018":{
            "cityId":304018,
            "pref":"和歌山県",
            "city":"白浜町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｼﾗﾊﾏﾁｮｳ"
        },
        "304042":{
            "cityId":304042,
            "pref":"和歌山県",
            "city":"上富田町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｶﾐﾄﾝﾀﾞﾁｮｳ"
        },
        "304069":{
            "cityId":304069,
            "pref":"和歌山県",
            "city":"すさみ町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｽｻﾐﾁｮｳ"
        },
        "304212":{
            "cityId":304212,
            "pref":"和歌山県",
            "city":"那智勝浦町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾅﾁｶﾂｳﾗﾁｮｳ"
        },
        "304221":{
            "cityId":304221,
            "pref":"和歌山県",
            "city":"太地町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ﾀｲｼﾞﾁｮｳ"
        },
        "304247":{
            "cityId":304247,
            "pref":"和歌山県",
            "city":"古座川町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｺｻﾞｶﾞﾜﾁｮｳ"
        },
        "304271":{
            "cityId":304271,
            "pref":"和歌山県",
            "city":"北山村",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｷﾀﾔﾏﾑﾗ"
        },
        "304280":{
            "cityId":304280,
            "pref":"和歌山県",
            "city":"串本町",
            "prefKana":"ﾜｶﾔﾏｹﾝ",
            "cityKana":"ｸｼﾓﾄﾁｮｳ"
        }
    },
    "鳥取":{
        "310000":{
            "cityId":310000,
            "pref":"鳥取県",
            "city":"",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":""
        },
        "312011":{
            "cityId":312011,
            "pref":"鳥取県",
            "city":"鳥取市",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾄｯﾄﾘｼ"
        },
        "312029":{
            "cityId":312029,
            "pref":"鳥取県",
            "city":"米子市",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾖﾅｺﾞｼ"
        },
        "312037":{
            "cityId":312037,
            "pref":"鳥取県",
            "city":"倉吉市",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ｸﾗﾖｼｼ"
        },
        "312045":{
            "cityId":312045,
            "pref":"鳥取県",
            "city":"境港市",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ｻｶｲﾐﾅﾄｼ"
        },
        "313025":{
            "cityId":313025,
            "pref":"鳥取県",
            "city":"岩美町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ｲﾜﾐﾁｮｳ"
        },
        "313254":{
            "cityId":313254,
            "pref":"鳥取県",
            "city":"若桜町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾜｶｻﾁｮｳ"
        },
        "313289":{
            "cityId":313289,
            "pref":"鳥取県",
            "city":"智頭町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾁﾂﾞﾁｮｳ"
        },
        "313297":{
            "cityId":313297,
            "pref":"鳥取県",
            "city":"八頭町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾔｽﾞﾁｮｳ"
        },
        "313645":{
            "cityId":313645,
            "pref":"鳥取県",
            "city":"三朝町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾐｻｻﾁｮｳ"
        },
        "313700":{
            "cityId":313700,
            "pref":"鳥取県",
            "city":"湯梨浜町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾕﾘﾊﾏﾁｮｳ"
        },
        "313718":{
            "cityId":313718,
            "pref":"鳥取県",
            "city":"琴浦町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ｺﾄｳﾗﾁｮｳ"
        },
        "313726":{
            "cityId":313726,
            "pref":"鳥取県",
            "city":"北栄町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾎｸｴｲﾁｮｳ"
        },
        "313840":{
            "cityId":313840,
            "pref":"鳥取県",
            "city":"日吉津村",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾋｴﾂﾞｿﾝ"
        },
        "313866":{
            "cityId":313866,
            "pref":"鳥取県",
            "city":"大山町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾀﾞｲｾﾝﾁｮｳ"
        },
        "313891":{
            "cityId":313891,
            "pref":"鳥取県",
            "city":"南部町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾅﾝﾌﾞﾁｮｳ"
        },
        "313904":{
            "cityId":313904,
            "pref":"鳥取県",
            "city":"伯耆町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾎｳｷﾁｮｳ"
        },
        "314013":{
            "cityId":314013,
            "pref":"鳥取県",
            "city":"日南町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾆﾁﾅﾝﾁｮｳ"
        },
        "314021":{
            "cityId":314021,
            "pref":"鳥取県",
            "city":"日野町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ﾋﾉﾁｮｳ"
        },
        "314030":{
            "cityId":314030,
            "pref":"鳥取県",
            "city":"江府町",
            "prefKana":"ﾄｯﾄﾘｹﾝ",
            "cityKana":"ｺｳﾌﾁｮｳ"
        }
    },
    "島根":{
        "320005":{
            "cityId":320005,
            "pref":"島根県",
            "city":"",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":""
        },
        "322016":{
            "cityId":322016,
            "pref":"島根県",
            "city":"松江市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾏﾂｴｼ"
        },
        "322024":{
            "cityId":322024,
            "pref":"島根県",
            "city":"浜田市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾊﾏﾀﾞｼ"
        },
        "322032":{
            "cityId":322032,
            "pref":"島根県",
            "city":"出雲市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｲｽﾞﾓｼ"
        },
        "322041":{
            "cityId":322041,
            "pref":"島根県",
            "city":"益田市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾏｽﾀﾞｼ"
        },
        "322059":{
            "cityId":322059,
            "pref":"島根県",
            "city":"大田市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｵｵﾀﾞｼ"
        },
        "322067":{
            "cityId":322067,
            "pref":"島根県",
            "city":"安来市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾔｽｷﾞｼ"
        },
        "322075":{
            "cityId":322075,
            "pref":"島根県",
            "city":"江津市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｺﾞｳﾂｼ"
        },
        "322091":{
            "cityId":322091,
            "pref":"島根県",
            "city":"雲南市",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｳﾝﾅﾝｼ"
        },
        "323438":{
            "cityId":323438,
            "pref":"島根県",
            "city":"奥出雲町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｵｸｲｽﾞﾓﾁｮｳ"
        },
        "323861":{
            "cityId":323861,
            "pref":"島根県",
            "city":"飯南町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｲｲﾅﾝﾁｮｳ"
        },
        "324418":{
            "cityId":324418,
            "pref":"島根県",
            "city":"川本町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｶﾜﾓﾄﾏﾁ"
        },
        "324485":{
            "cityId":324485,
            "pref":"島根県",
            "city":"美郷町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾐｻﾄﾁｮｳ"
        },
        "324493":{
            "cityId":324493,
            "pref":"島根県",
            "city":"邑南町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｵｵﾅﾝﾁｮｳ"
        },
        "325015":{
            "cityId":325015,
            "pref":"島根県",
            "city":"津和野町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾂﾜﾉﾁｮｳ"
        },
        "325058":{
            "cityId":325058,
            "pref":"島根県",
            "city":"吉賀町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾖｼｶﾁｮｳ"
        },
        "325252":{
            "cityId":325252,
            "pref":"島根県",
            "city":"海士町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｱﾏﾁｮｳ"
        },
        "325261":{
            "cityId":325261,
            "pref":"島根県",
            "city":"西ノ島町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾆｼﾉｼﾏﾁｮｳ"
        },
        "325279":{
            "cityId":325279,
            "pref":"島根県",
            "city":"知夫村",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ﾁﾌﾞﾑﾗ"
        },
        "325287":{
            "cityId":325287,
            "pref":"島根県",
            "city":"隠岐の島町",
            "prefKana":"ｼﾏﾈｹﾝ",
            "cityKana":"ｵｷﾉｼﾏﾁｮｳ"
        }
    },
    "岡山":{
        "330001":{
            "cityId":330001,
            "pref":"岡山県",
            "city":"",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":""
        },
        "331007":{
            "cityId":331007,
            "pref":"岡山県",
            "city":"岡山市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｵｶﾔﾏｼ"
        },
        "332020":{
            "cityId":332020,
            "pref":"岡山県",
            "city":"倉敷市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｸﾗｼｷｼ"
        },
        "332038":{
            "cityId":332038,
            "pref":"岡山県",
            "city":"津山市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾂﾔﾏｼ"
        },
        "332046":{
            "cityId":332046,
            "pref":"岡山県",
            "city":"玉野市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾀﾏﾉｼ"
        },
        "332054":{
            "cityId":332054,
            "pref":"岡山県",
            "city":"笠岡市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｶｻｵｶｼ"
        },
        "332071":{
            "cityId":332071,
            "pref":"岡山県",
            "city":"井原市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｲﾊﾞﾗｼ"
        },
        "332089":{
            "cityId":332089,
            "pref":"岡山県",
            "city":"総社市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｿｳｼﾞﾔｼ"
        },
        "332097":{
            "cityId":332097,
            "pref":"岡山県",
            "city":"高梁市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾀｶﾊｼｼ"
        },
        "332101":{
            "cityId":332101,
            "pref":"岡山県",
            "city":"新見市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾆｲﾐｼ"
        },
        "332119":{
            "cityId":332119,
            "pref":"岡山県",
            "city":"備前市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾋﾞｾﾞﾝｼ"
        },
        "332127":{
            "cityId":332127,
            "pref":"岡山県",
            "city":"瀬戸内市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｾﾄｳﾁｼ"
        },
        "332135":{
            "cityId":332135,
            "pref":"岡山県",
            "city":"赤磐市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｱｶｲﾜｼ"
        },
        "332143":{
            "cityId":332143,
            "pref":"岡山県",
            "city":"真庭市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾏﾆﾜｼ"
        },
        "332151":{
            "cityId":332151,
            "pref":"岡山県",
            "city":"美作市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾐﾏｻｶｼ"
        },
        "332160":{
            "cityId":332160,
            "pref":"岡山県",
            "city":"浅口市",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｱｻｸﾁｼ"
        },
        "333468":{
            "cityId":333468,
            "pref":"岡山県",
            "city":"和気町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾜｹﾁｮｳ"
        },
        "334235":{
            "cityId":334235,
            "pref":"岡山県",
            "city":"早島町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾊﾔｼﾏﾁｮｳ"
        },
        "334456":{
            "cityId":334456,
            "pref":"岡山県",
            "city":"里庄町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｻﾄｼｮｳﾁｮｳ"
        },
        "334618":{
            "cityId":334618,
            "pref":"岡山県",
            "city":"矢掛町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾔｶｹﾞﾁｮｳ"
        },
        "335860":{
            "cityId":335860,
            "pref":"岡山県",
            "city":"新庄村",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｼﾝｼﾞﾖｳｿﾝ"
        },
        "336068":{
            "cityId":336068,
            "pref":"岡山県",
            "city":"鏡野町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｶｶﾞﾐﾉﾁｮｳ"
        },
        "336220":{
            "cityId":336220,
            "pref":"岡山県",
            "city":"勝央町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｼｮｳｵｳﾁｮｳ"
        },
        "336238":{
            "cityId":336238,
            "pref":"岡山県",
            "city":"奈義町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾅｷﾞﾁｮｳ"
        },
        "336432":{
            "cityId":336432,
            "pref":"岡山県",
            "city":"西粟倉村",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾆｼｱﾜｸﾗｿﾝ"
        },
        "336637":{
            "cityId":336637,
            "pref":"岡山県",
            "city":"久米南町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｸﾒﾅﾝﾁｮｳ"
        },
        "336661":{
            "cityId":336661,
            "pref":"岡山県",
            "city":"美咲町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ﾐｻｷﾁｮｳ"
        },
        "336815":{
            "cityId":336815,
            "pref":"岡山県",
            "city":"吉備中央町",
            "prefKana":"ｵｶﾔﾏｹﾝ",
            "cityKana":"ｷﾋﾞﾁｭｳｵｳﾁｮｳ"
        }
    },
    "広島":{
        "340006":{
            "cityId":340006,
            "pref":"広島県",
            "city":"",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":""
        },
        "341002":{
            "cityId":341002,
            "pref":"広島県",
            "city":"広島市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾋﾛｼﾏｼ"
        },
        "342025":{
            "cityId":342025,
            "pref":"広島県",
            "city":"呉市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｸﾚｼ"
        },
        "342033":{
            "cityId":342033,
            "pref":"広島県",
            "city":"竹原市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾀｹﾊﾗｼ"
        },
        "342041":{
            "cityId":342041,
            "pref":"広島県",
            "city":"三原市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾐﾊﾗｼ"
        },
        "342050":{
            "cityId":342050,
            "pref":"広島県",
            "city":"尾道市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｵﾉﾐﾁｼ"
        },
        "342076":{
            "cityId":342076,
            "pref":"広島県",
            "city":"福山市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾌｸﾔﾏｼ"
        },
        "342084":{
            "cityId":342084,
            "pref":"広島県",
            "city":"府中市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾌﾁｭｳｼ"
        },
        "342092":{
            "cityId":342092,
            "pref":"広島県",
            "city":"三次市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾐﾖｼｼ"
        },
        "342106":{
            "cityId":342106,
            "pref":"広島県",
            "city":"庄原市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｼｮｳﾊﾞﾗｼ"
        },
        "342114":{
            "cityId":342114,
            "pref":"広島県",
            "city":"大竹市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｵｵﾀｹｼ"
        },
        "342122":{
            "cityId":342122,
            "pref":"広島県",
            "city":"東広島市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼﾋﾛｼﾏｼ"
        },
        "342131":{
            "cityId":342131,
            "pref":"広島県",
            "city":"廿日市市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾊﾂｶｲﾁｼ"
        },
        "342149":{
            "cityId":342149,
            "pref":"広島県",
            "city":"安芸高田市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｱｷﾀｶﾀｼ"
        },
        "342157":{
            "cityId":342157,
            "pref":"広島県",
            "city":"江田島市",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｴﾀｼﾞﾏｼ"
        },
        "343021":{
            "cityId":343021,
            "pref":"広島県",
            "city":"府中町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ﾌﾁｭｳﾁｮｳ"
        },
        "343048":{
            "cityId":343048,
            "pref":"広島県",
            "city":"海田町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｶｲﾀﾁｮｳ"
        },
        "343072":{
            "cityId":343072,
            "pref":"広島県",
            "city":"熊野町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｸﾏﾉﾁｮｳ"
        },
        "343099":{
            "cityId":343099,
            "pref":"広島県",
            "city":"坂町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｻｶﾁｮｳ"
        },
        "343684":{
            "cityId":343684,
            "pref":"広島県",
            "city":"安芸太田町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｱｷｵｵﾀﾁｮｳ"
        },
        "343692":{
            "cityId":343692,
            "pref":"広島県",
            "city":"北広島町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｷﾀﾋﾛｼﾏﾁｮｳ"
        },
        "344311":{
            "cityId":344311,
            "pref":"広島県",
            "city":"大崎上島町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｵｵｻｷｶﾐｼﾞﾏﾁｮｳ"
        },
        "344621":{
            "cityId":344621,
            "pref":"広島県",
            "city":"世羅町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｾﾗﾁｮｳ"
        },
        "345458":{
            "cityId":345458,
            "pref":"広島県",
            "city":"神石高原町",
            "prefKana":"ﾋﾛｼﾏｹﾝ",
            "cityKana":"ｼﾞﾝｾｷｺｳｹﾞﾝﾁｮｳ"
        }
    },
    "山口":{
        "350001":{
            "cityId":350001,
            "pref":"山口県",
            "city":"",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":""
        },
        "352012":{
            "cityId":352012,
            "pref":"山口県",
            "city":"下関市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｼﾓﾉｾｷｼ"
        },
        "352021":{
            "cityId":352021,
            "pref":"山口県",
            "city":"宇部市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｳﾍﾞｼ"
        },
        "352039":{
            "cityId":352039,
            "pref":"山口県",
            "city":"山口市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾔﾏｸﾞﾁｼ"
        },
        "352047":{
            "cityId":352047,
            "pref":"山口県",
            "city":"萩市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾊｷﾞｼ"
        },
        "352063":{
            "cityId":352063,
            "pref":"山口県",
            "city":"防府市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾎｳﾌｼ"
        },
        "352071":{
            "cityId":352071,
            "pref":"山口県",
            "city":"下松市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｸﾀﾞﾏﾂｼ"
        },
        "352080":{
            "cityId":352080,
            "pref":"山口県",
            "city":"岩国市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｲﾜｸﾆｼ"
        },
        "352101":{
            "cityId":352101,
            "pref":"山口県",
            "city":"光市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾋｶﾘｼ"
        },
        "352110":{
            "cityId":352110,
            "pref":"山口県",
            "city":"長門市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾅｶﾞﾄｼ"
        },
        "352128":{
            "cityId":352128,
            "pref":"山口県",
            "city":"柳井市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾔﾅｲｼ"
        },
        "352136":{
            "cityId":352136,
            "pref":"山口県",
            "city":"美祢市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾐﾈｼ"
        },
        "352152":{
            "cityId":352152,
            "pref":"山口県",
            "city":"周南市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｼｭｳﾅﾝｼ"
        },
        "352161":{
            "cityId":352161,
            "pref":"山口県",
            "city":"山陽小野田市",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｻﾝﾖｳｵﾉﾀﾞｼ"
        },
        "353051":{
            "cityId":353051,
            "pref":"山口県",
            "city":"周防大島町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｽｵｳｵｵｼﾏﾁｮｳ"
        },
        "353213":{
            "cityId":353213,
            "pref":"山口県",
            "city":"和木町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾜｷﾁｮｳ"
        },
        "353418":{
            "cityId":353418,
            "pref":"山口県",
            "city":"上関町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｶﾐﾉｾｷﾁｮｳ"
        },
        "353434":{
            "cityId":353434,
            "pref":"山口県",
            "city":"田布施町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾀﾌﾞｾﾁｮｳ"
        },
        "353442":{
            "cityId":353442,
            "pref":"山口県",
            "city":"平生町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ﾋﾗｵﾁｮｳ"
        },
        "355020":{
            "cityId":355020,
            "pref":"山口県",
            "city":"阿武町",
            "prefKana":"ﾔﾏｸﾞﾁｹﾝ",
            "cityKana":"ｱﾌﾞﾁｮｳ"
        }
    },
    "徳島":{
        "360007":{
            "cityId":360007,
            "pref":"徳島県",
            "city":"",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":""
        },
        "362018":{
            "cityId":362018,
            "pref":"徳島県",
            "city":"徳島市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾄｸｼﾏｼ"
        },
        "362026":{
            "cityId":362026,
            "pref":"徳島県",
            "city":"鳴門市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾅﾙﾄｼ"
        },
        "362034":{
            "cityId":362034,
            "pref":"徳島県",
            "city":"小松島市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｺﾏﾂｼﾏｼ"
        },
        "362042":{
            "cityId":362042,
            "pref":"徳島県",
            "city":"阿南市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｱﾅﾝｼ"
        },
        "362051":{
            "cityId":362051,
            "pref":"徳島県",
            "city":"吉野川市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾖｼﾉｶﾞﾜｼ"
        },
        "362069":{
            "cityId":362069,
            "pref":"徳島県",
            "city":"阿波市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｱﾜｼ"
        },
        "362077":{
            "cityId":362077,
            "pref":"徳島県",
            "city":"美馬市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾐﾏｼ"
        },
        "362085":{
            "cityId":362085,
            "pref":"徳島県",
            "city":"三好市",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾐﾖｼｼ"
        },
        "363014":{
            "cityId":363014,
            "pref":"徳島県",
            "city":"勝浦町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｶﾂｳﾗﾁｮｳ"
        },
        "363022":{
            "cityId":363022,
            "pref":"徳島県",
            "city":"上勝町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｶﾐｶﾂﾁｮｳ"
        },
        "363219":{
            "cityId":363219,
            "pref":"徳島県",
            "city":"佐那河内村",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｻﾅｺﾞｳﾁｿﾝ"
        },
        "363413":{
            "cityId":363413,
            "pref":"徳島県",
            "city":"石井町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｲｼｲﾁｮｳ"
        },
        "363421":{
            "cityId":363421,
            "pref":"徳島県",
            "city":"神山町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｶﾐﾔﾏﾁｮｳ"
        },
        "363685":{
            "cityId":363685,
            "pref":"徳島県",
            "city":"那賀町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾅｶﾁｮｳ"
        },
        "363839":{
            "cityId":363839,
            "pref":"徳島県",
            "city":"牟岐町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾑｷﾞﾁｮｳ"
        },
        "363871":{
            "cityId":363871,
            "pref":"徳島県",
            "city":"美波町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐﾁｮｳ"
        },
        "363880":{
            "cityId":363880,
            "pref":"徳島県",
            "city":"海陽町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｶｲﾖｳﾁｮｳ"
        },
        "364011":{
            "cityId":364011,
            "pref":"徳島県",
            "city":"松茂町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾏﾂｼｹﾞﾁｮｳ"
        },
        "364029":{
            "cityId":364029,
            "pref":"徳島県",
            "city":"北島町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｷﾀｼﾞﾏﾁｮｳ"
        },
        "364037":{
            "cityId":364037,
            "pref":"徳島県",
            "city":"藍住町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｱｲｽﾞﾐﾁｮｳ"
        },
        "364045":{
            "cityId":364045,
            "pref":"徳島県",
            "city":"板野町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｲﾀﾉﾁｮｳ"
        },
        "364053":{
            "cityId":364053,
            "pref":"徳島県",
            "city":"上板町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ｶﾐｲﾀﾁｮｳ"
        },
        "364681":{
            "cityId":364681,
            "pref":"徳島県",
            "city":"つるぎ町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾂﾙｷﾞﾁｮｳ"
        },
        "364894":{
            "cityId":364894,
            "pref":"徳島県",
            "city":"東みよし町",
            "prefKana":"ﾄｸｼﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼﾐﾖｼﾁｮｳ"
        }
    },
    "香川":{
        "370002":{
            "cityId":370002,
            "pref":"香川県",
            "city":"",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":""
        },
        "372013":{
            "cityId":372013,
            "pref":"香川県",
            "city":"高松市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾀｶﾏﾂｼ"
        },
        "372021":{
            "cityId":372021,
            "pref":"香川県",
            "city":"丸亀市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾏﾙｶﾞﾒｼ"
        },
        "372030":{
            "cityId":372030,
            "pref":"香川県",
            "city":"坂出市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｻｶｲﾃﾞｼ"
        },
        "372048":{
            "cityId":372048,
            "pref":"香川県",
            "city":"善通寺市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｾﾞﾝﾂｳｼﾞｼ"
        },
        "372056":{
            "cityId":372056,
            "pref":"香川県",
            "city":"観音寺市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｶﾝｵﾝｼﾞｼ"
        },
        "372064":{
            "cityId":372064,
            "pref":"香川県",
            "city":"さぬき市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｻﾇｷｼ"
        },
        "372072":{
            "cityId":372072,
            "pref":"香川県",
            "city":"東かがわ市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾋｶﾞｼｶｶﾞﾜｼ"
        },
        "372081":{
            "cityId":372081,
            "pref":"香川県",
            "city":"三豊市",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾐﾄﾖｼ"
        },
        "373222":{
            "cityId":373222,
            "pref":"香川県",
            "city":"土庄町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾄﾉｼｮｳﾁｮｳ"
        },
        "373249":{
            "cityId":373249,
            "pref":"香川県",
            "city":"小豆島町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｼｮｳﾄﾞｼﾏﾁｮｳ"
        },
        "373419":{
            "cityId":373419,
            "pref":"香川県",
            "city":"三木町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾐｷﾁｮｳ"
        },
        "373648":{
            "cityId":373648,
            "pref":"香川県",
            "city":"直島町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾅｵｼﾏﾁｮｳ"
        },
        "373869":{
            "cityId":373869,
            "pref":"香川県",
            "city":"宇多津町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｳﾀﾂﾞﾁｮｳ"
        },
        "373877":{
            "cityId":373877,
            "pref":"香川県",
            "city":"綾川町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｱﾔｶﾞﾜﾁｮｳ"
        },
        "374032":{
            "cityId":374032,
            "pref":"香川県",
            "city":"琴平町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ｺﾄﾋﾗﾁｮｳ"
        },
        "374041":{
            "cityId":374041,
            "pref":"香川県",
            "city":"多度津町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾀﾄﾞﾂﾁｮｳ"
        },
        "374067":{
            "cityId":374067,
            "pref":"香川県",
            "city":"まんのう町",
            "prefKana":"ｶｶﾞﾜｹﾝ",
            "cityKana":"ﾏﾝﾉｳﾁｮｳ"
        }
    },
    "愛媛":{
        "380008":{
            "cityId":380008,
            "pref":"愛媛県",
            "city":"",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":""
        },
        "382019":{
            "cityId":382019,
            "pref":"愛媛県",
            "city":"松山市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾏﾂﾔﾏｼ"
        },
        "382027":{
            "cityId":382027,
            "pref":"愛媛県",
            "city":"今治市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｲﾏﾊﾞﾘｼ"
        },
        "382035":{
            "cityId":382035,
            "pref":"愛媛県",
            "city":"宇和島市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｳﾜｼﾞﾏｼ"
        },
        "382043":{
            "cityId":382043,
            "pref":"愛媛県",
            "city":"八幡浜市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾔﾜﾀﾊﾏｼ"
        },
        "382051":{
            "cityId":382051,
            "pref":"愛媛県",
            "city":"新居浜市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾆｲﾊﾏｼ"
        },
        "382060":{
            "cityId":382060,
            "pref":"愛媛県",
            "city":"西条市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｻｲｼﾞｮｳｼ"
        },
        "382078":{
            "cityId":382078,
            "pref":"愛媛県",
            "city":"大洲市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｵｵｽﾞｼ"
        },
        "382108":{
            "cityId":382108,
            "pref":"愛媛県",
            "city":"伊予市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｲﾖｼ"
        },
        "382132":{
            "cityId":382132,
            "pref":"愛媛県",
            "city":"四国中央市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｼｺｸﾁｭｳｵｳｼ"
        },
        "382141":{
            "cityId":382141,
            "pref":"愛媛県",
            "city":"西予市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｾｲﾖｼ"
        },
        "382159":{
            "cityId":382159,
            "pref":"愛媛県",
            "city":"東温市",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾄｳｵﾝｼ"
        },
        "383562":{
            "cityId":383562,
            "pref":"愛媛県",
            "city":"上島町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｶﾐｼﾞﾏﾁｮｳ"
        },
        "383864":{
            "cityId":383864,
            "pref":"愛媛県",
            "city":"久万高原町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｸﾏｺｳｹﾞﾝﾁｮｳ"
        },
        "384011":{
            "cityId":384011,
            "pref":"愛媛県",
            "city":"松前町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾏｻｷﾁｮｳ"
        },
        "384020":{
            "cityId":384020,
            "pref":"愛媛県",
            "city":"砥部町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾄﾍﾞﾁｮｳ"
        },
        "384224":{
            "cityId":384224,
            "pref":"愛媛県",
            "city":"内子町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｳﾁｺﾁｮｳ"
        },
        "384429":{
            "cityId":384429,
            "pref":"愛媛県",
            "city":"伊方町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｲｶﾀﾁｮｳ"
        },
        "384844":{
            "cityId":384844,
            "pref":"愛媛県",
            "city":"松野町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ﾏﾂﾉﾁｮｳ"
        },
        "384887":{
            "cityId":384887,
            "pref":"愛媛県",
            "city":"鬼北町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｷﾎｸﾁｮｳ"
        },
        "385069":{
            "cityId":385069,
            "pref":"愛媛県",
            "city":"愛南町",
            "prefKana":"ｴﾋﾒｹﾝ",
            "cityKana":"ｱｲﾅﾝﾁｮｳ"
        }
    },
    "高知":{
        "390003":{
            "cityId":390003,
            "pref":"高知県",
            "city":"",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":""
        },
        "392014":{
            "cityId":392014,
            "pref":"高知県",
            "city":"高知市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｺｳﾁｼ"
        },
        "392022":{
            "cityId":392022,
            "pref":"高知県",
            "city":"室戸市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾑﾛﾄｼ"
        },
        "392031":{
            "cityId":392031,
            "pref":"高知県",
            "city":"安芸市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｱｷｼ"
        },
        "392049":{
            "cityId":392049,
            "pref":"高知県",
            "city":"南国市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾅﾝｺｸｼ"
        },
        "392057":{
            "cityId":392057,
            "pref":"高知県",
            "city":"土佐市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾄｻｼ"
        },
        "392065":{
            "cityId":392065,
            "pref":"高知県",
            "city":"須崎市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｽｻｷｼ"
        },
        "392081":{
            "cityId":392081,
            "pref":"高知県",
            "city":"宿毛市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｽｸﾓｼ"
        },
        "392090":{
            "cityId":392090,
            "pref":"高知県",
            "city":"土佐清水市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾄｻｼﾐｽﾞｼ"
        },
        "392103":{
            "cityId":392103,
            "pref":"高知県",
            "city":"四万十市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｼﾏﾝﾄｼ"
        },
        "392111":{
            "cityId":392111,
            "pref":"高知県",
            "city":"香南市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｺｳﾅﾝｼ"
        },
        "392120":{
            "cityId":392120,
            "pref":"高知県",
            "city":"香美市",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｶﾐｼ"
        },
        "393011":{
            "cityId":393011,
            "pref":"高知県",
            "city":"東洋町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾄｳﾖｳﾁｮｳ"
        },
        "393029":{
            "cityId":393029,
            "pref":"高知県",
            "city":"奈半利町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾅﾊﾘﾁｮｳ"
        },
        "393037":{
            "cityId":393037,
            "pref":"高知県",
            "city":"田野町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾀﾉﾁｮｳ"
        },
        "393045":{
            "cityId":393045,
            "pref":"高知県",
            "city":"安田町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾔｽﾀﾞﾁｮｳ"
        },
        "393053":{
            "cityId":393053,
            "pref":"高知県",
            "city":"北川村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｷﾀｶﾞﾜﾑﾗ"
        },
        "393061":{
            "cityId":393061,
            "pref":"高知県",
            "city":"馬路村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｳﾏｼﾞﾑﾗ"
        },
        "393070":{
            "cityId":393070,
            "pref":"高知県",
            "city":"芸西村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｹﾞｲｾｲﾑﾗ"
        },
        "393410":{
            "cityId":393410,
            "pref":"高知県",
            "city":"本山町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾓﾄﾔﾏﾁｮｳ"
        },
        "393444":{
            "cityId":393444,
            "pref":"高知県",
            "city":"大豊町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｵｵﾄﾖﾁｮｳ"
        },
        "393631":{
            "cityId":393631,
            "pref":"高知県",
            "city":"土佐町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾄｻﾁｮｳ"
        },
        "393649":{
            "cityId":393649,
            "pref":"高知県",
            "city":"大川村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｵｵｶﾜﾑﾗ"
        },
        "393860":{
            "cityId":393860,
            "pref":"高知県",
            "city":"いの町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｲﾉﾁｮｳ"
        },
        "393878":{
            "cityId":393878,
            "pref":"高知県",
            "city":"仁淀川町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾆﾖﾄﾞｶﾞﾜﾁｮｳ"
        },
        "394017":{
            "cityId":394017,
            "pref":"高知県",
            "city":"中土佐町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾅｶﾄｻﾁｮｳ"
        },
        "394025":{
            "cityId":394025,
            "pref":"高知県",
            "city":"佐川町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｻｶﾜﾁｮｳ"
        },
        "394033":{
            "cityId":394033,
            "pref":"高知県",
            "city":"越知町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｵﾁﾁｮｳ"
        },
        "394050":{
            "cityId":394050,
            "pref":"高知県",
            "city":"梼原町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾕｽﾊﾗﾁｮｳ"
        },
        "394106":{
            "cityId":394106,
            "pref":"高知県",
            "city":"日高村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾋﾀﾞｶﾑﾗ"
        },
        "394114":{
            "cityId":394114,
            "pref":"高知県",
            "city":"津野町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾂﾉﾁｮｳ"
        },
        "394122":{
            "cityId":394122,
            "pref":"高知県",
            "city":"四万十町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｼﾏﾝﾄﾁｮｳ"
        },
        "394246":{
            "cityId":394246,
            "pref":"高知県",
            "city":"大月町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｵｵﾂｷﾁｮｳ"
        },
        "394271":{
            "cityId":394271,
            "pref":"高知県",
            "city":"三原村",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ﾐﾊﾗﾑﾗ"
        },
        "394289":{
            "cityId":394289,
            "pref":"高知県",
            "city":"黒潮町",
            "prefKana":"ｺｳﾁｹﾝ",
            "cityKana":"ｸﾛｼｵﾁｮｳ"
        }
    },
    "福岡":{
        "400009":{
            "cityId":400009,
            "pref":"福岡県",
            "city":"",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":""
        },
        "401005":{
            "cityId":401005,
            "pref":"福岡県",
            "city":"北九州市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｷﾀｷｭｳｼｭｳｼ"
        },
        "401307":{
            "cityId":401307,
            "pref":"福岡県",
            "city":"福岡市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾌｸｵｶｼ"
        },
        "402028":{
            "cityId":402028,
            "pref":"福岡県",
            "city":"大牟田市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｵﾑﾀｼ"
        },
        "402036":{
            "cityId":402036,
            "pref":"福岡県",
            "city":"久留米市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｸﾙﾒｼ"
        },
        "402044":{
            "cityId":402044,
            "pref":"福岡県",
            "city":"直方市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾉｵｶﾞﾀｼ"
        },
        "402052":{
            "cityId":402052,
            "pref":"福岡県",
            "city":"飯塚市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｲｲﾂﾞｶｼ"
        },
        "402061":{
            "cityId":402061,
            "pref":"福岡県",
            "city":"田川市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾀｶﾞﾜｼ"
        },
        "402079":{
            "cityId":402079,
            "pref":"福岡県",
            "city":"柳川市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾔﾅｶﾞﾜｼ"
        },
        "402109":{
            "cityId":402109,
            "pref":"福岡県",
            "city":"八女市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾔﾒｼ"
        },
        "402117":{
            "cityId":402117,
            "pref":"福岡県",
            "city":"筑後市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾁｸｺﾞｼ"
        },
        "402125":{
            "cityId":402125,
            "pref":"福岡県",
            "city":"大川市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｵｶﾜｼ"
        },
        "402133":{
            "cityId":402133,
            "pref":"福岡県",
            "city":"行橋市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾕｸﾊｼｼ"
        },
        "402141":{
            "cityId":402141,
            "pref":"福岡県",
            "city":"豊前市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾌﾞｾﾞﾝｼ"
        },
        "402150":{
            "cityId":402150,
            "pref":"福岡県",
            "city":"中間市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾅｶﾏｼ"
        },
        "402168":{
            "cityId":402168,
            "pref":"福岡県",
            "city":"小郡市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｺﾞｵﾘｼ"
        },
        "402176":{
            "cityId":402176,
            "pref":"福岡県",
            "city":"筑紫野市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾁｸｼﾉｼ"
        },
        "402184":{
            "cityId":402184,
            "pref":"福岡県",
            "city":"春日市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶｽｶﾞｼ"
        },
        "402192":{
            "cityId":402192,
            "pref":"福岡県",
            "city":"大野城市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｵﾉｼﾞｮｳｼ"
        },
        "402206":{
            "cityId":402206,
            "pref":"福岡県",
            "city":"宗像市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾑﾅｶﾀｼ"
        },
        "402214":{
            "cityId":402214,
            "pref":"福岡県",
            "city":"太宰府市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾀﾞｻﾞｲﾌｼ"
        },
        "402231":{
            "cityId":402231,
            "pref":"福岡県",
            "city":"古賀市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｺｶﾞｼ"
        },
        "402249":{
            "cityId":402249,
            "pref":"福岡県",
            "city":"福津市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾌｸﾂｼ"
        },
        "402257":{
            "cityId":402257,
            "pref":"福岡県",
            "city":"うきは市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｳｷﾊｼ"
        },
        "402265":{
            "cityId":402265,
            "pref":"福岡県",
            "city":"宮若市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾐﾔﾜｶｼ"
        },
        "402273":{
            "cityId":402273,
            "pref":"福岡県",
            "city":"嘉麻市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶﾏｼ"
        },
        "402281":{
            "cityId":402281,
            "pref":"福岡県",
            "city":"朝倉市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｱｻｸﾗｼ"
        },
        "402290":{
            "cityId":402290,
            "pref":"福岡県",
            "city":"みやま市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾐﾔﾏｼ"
        },
        "402303":{
            "cityId":402303,
            "pref":"福岡県",
            "city":"糸島市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｲﾄｼﾏｼ"
        },
        "402311":{
            "cityId":402311,
            "pref":"福岡県",
            "city":"那珂川市",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾅｶｶﾞﾜｼ"
        },
        "403415":{
            "cityId":403415,
            "pref":"福岡県",
            "city":"宇美町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｳﾐﾏﾁ"
        },
        "403423":{
            "cityId":403423,
            "pref":"福岡県",
            "city":"篠栗町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｻｻｸﾞﾘﾏﾁ"
        },
        "403431":{
            "cityId":403431,
            "pref":"福岡県",
            "city":"志免町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｼﾒﾏﾁ"
        },
        "403440":{
            "cityId":403440,
            "pref":"福岡県",
            "city":"須恵町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｽｴﾏﾁ"
        },
        "403458":{
            "cityId":403458,
            "pref":"福岡県",
            "city":"新宮町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｼﾝｸﾞｳﾏﾁ"
        },
        "403482":{
            "cityId":403482,
            "pref":"福岡県",
            "city":"久山町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾋｻﾔﾏﾏﾁ"
        },
        "403491":{
            "cityId":403491,
            "pref":"福岡県",
            "city":"粕屋町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶｽﾔﾏﾁ"
        },
        "403814":{
            "cityId":403814,
            "pref":"福岡県",
            "city":"芦屋町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｱｼﾔﾏﾁ"
        },
        "403822":{
            "cityId":403822,
            "pref":"福岡県",
            "city":"水巻町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾐｽﾞﾏｷﾏﾁ"
        },
        "403831":{
            "cityId":403831,
            "pref":"福岡県",
            "city":"岡垣町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｶｶﾞｷﾏﾁ"
        },
        "403849":{
            "cityId":403849,
            "pref":"福岡県",
            "city":"遠賀町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵﾝｶﾞﾁｮｳ"
        },
        "404012":{
            "cityId":404012,
            "pref":"福岡県",
            "city":"小竹町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｺﾀｹﾏﾁ"
        },
        "404021":{
            "cityId":404021,
            "pref":"福岡県",
            "city":"鞍手町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｸﾗﾃﾏﾁ"
        },
        "404217":{
            "cityId":404217,
            "pref":"福岡県",
            "city":"桂川町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｹｲｾﾝﾏﾁ"
        },
        "404471":{
            "cityId":404471,
            "pref":"福岡県",
            "city":"筑前町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾁｸｾﾞﾝﾏﾁ"
        },
        "404489":{
            "cityId":404489,
            "pref":"福岡県",
            "city":"東峰村",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾄｳﾎｳﾑﾗ"
        },
        "405035":{
            "cityId":405035,
            "pref":"福岡県",
            "city":"大刀洗町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾀﾁｱﾗｲﾏﾁ"
        },
        "405221":{
            "cityId":405221,
            "pref":"福岡県",
            "city":"大木町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｵｷﾏﾁ"
        },
        "405442":{
            "cityId":405442,
            "pref":"福岡県",
            "city":"広川町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾋﾛｶﾜﾏﾁ"
        },
        "406015":{
            "cityId":406015,
            "pref":"福岡県",
            "city":"香春町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶﾜﾗﾏﾁ"
        },
        "406023":{
            "cityId":406023,
            "pref":"福岡県",
            "city":"添田町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｿｴﾀﾞﾏﾁ"
        },
        "406040":{
            "cityId":406040,
            "pref":"福岡県",
            "city":"糸田町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｲﾄﾀﾞﾏﾁ"
        },
        "406058":{
            "cityId":406058,
            "pref":"福岡県",
            "city":"川崎町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶﾜｻｷﾏﾁ"
        },
        "406082":{
            "cityId":406082,
            "pref":"福岡県",
            "city":"大任町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｵｵﾄｳﾏﾁ"
        },
        "406091":{
            "cityId":406091,
            "pref":"福岡県",
            "city":"赤村",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｱｶﾑﾗ"
        },
        "406104":{
            "cityId":406104,
            "pref":"福岡県",
            "city":"福智町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾌｸﾁﾏﾁ"
        },
        "406210":{
            "cityId":406210,
            "pref":"福岡県",
            "city":"苅田町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｶﾝﾀﾞﾏﾁ"
        },
        "406252":{
            "cityId":406252,
            "pref":"福岡県",
            "city":"みやこ町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾐﾔｺﾏﾁ"
        },
        "406422":{
            "cityId":406422,
            "pref":"福岡県",
            "city":"吉富町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾖｼﾄﾐﾏﾁ"
        },
        "406465":{
            "cityId":406465,
            "pref":"福岡県",
            "city":"上毛町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ｺｳｹﾞﾏﾁ"
        },
        "406473":{
            "cityId":406473,
            "pref":"福岡県",
            "city":"築上町",
            "prefKana":"ﾌｸｵｶｹﾝ",
            "cityKana":"ﾁｸｼﾞｮｳﾏﾁ"
        }
    },
    "佐賀":{
        "410004":{
            "cityId":410004,
            "pref":"佐賀県",
            "city":"",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":""
        },
        "412015":{
            "cityId":412015,
            "pref":"佐賀県",
            "city":"佐賀市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｻｶﾞｼ"
        },
        "412023":{
            "cityId":412023,
            "pref":"佐賀県",
            "city":"唐津市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｶﾗﾂｼ"
        },
        "412031":{
            "cityId":412031,
            "pref":"佐賀県",
            "city":"鳥栖市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾄｽｼ"
        },
        "412040":{
            "cityId":412040,
            "pref":"佐賀県",
            "city":"多久市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾀｸｼ"
        },
        "412058":{
            "cityId":412058,
            "pref":"佐賀県",
            "city":"伊万里市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｲﾏﾘｼ"
        },
        "412066":{
            "cityId":412066,
            "pref":"佐賀県",
            "city":"武雄市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾀｹｵｼ"
        },
        "412074":{
            "cityId":412074,
            "pref":"佐賀県",
            "city":"鹿島市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｶｼﾏｼ"
        },
        "412082":{
            "cityId":412082,
            "pref":"佐賀県",
            "city":"小城市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｵｷﾞｼ"
        },
        "412091":{
            "cityId":412091,
            "pref":"佐賀県",
            "city":"嬉野市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｳﾚｼﾉｼ"
        },
        "412104":{
            "cityId":412104,
            "pref":"佐賀県",
            "city":"神埼市",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｶﾝｻﾞｷｼ"
        },
        "413275":{
            "cityId":413275,
            "pref":"佐賀県",
            "city":"吉野ヶ里町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾖｼﾉｶﾞﾘﾁｮｳ"
        },
        "413411":{
            "cityId":413411,
            "pref":"佐賀県",
            "city":"基山町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｷﾔﾏﾁｮｳ"
        },
        "413453":{
            "cityId":413453,
            "pref":"佐賀県",
            "city":"上峰町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｶﾐﾐﾈﾁｮｳ"
        },
        "413461":{
            "cityId":413461,
            "pref":"佐賀県",
            "city":"みやき町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾐﾔｷﾁｮｳ"
        },
        "413879":{
            "cityId":413879,
            "pref":"佐賀県",
            "city":"玄海町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｹﾞﾝｶｲﾁｮｳ"
        },
        "414018":{
            "cityId":414018,
            "pref":"佐賀県",
            "city":"有田町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｱﾘﾀﾁｮｳ"
        },
        "414239":{
            "cityId":414239,
            "pref":"佐賀県",
            "city":"大町町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｵｵﾏﾁﾁｮｳ"
        },
        "414247":{
            "cityId":414247,
            "pref":"佐賀県",
            "city":"江北町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｺｳﾎｸﾏﾁ"
        },
        "414255":{
            "cityId":414255,
            "pref":"佐賀県",
            "city":"白石町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ｼﾛｲｼﾁｮｳ"
        },
        "414417":{
            "cityId":414417,
            "pref":"佐賀県",
            "city":"太良町",
            "prefKana":"ｻｶﾞｹﾝ",
            "cityKana":"ﾀﾗﾁｮｳ"
        }
    },
    "長崎":{
        "420000":{
            "cityId":420000,
            "pref":"長崎県",
            "city":"",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":""
        },
        "422011":{
            "cityId":422011,
            "pref":"長崎県",
            "city":"長崎市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾅｶﾞｻｷｼ"
        },
        "422029":{
            "cityId":422029,
            "pref":"長崎県",
            "city":"佐世保市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｻｾﾎﾞｼ"
        },
        "422037":{
            "cityId":422037,
            "pref":"長崎県",
            "city":"島原市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｼﾏﾊﾞﾗｼ"
        },
        "422045":{
            "cityId":422045,
            "pref":"長崎県",
            "city":"諫早市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｲｻﾊﾔｼ"
        },
        "422053":{
            "cityId":422053,
            "pref":"長崎県",
            "city":"大村市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｵｵﾑﾗｼ"
        },
        "422070":{
            "cityId":422070,
            "pref":"長崎県",
            "city":"平戸市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾋﾗﾄﾞｼ"
        },
        "422088":{
            "cityId":422088,
            "pref":"長崎県",
            "city":"松浦市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾏﾂｳﾗｼ"
        },
        "422096":{
            "cityId":422096,
            "pref":"長崎県",
            "city":"対馬市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾂｼﾏｼ"
        },
        "422100":{
            "cityId":422100,
            "pref":"長崎県",
            "city":"壱岐市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｲｷｼ"
        },
        "422118":{
            "cityId":422118,
            "pref":"長崎県",
            "city":"五島市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｺﾞﾄｳｼ"
        },
        "422126":{
            "cityId":422126,
            "pref":"長崎県",
            "city":"西海市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｻｲｶｲｼ"
        },
        "422134":{
            "cityId":422134,
            "pref":"長崎県",
            "city":"雲仙市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｳﾝｾﾞﾝｼ"
        },
        "422142":{
            "cityId":422142,
            "pref":"長崎県",
            "city":"南島原市",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾐﾅﾐｼﾏﾊﾞﾗｼ"
        },
        "423076":{
            "cityId":423076,
            "pref":"長崎県",
            "city":"長与町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾅｶﾞﾖﾁｮｳ"
        },
        "423084":{
            "cityId":423084,
            "pref":"長崎県",
            "city":"時津町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾄｷﾞﾂﾁｮｳ"
        },
        "423211":{
            "cityId":423211,
            "pref":"長崎県",
            "city":"東彼杵町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾋｶﾞｼｿﾉｷﾞﾁｮｳ"
        },
        "423220":{
            "cityId":423220,
            "pref":"長崎県",
            "city":"川棚町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｶﾜﾀﾅﾁｮｳ"
        },
        "423238":{
            "cityId":423238,
            "pref":"長崎県",
            "city":"波佐見町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ﾊｻﾐﾁｮｳ"
        },
        "423831":{
            "cityId":423831,
            "pref":"長崎県",
            "city":"小値賀町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｵﾁﾞｶﾁｮｳ"
        },
        "423912":{
            "cityId":423912,
            "pref":"長崎県",
            "city":"佐々町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｻｻﾞﾁｮｳ"
        },
        "424111":{
            "cityId":424111,
            "pref":"長崎県",
            "city":"新上五島町",
            "prefKana":"ﾅｶﾞｻｷｹﾝ",
            "cityKana":"ｼﾝｶﾐｺﾞﾄｳﾁｮｳ"
        }
    },
    "熊本":{
        "430005":{
            "cityId":430005,
            "pref":"熊本県",
            "city":"",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":""
        },
        "431001":{
            "cityId":431001,
            "pref":"熊本県",
            "city":"熊本市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｸﾏﾓﾄｼ"
        },
        "432024":{
            "cityId":432024,
            "pref":"熊本県",
            "city":"八代市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾔﾂｼﾛｼ"
        },
        "432032":{
            "cityId":432032,
            "pref":"熊本県",
            "city":"人吉市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾋﾄﾖｼｼ"
        },
        "432041":{
            "cityId":432041,
            "pref":"熊本県",
            "city":"荒尾市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｱﾗｵｼ"
        },
        "432059":{
            "cityId":432059,
            "pref":"熊本県",
            "city":"水俣市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐﾅﾏﾀｼ"
        },
        "432067":{
            "cityId":432067,
            "pref":"熊本県",
            "city":"玉名市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾀﾏﾅｼ"
        },
        "432083":{
            "cityId":432083,
            "pref":"熊本県",
            "city":"山鹿市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾔﾏｶﾞｼ"
        },
        "432105":{
            "cityId":432105,
            "pref":"熊本県",
            "city":"菊池市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｷｸﾁｼ"
        },
        "432113":{
            "cityId":432113,
            "pref":"熊本県",
            "city":"宇土市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｳﾄｼ"
        },
        "432121":{
            "cityId":432121,
            "pref":"熊本県",
            "city":"上天草市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｶﾐｱﾏｸｻｼ"
        },
        "432130":{
            "cityId":432130,
            "pref":"熊本県",
            "city":"宇城市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｳｷｼ"
        },
        "432148":{
            "cityId":432148,
            "pref":"熊本県",
            "city":"阿蘇市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｱｿｼ"
        },
        "432156":{
            "cityId":432156,
            "pref":"熊本県",
            "city":"天草市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｱﾏｸｻｼ"
        },
        "432164":{
            "cityId":432164,
            "pref":"熊本県",
            "city":"合志市",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｺｳｼｼ"
        },
        "433489":{
            "cityId":433489,
            "pref":"熊本県",
            "city":"美里町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐｻﾄﾏﾁ"
        },
        "433641":{
            "cityId":433641,
            "pref":"熊本県",
            "city":"玉東町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｷﾞｮｸﾄｳﾏﾁ"
        },
        "433675":{
            "cityId":433675,
            "pref":"熊本県",
            "city":"南関町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾅﾝｶﾝﾏﾁ"
        },
        "433683":{
            "cityId":433683,
            "pref":"熊本県",
            "city":"長洲町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾅｶﾞｽﾏﾁ"
        },
        "433691":{
            "cityId":433691,
            "pref":"熊本県",
            "city":"和水町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾅｺﾞﾐﾏﾁ"
        },
        "434035":{
            "cityId":434035,
            "pref":"熊本県",
            "city":"大津町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｵｵﾂﾞﾏﾁ"
        },
        "434043":{
            "cityId":434043,
            "pref":"熊本県",
            "city":"菊陽町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｷｸﾖｳﾏﾁ"
        },
        "434230":{
            "cityId":434230,
            "pref":"熊本県",
            "city":"南小国町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐﾅﾐｵｸﾞﾆﾏﾁ"
        },
        "434248":{
            "cityId":434248,
            "pref":"熊本県",
            "city":"小国町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｵｸﾞﾆﾏﾁ"
        },
        "434256":{
            "cityId":434256,
            "pref":"熊本県",
            "city":"産山村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｳﾌﾞﾔﾏﾑﾗ"
        },
        "434281":{
            "cityId":434281,
            "pref":"熊本県",
            "city":"高森町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾀｶﾓﾘﾏﾁ"
        },
        "434329":{
            "cityId":434329,
            "pref":"熊本県",
            "city":"西原村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾆｼﾊﾗﾑﾗ"
        },
        "434337":{
            "cityId":434337,
            "pref":"熊本県",
            "city":"南阿蘇村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐﾅﾐｱｿﾑﾗ"
        },
        "434418":{
            "cityId":434418,
            "pref":"熊本県",
            "city":"御船町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐﾌﾈﾏﾁ"
        },
        "434426":{
            "cityId":434426,
            "pref":"熊本県",
            "city":"嘉島町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｶｼﾏﾏﾁ"
        },
        "434434":{
            "cityId":434434,
            "pref":"熊本県",
            "city":"益城町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾏｼｷﾏﾁ"
        },
        "434442":{
            "cityId":434442,
            "pref":"熊本県",
            "city":"甲佐町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｺｳｻﾏﾁ"
        },
        "434477":{
            "cityId":434477,
            "pref":"熊本県",
            "city":"山都町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾔﾏﾄﾁｮｳ"
        },
        "434680":{
            "cityId":434680,
            "pref":"熊本県",
            "city":"氷川町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾋｶﾜﾁｮｳ"
        },
        "434825":{
            "cityId":434825,
            "pref":"熊本県",
            "city":"芦北町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｱｼｷﾀﾏﾁ"
        },
        "434841":{
            "cityId":434841,
            "pref":"熊本県",
            "city":"津奈木町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾂﾅｷﾞﾏﾁ"
        },
        "435015":{
            "cityId":435015,
            "pref":"熊本県",
            "city":"錦町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾆｼｷﾏﾁ"
        },
        "435058":{
            "cityId":435058,
            "pref":"熊本県",
            "city":"多良木町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾀﾗｷﾞﾏﾁ"
        },
        "435066":{
            "cityId":435066,
            "pref":"熊本県",
            "city":"湯前町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾕﾉﾏｴﾏﾁ"
        },
        "435074":{
            "cityId":435074,
            "pref":"熊本県",
            "city":"水上村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾐｽﾞｶﾐﾑﾗ"
        },
        "435104":{
            "cityId":435104,
            "pref":"熊本県",
            "city":"相良村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｻｶﾞﾗﾑﾗ"
        },
        "435112":{
            "cityId":435112,
            "pref":"熊本県",
            "city":"五木村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｲﾂｷﾑﾗ"
        },
        "435121":{
            "cityId":435121,
            "pref":"熊本県",
            "city":"山江村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾔﾏｴﾑﾗ"
        },
        "435139":{
            "cityId":435139,
            "pref":"熊本県",
            "city":"球磨村",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｸﾏﾑﾗ"
        },
        "435147":{
            "cityId":435147,
            "pref":"熊本県",
            "city":"あさぎり町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ｱｻｷﾞﾘﾁｮｳ"
        },
        "435317":{
            "cityId":435317,
            "pref":"熊本県",
            "city":"苓北町",
            "prefKana":"ｸﾏﾓﾄｹﾝ",
            "cityKana":"ﾚｲﾎｸﾏﾁ"
        }
    },
    "大分":{
        "440001":{
            "cityId":440001,
            "pref":"大分県",
            "city":"",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":""
        },
        "442011":{
            "cityId":442011,
            "pref":"大分県",
            "city":"大分市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｵｵｲﾀｼ"
        },
        "442020":{
            "cityId":442020,
            "pref":"大分県",
            "city":"別府市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾍﾞｯﾌﾟｼ"
        },
        "442038":{
            "cityId":442038,
            "pref":"大分県",
            "city":"中津市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾅｶﾂｼ"
        },
        "442046":{
            "cityId":442046,
            "pref":"大分県",
            "city":"日田市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾋﾀｼ"
        },
        "442054":{
            "cityId":442054,
            "pref":"大分県",
            "city":"佐伯市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｻｲｷｼ"
        },
        "442062":{
            "cityId":442062,
            "pref":"大分県",
            "city":"臼杵市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｳｽｷｼ"
        },
        "442071":{
            "cityId":442071,
            "pref":"大分県",
            "city":"津久見市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾂｸﾐｼ"
        },
        "442089":{
            "cityId":442089,
            "pref":"大分県",
            "city":"竹田市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾀｹﾀｼ"
        },
        "442097":{
            "cityId":442097,
            "pref":"大分県",
            "city":"豊後高田市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾌﾞﾝｺﾞﾀｶﾀﾞｼ"
        },
        "442101":{
            "cityId":442101,
            "pref":"大分県",
            "city":"杵築市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｷﾂｷｼ"
        },
        "442119":{
            "cityId":442119,
            "pref":"大分県",
            "city":"宇佐市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｳｻｼ"
        },
        "442127":{
            "cityId":442127,
            "pref":"大分県",
            "city":"豊後大野市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾌﾞﾝｺﾞｵｵﾉｼ"
        },
        "442135":{
            "cityId":442135,
            "pref":"大分県",
            "city":"由布市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾕﾌｼ"
        },
        "442143":{
            "cityId":442143,
            "pref":"大分県",
            "city":"国東市",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｸﾆｻｷｼ"
        },
        "443221":{
            "cityId":443221,
            "pref":"大分県",
            "city":"姫島村",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾋﾒｼﾏﾑﾗ"
        },
        "443417":{
            "cityId":443417,
            "pref":"大分県",
            "city":"日出町",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ﾋｼﾞﾏﾁ"
        },
        "444618":{
            "cityId":444618,
            "pref":"大分県",
            "city":"九重町",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｺｺﾉｴﾏﾁ"
        },
        "444626":{
            "cityId":444626,
            "pref":"大分県",
            "city":"玖珠町",
            "prefKana":"ｵｵｲﾀｹﾝ",
            "cityKana":"ｸｽﾏﾁ"
        }
    },
    "宮崎":{
        "450006":{
            "cityId":450006,
            "pref":"宮崎県",
            "city":"",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":""
        },
        "452017":{
            "cityId":452017,
            "pref":"宮崎県",
            "city":"宮崎市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾐﾔｻﾞｷｼ"
        },
        "452025":{
            "cityId":452025,
            "pref":"宮崎県",
            "city":"都城市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾐﾔｺﾉｼﾞｮｳｼ"
        },
        "452033":{
            "cityId":452033,
            "pref":"宮崎県",
            "city":"延岡市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾉﾍﾞｵｶｼ"
        },
        "452041":{
            "cityId":452041,
            "pref":"宮崎県",
            "city":"日南市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾆﾁﾅﾝｼ"
        },
        "452050":{
            "cityId":452050,
            "pref":"宮崎県",
            "city":"小林市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｺﾊﾞﾔｼｼ"
        },
        "452068":{
            "cityId":452068,
            "pref":"宮崎県",
            "city":"日向市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾋｭｳｶﾞｼ"
        },
        "452076":{
            "cityId":452076,
            "pref":"宮崎県",
            "city":"串間市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｸｼﾏｼ"
        },
        "452084":{
            "cityId":452084,
            "pref":"宮崎県",
            "city":"西都市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｻｲﾄｼ"
        },
        "452092":{
            "cityId":452092,
            "pref":"宮崎県",
            "city":"えびの市",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｴﾋﾞﾉｼ"
        },
        "453412":{
            "cityId":453412,
            "pref":"宮崎県",
            "city":"三股町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾐﾏﾀﾁｮｳ"
        },
        "453617":{
            "cityId":453617,
            "pref":"宮崎県",
            "city":"高原町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾀｶﾊﾙﾁｮｳ"
        },
        "453820":{
            "cityId":453820,
            "pref":"宮崎県",
            "city":"国富町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｸﾆﾄﾐﾁｮｳ"
        },
        "453838":{
            "cityId":453838,
            "pref":"宮崎県",
            "city":"綾町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｱﾔﾁｮｳ"
        },
        "454010":{
            "cityId":454010,
            "pref":"宮崎県",
            "city":"高鍋町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾀｶﾅﾍﾞﾁｮｳ"
        },
        "454028":{
            "cityId":454028,
            "pref":"宮崎県",
            "city":"新富町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｼﾝﾄﾐﾁｮｳ"
        },
        "454036":{
            "cityId":454036,
            "pref":"宮崎県",
            "city":"西米良村",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾆｼﾒﾗｿﾝ"
        },
        "454044":{
            "cityId":454044,
            "pref":"宮崎県",
            "city":"木城町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｷｼﾞｮｳﾁｮｳ"
        },
        "454052":{
            "cityId":454052,
            "pref":"宮崎県",
            "city":"川南町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｶﾜﾐﾅﾐﾁｮｳ"
        },
        "454061":{
            "cityId":454061,
            "pref":"宮崎県",
            "city":"都農町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾂﾉﾁｮｳ"
        },
        "454214":{
            "cityId":454214,
            "pref":"宮崎県",
            "city":"門川町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｶﾄﾞｶﾞﾜﾁｮｳ"
        },
        "454290":{
            "cityId":454290,
            "pref":"宮崎県",
            "city":"諸塚村",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾓﾛﾂｶｿﾝ"
        },
        "454303":{
            "cityId":454303,
            "pref":"宮崎県",
            "city":"椎葉村",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｼｲﾊﾞｿﾝ"
        },
        "454311":{
            "cityId":454311,
            "pref":"宮崎県",
            "city":"美郷町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾐｻﾄﾁｮｳ"
        },
        "454419":{
            "cityId":454419,
            "pref":"宮崎県",
            "city":"高千穂町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾀｶﾁﾎﾁｮｳ"
        },
        "454427":{
            "cityId":454427,
            "pref":"宮崎県",
            "city":"日之影町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ﾋﾉｶｹﾞﾁｮｳ"
        },
        "454435":{
            "cityId":454435,
            "pref":"宮崎県",
            "city":"五ヶ瀬町",
            "prefKana":"ﾐﾔｻﾞｷｹﾝ",
            "cityKana":"ｺﾞｶｾﾁｮｳ"
        }
    },
    "鹿児島":{
        "460001":{
            "cityId":460001,
            "pref":"鹿児島県",
            "city":"",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":""
        },
        "462012":{
            "cityId":462012,
            "pref":"鹿児島県",
            "city":"鹿児島市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｶｺﾞｼﾏｼ"
        },
        "462039":{
            "cityId":462039,
            "pref":"鹿児島県",
            "city":"鹿屋市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｶﾉﾔｼ"
        },
        "462047":{
            "cityId":462047,
            "pref":"鹿児島県",
            "city":"枕崎市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾏｸﾗｻﾞｷｼ"
        },
        "462063":{
            "cityId":462063,
            "pref":"鹿児島県",
            "city":"阿久根市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｱｸﾈｼ"
        },
        "462080":{
            "cityId":462080,
            "pref":"鹿児島県",
            "city":"出水市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｲｽﾞﾐｼ"
        },
        "462101":{
            "cityId":462101,
            "pref":"鹿児島県",
            "city":"指宿市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｲﾌﾞｽｷｼ"
        },
        "462136":{
            "cityId":462136,
            "pref":"鹿児島県",
            "city":"西之表市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾆｼﾉｵﾓﾃｼ"
        },
        "462144":{
            "cityId":462144,
            "pref":"鹿児島県",
            "city":"垂水市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾀﾙﾐｽﾞｼ"
        },
        "462152":{
            "cityId":462152,
            "pref":"鹿児島県",
            "city":"薩摩川内市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｻﾂﾏｾﾝﾀﾞｲｼ"
        },
        "462161":{
            "cityId":462161,
            "pref":"鹿児島県",
            "city":"日置市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾋｵｷｼ"
        },
        "462179":{
            "cityId":462179,
            "pref":"鹿児島県",
            "city":"曽於市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｿｵｼ"
        },
        "462187":{
            "cityId":462187,
            "pref":"鹿児島県",
            "city":"霧島市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｷﾘｼﾏｼ"
        },
        "462195":{
            "cityId":462195,
            "pref":"鹿児島県",
            "city":"いちき串木野市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｲﾁｷｸｼｷﾉｼ"
        },
        "462209":{
            "cityId":462209,
            "pref":"鹿児島県",
            "city":"南さつま市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐｻﾂﾏｼ"
        },
        "462217":{
            "cityId":462217,
            "pref":"鹿児島県",
            "city":"志布志市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｼﾌﾞｼｼ"
        },
        "462225":{
            "cityId":462225,
            "pref":"鹿児島県",
            "city":"奄美市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｱﾏﾐｼ"
        },
        "462233":{
            "cityId":462233,
            "pref":"鹿児島県",
            "city":"南九州市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐｷｭｳｼｭｳｼ"
        },
        "462241":{
            "cityId":462241,
            "pref":"鹿児島県",
            "city":"伊佐市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｲｻｼ"
        },
        "462250":{
            "cityId":462250,
            "pref":"鹿児島県",
            "city":"姶良市",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｱｲﾗｼ"
        },
        "463035":{
            "cityId":463035,
            "pref":"鹿児島県",
            "city":"三島村",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾐｼﾏﾑﾗ"
        },
        "463043":{
            "cityId":463043,
            "pref":"鹿児島県",
            "city":"十島村",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾄｼﾏﾑﾗ"
        },
        "463922":{
            "cityId":463922,
            "pref":"鹿児島県",
            "city":"さつま町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｻﾂﾏﾁｮｳ"
        },
        "464040":{
            "cityId":464040,
            "pref":"鹿児島県",
            "city":"長島町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾅｶﾞｼﾏﾁｮｳ"
        },
        "464520":{
            "cityId":464520,
            "pref":"鹿児島県",
            "city":"湧水町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾕｳｽｲﾁｮｳ"
        },
        "464686":{
            "cityId":464686,
            "pref":"鹿児島県",
            "city":"大崎町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｵｵｻｷﾁｮｳ"
        },
        "464821":{
            "cityId":464821,
            "pref":"鹿児島県",
            "city":"東串良町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾋｶﾞｼｸｼﾗﾁｮｳ"
        },
        "464902":{
            "cityId":464902,
            "pref":"鹿児島県",
            "city":"錦江町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｷﾝｺｳﾁｮｳ"
        },
        "464911":{
            "cityId":464911,
            "pref":"鹿児島県",
            "city":"南大隅町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐｵｵｽﾐﾁｮｳ"
        },
        "464929":{
            "cityId":464929,
            "pref":"鹿児島県",
            "city":"肝付町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｷﾓﾂｷﾁｮｳ"
        },
        "465011":{
            "cityId":465011,
            "pref":"鹿児島県",
            "city":"中種子町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾅｶﾀﾈﾁｮｳ"
        },
        "465020":{
            "cityId":465020,
            "pref":"鹿児島県",
            "city":"南種子町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾐﾅﾐﾀﾈﾁｮｳ"
        },
        "465054":{
            "cityId":465054,
            "pref":"鹿児島県",
            "city":"屋久島町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾔｸｼﾏﾁｮｳ"
        },
        "465232":{
            "cityId":465232,
            "pref":"鹿児島県",
            "city":"大和村",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾔﾏﾄｿﾝ"
        },
        "465241":{
            "cityId":465241,
            "pref":"鹿児島県",
            "city":"宇検村",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｳｹﾝｿﾝ"
        },
        "465259":{
            "cityId":465259,
            "pref":"鹿児島県",
            "city":"瀬戸内町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｾﾄｳﾁﾁｮｳ"
        },
        "465275":{
            "cityId":465275,
            "pref":"鹿児島県",
            "city":"龍郷町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾀﾂｺﾞｳﾁｮｳ"
        },
        "465291":{
            "cityId":465291,
            "pref":"鹿児島県",
            "city":"喜界町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｷｶｲﾁｮｳ"
        },
        "465305":{
            "cityId":465305,
            "pref":"鹿児島県",
            "city":"徳之島町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾄｸﾉｼﾏﾁｮｳ"
        },
        "465313":{
            "cityId":465313,
            "pref":"鹿児島県",
            "city":"天城町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｱﾏｷﾞﾁｮｳ"
        },
        "465321":{
            "cityId":465321,
            "pref":"鹿児島県",
            "city":"伊仙町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ｲｾﾝﾁｮｳ"
        },
        "465330":{
            "cityId":465330,
            "pref":"鹿児島県",
            "city":"和泊町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾜﾄﾞﾏﾘﾁｮｳ"
        },
        "465348":{
            "cityId":465348,
            "pref":"鹿児島県",
            "city":"知名町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾁﾅﾁｮｳ"
        },
        "465356":{
            "cityId":465356,
            "pref":"鹿児島県",
            "city":"与論町",
            "prefKana":"ｶｺﾞｼﾏｹﾝ",
            "cityKana":"ﾖﾛﾝﾁｮｳ"
        }
    },
    "沖縄":{
        "470007":{
            "cityId":470007,
            "pref":"沖縄県",
            "city":"",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":""
        },
        "472018":{
            "cityId":472018,
            "pref":"沖縄県",
            "city":"那覇市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾅﾊｼ"
        },
        "472051":{
            "cityId":472051,
            "pref":"沖縄県",
            "city":"宜野湾市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｷﾞﾉﾜﾝｼ"
        },
        "472077":{
            "cityId":472077,
            "pref":"沖縄県",
            "city":"石垣市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｲｼｶﾞｷｼ"
        },
        "472085":{
            "cityId":472085,
            "pref":"沖縄県",
            "city":"浦添市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｳﾗｿｴｼ"
        },
        "472093":{
            "cityId":472093,
            "pref":"沖縄県",
            "city":"名護市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾅｺﾞｼ"
        },
        "472107":{
            "cityId":472107,
            "pref":"沖縄県",
            "city":"糸満市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｲﾄﾏﾝｼ"
        },
        "472115":{
            "cityId":472115,
            "pref":"沖縄県",
            "city":"沖縄市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｵｷﾅﾜｼ"
        },
        "472123":{
            "cityId":472123,
            "pref":"沖縄県",
            "city":"豊見城市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾄﾐｸﾞｽｸｼ"
        },
        "472131":{
            "cityId":472131,
            "pref":"沖縄県",
            "city":"うるま市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｳﾙﾏｼ"
        },
        "472140":{
            "cityId":472140,
            "pref":"沖縄県",
            "city":"宮古島市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾐﾔｺｼﾞﾏｼ"
        },
        "472158":{
            "cityId":472158,
            "pref":"沖縄県",
            "city":"南城市",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾅﾝｼﾞｮｳｼ"
        },
        "473014":{
            "cityId":473014,
            "pref":"沖縄県",
            "city":"国頭村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｸﾆｶﾞﾐｿﾝ"
        },
        "473022":{
            "cityId":473022,
            "pref":"沖縄県",
            "city":"大宜味村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｵｵｷﾞﾐｿﾝ"
        },
        "473031":{
            "cityId":473031,
            "pref":"沖縄県",
            "city":"東村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾋｶﾞｼｿﾝ"
        },
        "473065":{
            "cityId":473065,
            "pref":"沖縄県",
            "city":"今帰仁村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾅｷｼﾞﾝｿﾝ"
        },
        "473081":{
            "cityId":473081,
            "pref":"沖縄県",
            "city":"本部町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾓﾄﾌﾞﾁｮｳ"
        },
        "473111":{
            "cityId":473111,
            "pref":"沖縄県",
            "city":"恩納村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｵﾝﾅｿﾝ"
        },
        "473138":{
            "cityId":473138,
            "pref":"沖縄県",
            "city":"宜野座村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｷﾞﾉｻﾞｿﾝ"
        },
        "473146":{
            "cityId":473146,
            "pref":"沖縄県",
            "city":"金武町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｷﾝﾁｮｳ"
        },
        "473154":{
            "cityId":473154,
            "pref":"沖縄県",
            "city":"伊江村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｲｴｿﾝ"
        },
        "473243":{
            "cityId":473243,
            "pref":"沖縄県",
            "city":"読谷村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾖﾐﾀﾝｿﾝ"
        },
        "473251":{
            "cityId":473251,
            "pref":"沖縄県",
            "city":"嘉手納町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｶﾃﾞﾅﾁｮｳ"
        },
        "473260":{
            "cityId":473260,
            "pref":"沖縄県",
            "city":"北谷町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾁﾔﾀﾝﾁｮｳ"
        },
        "473278":{
            "cityId":473278,
            "pref":"沖縄県",
            "city":"北中城村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｷﾀﾅｶｸﾞｽｸｿﾝ"
        },
        "473286":{
            "cityId":473286,
            "pref":"沖縄県",
            "city":"中城村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾅｶｸﾞｽｸｿﾝ"
        },
        "473294":{
            "cityId":473294,
            "pref":"沖縄県",
            "city":"西原町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾆｼﾊﾗﾁｮｳ"
        },
        "473481":{
            "cityId":473481,
            "pref":"沖縄県",
            "city":"与那原町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾖﾅﾊﾞﾙﾁｮｳ"
        },
        "473502":{
            "cityId":473502,
            "pref":"沖縄県",
            "city":"南風原町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾊｴﾊﾞﾙﾁｮｳ"
        },
        "473537":{
            "cityId":473537,
            "pref":"沖縄県",
            "city":"渡嘉敷村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾄｶｼｷｿﾝ"
        },
        "473545":{
            "cityId":473545,
            "pref":"沖縄県",
            "city":"座間味村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｻﾞﾏﾐｿﾝ"
        },
        "473553":{
            "cityId":473553,
            "pref":"沖縄県",
            "city":"粟国村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｱｸﾞﾆｿﾝ"
        },
        "473561":{
            "cityId":473561,
            "pref":"沖縄県",
            "city":"渡名喜村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾄﾅｷｿﾝ"
        },
        "473570":{
            "cityId":473570,
            "pref":"沖縄県",
            "city":"南大東村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾐﾅﾐﾀﾞｲﾄｳｿﾝ"
        },
        "473588":{
            "cityId":473588,
            "pref":"沖縄県",
            "city":"北大東村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｷﾀﾀﾞｲﾄｳｿﾝ"
        },
        "473596":{
            "cityId":473596,
            "pref":"沖縄県",
            "city":"伊平屋村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｲﾍﾔｿﾝ"
        },
        "473600":{
            "cityId":473600,
            "pref":"沖縄県",
            "city":"伊是名村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｲｾﾞﾅｿﾝ"
        },
        "473618":{
            "cityId":473618,
            "pref":"沖縄県",
            "city":"久米島町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ｸﾒｼﾞﾏﾁｮｳ"
        },
        "473626":{
            "cityId":473626,
            "pref":"沖縄県",
            "city":"八重瀬町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾔｴｾﾁｮｳ"
        },
        "473758":{
            "cityId":473758,
            "pref":"沖縄県",
            "city":"多良間村",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾀﾗﾏｿﾝ"
        },
        "473812":{
            "cityId":473812,
            "pref":"沖縄県",
            "city":"竹富町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾀｹﾄﾐﾁｮｳ"
        },
        "473821":{
            "cityId":473821,
            "pref":"沖縄県",
            "city":"与那国町",
            "prefKana":"ｵｷﾅﾜｹﾝ",
            "cityKana":"ﾖﾅｸﾞﾆﾁｮｳ"
        }
    }
}