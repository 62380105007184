import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Shop } from '../entity/Shop';
import { GeneralModalComponentConfig } from '../reducers/generalInfoReducer';
import ShopDetailModal from './ShopDetailModal';
//%%import entry%%

//modules

//elements
import styles from '../css/ShopRow.module.css'
import ShopHeader from './ShopHeader';
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  shop: Shop;
  //%%props entry%%
}

class ShopRow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  showModal = () => {
    const data = {
      modalContentComponent: (
        <ShopDetailModal shop={this.props.shop} />
      ),
      modalHeaderComponent: (
        <ShopHeader shop={this.props.shop} />
      )
    } as GeneralModalComponentConfig;
    this.props.dispatch({ type: ActionType.OpenModal, data: data });
  }
  showDetail = (ev: any) => {
    this.showModal();
  }
  render() {
    return (
      <>
        <tr className={styles.row} onClick={this.showDetail}>
          <td><img width="80" src={this.props.shop.imageUrl} alt='' /></td>
          <td>
            <b>{this.props.shop.name}</b><br />
            {this.renderTime()}
            {this.renderMenu()}
            {this.props.shop.description.split('\n').map(((m,i) => <span key={i}>{m}<br/></span>))}<br />
            <div className={styles.distance}>
            {/* {this.renderShopLink()} */}
            </div>
            <div className={styles.distance}>
            {this.props.shop.distanceFromHere ? '現在地から' + this.props.shop.distanceFromHere + 'm' : ''}
            </div>
          </td>
        </tr>
        {/* %%child component entry%% --> */}
      </>
    );
  }
  renderTime = () => {
    if (this.props.shop.toGoTimeStart && this.props.shop.toGoTimeEnd) {
      return (
        <div className={styles.takeoutTime}>
          テイクアウト受付時間 { this.props.shop.toGoTimeStart} -{ this.props.shop.toGoTimeEnd} < br />
        </div>
      );
    }
  }
  renderShopLink = () => {
    if (this.props.shop.url){
      return (
        <a rel="noopener noreferrer" style={{float: 'right'}} target="_blank" href={this.props.shop.url}>店舗のページへ</a>
      )
    }
  }
  renderMenu = () => {
    
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopRow);
