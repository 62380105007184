import { Payload } from "../actions/asyncActionCreator";

export enum ErrorDataHandlingAction {
    ShowPopup, HidePopup
}

export class ErrorData {
    errorHandlingAction: ErrorDataHandlingAction;
    message: string;
    payload: Payload<any, any>;
}
