import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import { GeolocatedProps, geolocated } from "react-geolocated";

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history?: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  appState?: State;
}

class GeoManeger extends React.Component<IProps & GeolocatedProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    setInterval(() => {
      if (this.props.isGeolocationAvailable){
        this.props.dispatch({type: ActionType.SaveGeoLocation, data: this.props.coords})
      }
    }, 1000);
  }
  render() {
    return ''
    // return !this.props.isGeolocationAvailable ? (
    //   <div>Your browser does not support Geolocation</div>
    //   ) : !this.props.isGeolocationEnabled ? (
    //       <div>Geolocation is not enabled</div>
    //   ) : this.props.coords ? (
    //     <div>
    //       <table>
    //           <tbody>
    //               <tr>
    //                   <td>latitude</td>
    //                   <td>{this.props.coords.latitude}</td>
    //               </tr>
    //               <tr>
    //                   <td>longitude</td>
    //                   <td>{this.props.coords.longitude}</td>
    //               </tr>
    //           </tbody>
    //       </table>
    //       </div>
    //   ) : (
    //       <div>Getting the location data&hellip; </div>
    //   );
    
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default geolocated()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(GeoManeger));
