import { ErrorHandleAction, Payload, ChainAction, ErrorType, buildJSONRPCClient } from "../asyncActionCreator";
import store from "../../store/configureStore";
import { LoginDataAction } from "../LoginDataAction";
import { createShowErrorPopUpAction } from "../errorDataActionCreator";
import { restoreLoginDataFromLocalStorage } from "../../models/LoginData";
import { JwtRPC } from "../../rpc-clients/JwtRPC";

export async function obtainJWTFromRefreshToken() {
  const loginData = restoreLoginDataFromLocalStorage();
  let { refreshToken } = loginData;

  const newToken = await buildJSONRPCClient(JwtRPC).obtainJWTFromRefreshToken({refreshToken});
  newToken.refreshToken = refreshToken;
  return newToken;
};

export async function universalErrorHandler<T, U>(payload: Payload<T, U|ErrorType>): Promise<ErrorHandleAction<U|ErrorType>> {
  switch(payload.error.code) {
    case -9999:
      return {
        type: ChainAction.Return,
        action: createShowErrorPopUpAction(payload)
      };
    case -32007:
      localStorage.clear();
      window.location.reload();
      break;
    case -32003:
      localStorage.clear();
      window.location.reload();
      break;
    case -32002:
      const newToken = await obtainJWTFromRefreshToken();
      console.log(newToken);
      store.dispatch({
        type: LoginDataAction.UpdateCredential,
        data: newToken
      });

      const loginData = restoreLoginDataFromLocalStorage();
      // console.log(loginData);
      if (loginData){
        console.log(loginData.jwt);
      }
      return {
        type: ChainAction.Return,
        action: await payload.retryAsyncActionCreator()
      };
  }

  return {
    type: ChainAction.ChainNext
  };
}