export class GeneralInfo {
    showModal: boolean;
    modalContent?: string;
    modalHeader?: string;
    modalContentComponent?: React.Component;
    modalHeaderComponent?: React.Component;
    nextFunction?: (...arg1: any) => any;
    modalNextResult?: any;
    geoApproved: boolean = false;
    //%%property entry%%
}
