import { ActionType } from "../constants/ActionType";
import { ErrorDataHandlingAction } from "../modules/ErrorData";

export function createShowErrorPopUpAction(payload: any) {
    return {
        type: ActionType.SaveErrorDataAction,
        data: {
            errorHandlingAction: ErrorDataHandlingAction.ShowPopup,
            payload: payload
        }
    }
}