import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { ShopRPC, ShopListInput } from '../rpc-clients/ShopRPC';
import { GeneralModalComponentConfig } from '../reducers/generalInfoReducer';
import ShopRow from './ShopRow';

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class AdminShopListPage extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    if (!this.props.appState.userInfo.hasLoggedIn){
      this.props.history.push('/amb/login');
    }
    await this.loadShopRPC_shopList();
  }
  loadShopRPC_shopList = async () => {
    let rpc = await genRpc(ShopRPC);
    const input = new ShopListInput();
    input.forAdmin = true;
    const items = await rpc.shopList(input);
    this.props.dispatch({ type: ActionType.LoadShops, data: items });
  }
  showModal = (event: any) => {
    const data = {
      modalTitle: '',
      modalContentComponent: (
        <></>
      ),
    } as GeneralModalComponentConfig;
    this.props.dispatch({type: ActionType.OpenModal, data: data});
  }
  modalCallback = () => {
    this.loads()
  }
  loads = () => {

  }

  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
 showRegisterModal = () => {
   
 }
  render() {

    return (
    <>
      <Header></Header>
      <div>
        <button onClick={this.showRegisterModal}>登録</button>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>店舗名</th>
              <th>住所</th>
            </tr>
          </thead>
          <tbody>
            {this.props.appState.shopsInfo.shops.map(shop => {
              return (
                <ShopRow key={shop.id} shop={shop}></ShopRow>
              );
            })}
          </tbody>
        </table>

    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminShopListPage);
