export enum ActionType {
    SaveErrorDataAction= "SaveErrorDataAction",

    //Application General
    OpenModal = "OpenModal",
    CloseModal = "CloseModal",
    UpdateModal = "UpdateModal",
    
    LoadShops = "LoadShops",
    UpdateShop = "UpdateShop",
    GeoApprove = "GeoApprove",
    Login = "Login",
    Logout = "Logout",
    IssueLoginLink = "IssueLoginLink",
    SaveGeoLocation = "SaveGeoLocation",
    SaveShopFilter = "SaveShopFilter",
    CalcShopDistance = "CalcShopDistance",
    EditShop = "EditShop",
    SaveShop = "SaveShop",
    //%%actionType entry%%
    d="dummy"
};
