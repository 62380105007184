import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../../constants/ActionType';
// import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../../reducers';
import { Dispatch } from 'redux';

//modules

//elements

import { Navbar, NavLink, Modal, Nav } from 'react-bootstrap';
import GeoManeger from '../GeoManeger';

interface IState {
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  // applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  //actionMethod?: (actionType: ActionType, data: OooooInfo) => any;
}

class Header extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ [event.target.name]: event.target.value } as Pick<IState, keyof IState>);
  }
  async componentDidMount() {
  }
  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    return false;
  }
  renderModalHeaderContent = () => {
    if (this.props.appState.generalInfo.modalHeaderComponent && (this.props.appState.generalInfo.modalHeaderComponent as any)['$$typeof']) {
      if ((this.props.appState.generalInfo.modalHeaderComponent as any)['$$typeof'].toString() === 'Symbol(react.element)') {
        return this.props.appState.generalInfo.modalHeaderComponent;
      }
    } else if (this.props.appState.generalInfo.modalHeader) {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.props.appState.generalInfo.modalHeader }}></div>
      )
    } else {
      return <></>
    }
  }
  renderModalBodyContent = () => {
    if (this.props.appState.generalInfo.modalContentComponent && (this.props.appState.generalInfo.modalContentComponent as any)['$$typeof']) {
      if ((this.props.appState.generalInfo.modalContentComponent as any)['$$typeof'].toString() === 'Symbol(react.element)') {
        return this.props.appState.generalInfo.modalContentComponent;
      }
    } else if (this.props.appState.generalInfo.modalContent) {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.props.appState.generalInfo.modalContent }}></div>
      )
    } else {
      return <></>
    }
  }
  clickPrimaryButton = () => {
    if (this.props.appState.generalInfo.nextFunction) {
      this.props.appState.generalInfo.nextFunction()
    } else {
      this.props.dispatch({ type: ActionType.CloseModal });
    }
  }
  isActive = (tabIndex: number) => {
    const urlsForTabs = [
      [
        '/area'
      ],
      [
        '/shop_list'
      ],
      [
        '/amb'
      ],
    ];
    let result = false;
    urlsForTabs[tabIndex].forEach((url, index) => {
      if (window.location.pathname.indexOf(url) === 0) {
        result = true;
      }
    });
    return result;
  }
  render() {
    return (
      <>
        {this.renderMain()}
        {this.renderGeoManager()}
      </>
    )
  }
  renderGeoManager = () => {
    // if (this.props.appState.generalInfo.geoApproved) {
      return (
        <GeoManeger></GeoManeger>
      )
    // }
  }
  renderMain() {
    return (
      <>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/">テイクアウツ!</Navbar.Brand>
        </Navbar>
        <Nav variant="tabs" >
          <Nav.Item>
            <NavLink href="/area_pref" active={this.isActive(0)}>地域選択</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink href="/shop_list" active={this.isActive(1)}>店舗一覧</NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink href="/amb/login" active={this.isActive(2)}>アンバサダー</NavLink>
          </Nav.Item>
        </Nav>
        <Modal dialogClassName="modal-90w" show={this.props.appState.generalInfo.showModal} onHide={() => this.props.dispatch({ type: ActionType.CloseModal })}>
          <Modal.Header closeButton>
            {this.renderModalHeaderContent()}
          </Modal.Header>
          <Modal.Body>
            {this.renderModalBodyContent()}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //actionMethod: (actionType: ActionType, data: OooooInfo) => {
    //  dispatch({ type: ActionType., data });
    //},
    // applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
