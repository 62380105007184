

export class LoginUser {
    id: number;

    public email: string;

    public login_token: string;

    public dt_login_token_expired?: Date | null;

    public password: string;

    public lastname: string;

    public firstname: string;

    public createdAt: Date;

    public updatedAt: Date;

}
