import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { ShopsInfo } from "../modules/ShopsInfo";
import getDistance from 'geolib/es/getDistance';
import { japaneseCityInfoByCityId } from "../constants/japaneseCities";

const defaultState = (): ShopsInfo => (new ShopsInfo());
export const shopsInfoReducer = (
  state = defaultState(),
  action: GenericAction<string, any>
) => {
  let newState = state;
  const data = action.data;
  switch (action.type) {
    case ActionType.LoadShops:
      newState.shops = data;
      newState.filteredShops = data;
      return { ...newState }
    case ActionType.SaveShopFilter:
      newState.shopFilter = data;
      const currentTime = parseInt(new Date().getHours() + '' + new Date().getMinutes());
      newState.filteredShops = newState.shops.filter(shop => {
        const currentAvailable = !newState.shopFilter.currentAvailable || (shop.toGoTimeStart <= currentTime && currentTime <= shop.toGoTimeEnd)
        const deliveryAvailable = !newState.shopFilter.deliveryAvailable || shop.deliveryAvailable;
        const inCity = !newState.shopFilter.cityId || newState.shopFilter.cityId === shop.cityId || shop.address.indexOf(japaneseCityInfoByCityId(newState.shopFilter.cityId).city) > -1;
        return currentAvailable && deliveryAvailable && inCity
      });
      return { ...newState }
    case ActionType.CalcShopDistance:
      newState.filteredShops = newState.filteredShops.map(shop => {
        if (data){
          shop.distanceFromHere = getDistance(
            { latitude: parseFloat(shop.latitude), longitude: parseFloat(shop.longitude) },
            { latitude: data.latitude, longitude: data.longitude }
          );
        }
        return shop; 
      });
      return { ...newState }
    case ActionType.SaveShop:
      newState.shops = newState.shops.map(shop => {
        if (shop.id === data.id){
          shop = data;
        }
        return shop;
      });
      
      return { ...newState }
    //%%actionType entry%%
    default:
      return state;
  }
};
