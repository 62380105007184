import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
//import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Button } from 'react-bootstrap';
import { GeneralModalComponentConfig } from '../reducers/generalInfoReducer';
import { Shop } from '../entity/Shop';
//%%import entry%%

//modules

//elements
import styles from '../css/ShopHeader.module.css';
import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { ShopRPC } from '../rpc-clients/ShopRPC';
import ShopDetailModal from './ShopDetailModal';
import ShopHeader from './ShopHeader';
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  shop: Shop;
  //%%props entry%%
}

class ShopEditHeader extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  save = async () => {
    this.props.dispatch({ type: ActionType.SaveShop, data: this.props.appState.shopInfo });
    const rpc = await genRpc(ShopRPC);
    await rpc.saveShop(this.props.appState.shopInfo);

    this.toDetail();
  }
  toDetail = () => {
    this.props.dispatch({ type: ActionType.CloseModal });
    const data = {
      modalContentComponent: (
        <ShopDetailModal shop={this.props.shop} />
      ),
      modalHeaderComponent: (
        <ShopHeader shop={this.props.shop} />
      )
    } as GeneralModalComponentConfig;
    this.props.dispatch({ type: ActionType.OpenModal, data: data });

  }
  render() {
    return (
      <div className={styles.root}>
        {this.props.shop.name}
        {this.renderSaveButton()}
        {/* %%child component entry%% --> */}
      </div>
    );
  }

  renderSaveButton = () => {
    if (this.props.appState.userInfo.hasLoggedIn) {
      return (
        <div className={styles.btnSave}><Button size="sm" variant="success" onClick={this.save}>保存</Button></div>
      );
    }
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopEditHeader);
