import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { ErrorData, ErrorDataHandlingAction } from "../modules/ErrorData";

const createErrorData = (): ErrorData => ({
  errorHandlingAction: ErrorDataHandlingAction.HidePopup,
  message: '',
  payload: null
});

export const errorDataReducer = (
  state = createErrorData(),
  action: GenericAction<string, ErrorData>
) => {
  switch (action.type) {
    case ActionType.SaveErrorDataAction:
      console.log('errorDataReducer');
      console.log(state);
      let data = action.data;
      return { ...state, ...data };
    default:
      return state;
  }
};
