
import { GeneralUser } from "../entity/GeneralUser";
import { GeoLocation } from "../entity/GeoLocation";
//%%import entry%%
export class UserInfo {
  hasLoggedIn: boolean = false;
  loginEmail: string = '';
  user: GeneralUser;
  geoLocation: GeoLocation;
  //%%property entry%%
}
