import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { Button } from 'react-bootstrap';

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class MainPage extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    this.props.dispatch({type: ActionType.GeoApprove, data: true})
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  searchHere = () => {
    this.props.dispatch({type: ActionType.GeoApprove, data: true})
    this.props.history.push('/shop_list');
  }
  render() {
    return (
    <>
      <Header></Header>
      <div style={{width: '100%', textAlign: 'center', marginTop: '50px'}}>
        <Button variant="success" onClick={this.searchHere}>現在地から選択</Button>
        <Button style={{margin: '0 0 0 30px'}} variant="info" onClick={(e: any) => {this.props.history.push('/area_pref')}}>地域を選ぶ</Button>
    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPage);
