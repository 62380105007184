import 'reflect-metadata';
import * as JSONRPC from 'jsonrpc-compiler/lib/core/jsonrpc';
import {plainToClass} from 'class-transformer';

import { Shop } from "../entity/Shop";
//%%import entry%%

export class Result {
  public success: boolean;
  public message?: string;
  public id?: number;
}

export class ShopListInput {
  public aroundHere?: boolean;
  public page?: number;
  public forAdmin?: boolean;
}



export class ShopRPC {
  constructor(public endpoint: string, public requestHeaders: {[key: string]: string} = {}) {}
  
  
  async shopList(input: ShopListInput, timeout: number = 15000): Promise<Shop[]> {
  
  
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ShopRPC.shopList", input);
  
    return new Promise<Shop[]>((resolve, reject) => {
      function reqListener () {
        if(oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: '現在メンテナンス中です。' })
        }
        else if(oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error);
          } catch(error) {
            return reject(oReq.responseText);
          }
        }
        const obj = JSON.parse(oReq.responseText);
        if(obj.result) {
          const out = plainToClass(Shop, obj.result) as any;
          
          resolve(out);
          
        }
        else if(obj.error) {
          reject(obj.error);
        } else {
          resolve();
        }
      }

      function reqErrorListener () {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: '通信に失敗しました。' })
        }
        else {
          // 通信エラー
          reject({ code: -9999, message: 'サーバーとの通信に失敗しました。' })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: '接続がタイムアウトしました。' })
      }

      var oReq = new XMLHttpRequest();
      oReq.addEventListener("load", reqListener);
      oReq.addEventListener("error", reqErrorListener);
      oReq.addEventListener("timeout", reqTimeoutListener);
      oReq.open("POST", this.endpoint+"/jsonrpc");
      oReq.timeout = timeout;
      oReq.setRequestHeader("Content-Type", "application/json");
      for(let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key]);
      }
      oReq.send(jrpcBody.toString());
    });
  }
  
  
  async saveShop(input: Shop, timeout: number = 15000): Promise<Result> {
  
  
    const jrpcBody = new JSONRPC.JSORPCV2Request(1, "ShopRPC.saveShop", input);
  
    return new Promise<Result>((resolve, reject) => {
      function reqListener () {
        if(oReq.status === 503) {
          // メンテナンス
          reject({ code: -9996, message: '現在メンテナンス中です。' })
        }
        else if(oReq.status !== 201) {
          try {
            return reject(JSON.parse(oReq.responseText).error);
          } catch(error) {
            return reject(oReq.responseText);
          }
        }
        const obj = JSON.parse(oReq.responseText);
        if(obj.result) {
          const out = plainToClass(Result, obj.result) as any;
          
          resolve((out)[0] ? (out)[0] : out);
          
        }
        else if(obj.error) {
          reject(obj.error);
        } else {
          resolve();
        }
      }

      function reqErrorListener () {
        if (oReq.status > 0) {
          // サーバーエラー
          reject({ code: -9998, message: '通信に失敗しました。' })
        }
        else {
          // 通信エラー
          reject({ code: -9999, message: 'サーバーとの通信に失敗しました。' })
        }
      }

      function reqTimeoutListener() {
        reject({ code: -9997, message: '接続がタイムアウトしました。' })
      }

      var oReq = new XMLHttpRequest();
      oReq.addEventListener("load", reqListener);
      oReq.addEventListener("error", reqErrorListener);
      oReq.addEventListener("timeout", reqTimeoutListener);
      oReq.open("POST", this.endpoint+"/jsonrpc");
      oReq.timeout = timeout;
      oReq.setRequestHeader("Content-Type", "application/json");
      for(let key in this.requestHeaders) {
        oReq.setRequestHeader(key, this.requestHeaders[key]);
      }
      oReq.send(jrpcBody.toString());
    });
  }
  
}
