import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
//import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Form, Card } from "react-bootstrap";
import { ShopFilter } from '../entity/ShopFilter';
//%%import entry%%

//modules

//elements
import styles from '../css/SearchBox.module.css';
import { genres } from '../constants/genres';
//%%element import entry%%

interface IState {
  genre?: string
  currentAvailable?: string
  deliveryAvailable?: string
  opened: boolean;
  //%%component state entry%%
}
interface IProps {
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  //%%props entry%%
}

class SearchBox extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      opened: false
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  handleChange = (ev: any) => {
    let shopFilter = this.props.appState.shopsInfo.shopFilter;
    if (!shopFilter) {
      shopFilter = new ShopFilter();
    }
    let value = ev.target.value;
    if (ev.target.type === "checkbox") {
      value = ev.target.checked;
    }
    (shopFilter as any)[ev.target.name] = value;

    this.props.dispatch({ type: ActionType.SaveShopFilter, data: shopFilter });
  }
  toggle = () => {
    this.setState({ opened: !this.state.opened });
  }
  render() {
    return (
      <Card className={styles.root}>
        <Card.Header onClick={this.toggle}>条件を指定する
          <span className={styles.header_after}>{this.state.opened ? 'ー' : '＋'}</span>
        </Card.Header>
        {this.renderForms()}
        {/* %%child component entry%% --> */}
      </Card>
    );
  }
  renderForms = () => {
    return (
      <Card.Body className={styles.accordion + ' ' + (this.state.opened ? '' : styles.accordionClosed)}>
        <Form.Group controlId="form.genre">
          <Form.Label>ジャンル</Form.Label>
          <Form.Control as="select" placeholder="" name="genre" value={this.state.genre} onChange={this.handleChange}>
            <option value="">ジャンルで絞り込み</option>
            {Object.keys(genres).map(genreId => {
              return (
                <><option>{(genres as any)[genreId]}</option></>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="form.currentAvailable">
          <Form.Check inline placeholder="" name="currentAvailable" value={this.state.currentAvailable} label="今注文できるお店に絞る" onChange={this.handleChange} />
        </Form.Group>
        <Form.Group controlId="form.deliveryAvailable">
          <Form.Check inline placeholder="" name="deliveryAvailable" value={this.state.deliveryAvailable} label="配達可" onChange={this.handleChange} />
        </Form.Group>
      </Card.Body>
    )
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBox);
