import { applyMiddleware, createStore, Store } from "redux";
import reduxThunk from "redux-thunk";
import { state, State } from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { persistReducer, persistStore } from 'redux-persist'
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "loginData",
    "generalInfo",
    "userInfo",
  ]
}
const persistedReducer = persistReducer(persistConfig, state)

const store: Store<State> = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

export const persistor = persistStore(store)
export default store