
import { Menu } from "../entity/Menu";

export class Shop {
    id: number;

    public name: string;

    public prefId?: number;

    public seqNo?: number;

    public cityId?: string;

    public tel?: string;

    public latitude?: string;

    public longitude?: string;

    public toGoTimeStart?: number;

    public toGoTimeEnd?: number;

    public description?: string;

    public uberEatsUrl?: string;

    public address?: string;

    public demaeKanUrl?: string;

    public toGoAvailable?: boolean;

    public deliveryAvailable?: boolean;

    public menuStr?: string;

    public distanceFromHere?: number;

    public url?: string;

    public reservationRequired?: boolean;

    public imageUrl?: string;

    public menus?: Menu[];

    public createdAt: Date;

    public updatedAt: Date;

}
