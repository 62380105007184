import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { japaneseDistricts, japanesePrefectures } from '../constants/japaneseCities';
import { Accordion, Card } from 'react-bootstrap';

//elements
import styles from '../css/area_list.module.css';
//%%element import entry%%

interface IState {
  activeDistrict?: string;
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class AreaPrefPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  selectDistrict = (ev: any) => {
    if (this.state.activeDistrict === ev.target.dataset.district){
      this.setState({activeDistrict: undefined});
    }else{
      this.setState({activeDistrict: ev.target.dataset.district});
    }
  }
  selectPref = (ev: any) => {
    this.props.history.push('/area_city/' + ev.target.dataset.pref_index)
  }
  render() {
    return (
      <>
        <Header></Header>
        <div>
          <Accordion defaultActiveKey="1">
              {Object.keys(japaneseDistricts).map(district => {
                return (
                  <Card key={district}>
                    <Accordion.Toggle className={styles.mainItem} as={Card.Header} variant="link" eventKey={district} onClick={this.selectDistrict} data-district={district}>
                      {district}
                      <span className={styles.mainItem_after}>{this.state.activeDistrict === district ? 'ー' : '＋'}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={district}>
                      <Card.Body>
                        <ul>
                          {(japaneseDistricts as any)[district].map((prefIndex: any) => {
                            return (
                              <li className={styles.detailItem} key={prefIndex} onClick={this.selectPref} data-pref_index={prefIndex}>
                                {(japanesePrefectures as any)[prefIndex]}
                              </li>
                            )
                          })}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>

                  </Card>
                );
              })}
            {/* %%child component entry%% --> */}
          </Accordion>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AreaPrefPage);
