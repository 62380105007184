

export class ShopFilter {
    id: number;

    public genre: string;

    public currentAvailable?: boolean;

    public deliveryAvailable?: boolean;

    public cityId?: string;

    public createdAt: Date;

    public updatedAt: Date;

}
