import { restoreLoginDataFromLocalStorage } from "../../models/LoginData";
import { configForCurrentEnv } from "../../config/environment";
// import store from "../store/configureStore";
// import { LoginDataAction } from "../actions/LoginDataAction";

const apiServer = configForCurrentEnv.endpoints.api;

export const genRpc = <T>(RPCClass: {new (endpoints: string, headers?: { [key: string]: string }): T}) => {
    const loginData = restoreLoginDataFromLocalStorage();
    if(loginData) {
        return new RPCClass(apiServer, {
            Authorization: `JWT ${loginData.jwt}`,
        });
    }
    return new RPCClass(apiServer);
};
