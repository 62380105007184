import { restoreLoginDataFromLocalStorage } from "../models/LoginData";
import { configForCurrentEnv } from "../config/environment";
import { Unpacked, GenericAction } from "../types";

export type ErrorHandleAction<T> = {
  type: ChainAction;
  action?: GenericAction<string, T>;
}
export type ErrorRepresentable = {message: string, code?: number};
export type AwaitableAction<T> = Promise<GenericAction<string, T>>|GenericAction<string, T>;
export type AsyncActionCreatorRetType<T> = GenericAction<string, T|ErrorType>;
export type WhenSuccess<T, U> = (result: Unpacked<T>) => AwaitableAction<U>;
export type WhenFailure<T, U> = (error: Error, retryAsyncActionCreator: () => Promise<AsyncActionCreatorRetType<U>>) => AwaitableAction<U>;
export type Payload<T, U> = {
  error: ErrorRepresentable;
  retryAsyncActionCreator: () => Promise<GenericAction<string, U|ErrorType>>;
}
export type ErrorType = {[key: string]: any};
export type JSONRPCErrorMiddleware<T, U> = (payload: Payload<T, U>) => Promise<ErrorHandleAction<U>>;
export enum ChainAction {
  ChainNext, Return
}
export interface ExecuteRPCOptions<T, U> {
  whenSuccess?: WhenSuccess<T, U>;
  whenFailure?: WhenFailure<T, U>;
}

export function combineMiddleware<T, U>(middlewares: JSONRPCErrorMiddleware<T, U>[]): (error: ErrorRepresentable, retryAsyncActionCreator: () => Promise<AsyncActionCreatorRetType<U>>) => Promise<GenericAction<string, U>> {
  return async (error, retryAsyncActionCreator) => {
    for(const middleware of middlewares) {
      const action = await middleware({
        error: error,
        retryAsyncActionCreator: retryAsyncActionCreator
      });
      switch(action.type) {
        case ChainAction.Return:
          return action.action;

        case ChainAction.ChainNext:
          break
      }
    }
  }
}

export function buildJSONRPCClient<T>(RequestClass: {
  new (endpoints: string, headers?: { [key: string]: string }): T
}) {
  const loginData = restoreLoginDataFromLocalStorage();
  if (!loginData) {
    return new RequestClass(configForCurrentEnv.endpoints.api);
  } else {
    const headers: any = {
      Authorization: `JWT ${loginData.jwt}`,
    };
    return new RequestClass(configForCurrentEnv.endpoints.api, headers);
  }
}

export async function asyncActionCreator<Input, RPCResult, ActualResult>(rpc: (input: Input) => RPCResult, input: Input, options?: ExecuteRPCOptions<RPCResult, ActualResult>): Promise<GenericAction<string, Unpacked<ActualResult>|ErrorType>|undefined> {
  try {
    const result = await rpc(input);
    if(options && options.whenSuccess) {
      const action = await options.whenSuccess(result as Unpacked<RPCResult>);
      return action;
    }
  } catch(error) {
    if(options && options.whenFailure) {
      const action = await options.whenFailure(
        error, 
        () => {
          return asyncActionCreator(rpc, input, {
            whenSuccess: options.whenSuccess, 
            whenFailure: options.whenFailure
          });
        }
      );
      return action;
    }
  }
}