import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { CloseShopsInfo } from "../modules/CloseShopsInfo";

const defaultState = (): CloseShopsInfo => (new CloseShopsInfo());
export const closeShopsInfoReducer = (
  state = defaultState(),
  action: GenericAction<string, any>
) => {
  let newState = state;
  const data = action.data;
  switch (action.type) {
    case ActionType.LoadShops:
      newState.shops = data;
      return { ...newState }
    //%%actionType entry%%
    default:
      return state;
  }
};
