import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { LoginRPC, LoginInput } from '../rpc-clients/LoginRPC';
import { LoginUser } from '../entity/LoginUser';

//elements
//%%element import entry%%

interface IState {
  email?: string;
  emailSent: boolean;
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class AdminLoginPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      emailSent: false
    }
  }
  async componentDidMount() {
    console.log(this.props.appState.userInfo);
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  onChange = async (event: any) => {
    this.setState({
      email: event.target.value
    });
  }
  login = async () => {
    console.log('login');
    let rpc = await genRpc(LoginRPC);
    const input = new LoginInput();
    input.loginUser = new LoginUser();
    input.loginUser.email = this.state.email;
    const result = await rpc.login(input);
    if (result.success){
      this.props.dispatch({type: ActionType.IssueLoginLink, data: this.state.email})
    }
    this.setState({
      emailSent: true
    })
  }
  loginButton = () => {
    if (this.state.emailSent) {
      return (
        <>メールを送信しました</>
      );
    } else {
      return (
        <button onClick={this.login}>ログインリンク送信</button>
      );
    }
  }
  render() {
    return (
      <>
        <Header></Header>
        <div>
          <input type="email" defaultValue={this.props.appState.userInfo.loginEmail} onChange={this.onChange} />
          {this.loginButton()}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminLoginPage);
