import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { japaneseCities, japanesePrefectures } from '../constants/japaneseCities';
import { Accordion, Card } from 'react-bootstrap';
import { japanese50LetterColumns, japaneseHankakuKanasForColumns } from '../constants/japaneseCharacters';

//elements
import styles from '../css/area_list.module.css';
//%%element import entry%%

interface IState {
  activeLetter?: string;
  prefIndex?: number;
  prefName?: string;
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
  prefIndex: number;
}

class AreaCityPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    this.setState({prefIndex: (this.props as any).match.params.id});
    this.setState({prefName: (japanesePrefectures as any)[(this.props as any).match.params.id]});
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  selectLetter = (ev: any) => {
    if (this.state.activeLetter === ev.target.dataset.letter){
      this.setState({activeLetter: undefined});
    }else{
      this.setState({activeLetter: ev.target.dataset.letter});
    }
  }
  selectCity = (ev: any) => {
    this.props.history.push('/shop_list/' + ev.target.dataset.city_id)
  }
  render() {
    if (!this.state.prefName) return '';
    return (
      <>
        <Header></Header>
        <div>
          <Accordion defaultActiveKey="1">
              {Object.keys(japanese50LetterColumns).map(japanese50Letter => {
                return (
                  <Card key={japanese50Letter} onClick={this.selectLetter} data-letter={japanese50Letter}>
                    <Accordion.Toggle className={styles.mainItem} as={Card.Header} variant="link" eventKey={japanese50Letter}>
                      {japanese50Letter}行
                      <span className={styles.mainItem_after}>{this.state.activeLetter === japanese50Letter ? 'ー' : '＋'}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={japanese50Letter}>
                    <Card.Body>
                      <ul>
                        {Object.keys((japaneseCities as any)[this.state.prefName]).map(cityId => {
                          if ((japaneseHankakuKanasForColumns as any)[japanese50Letter].indexOf((japaneseCities as any)[this.state.prefName][cityId].cityKana.substr(0, 1)) > -1){
                            return (
                              <li className={styles.detailItem} key={cityId} onClick={this.selectCity} data-city_id={cityId}>
                                {(japaneseCities as any)[this.state.prefName][cityId].city}
                              </li>
                            )
                          }
                          return ''
                        })}
                      </ul>
                      </Card.Body>
                    </Accordion.Collapse>

                  </Card>
                );
              })}
            {/* %%child component entry%% --> */}
          </Accordion>
          {/* %%child component entry%% --> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AreaCityPage);
