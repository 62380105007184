import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Button } from 'react-bootstrap';
//%%import entry%%

//modules

//elements
import styles from "../css/top2page.module.css"
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class Top2Page extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  next = () => {
    this.props.history.push('/main')
  }
  render() {
    return (
    <>
      <div style={{textAlign: "center"}}>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th><div className={styles.step}>STEP1</div></th>
              <th><div className={styles.step}>STEP2</div></th>
              <th><div className={styles.step}>STEP3</div></th>
            </tr>
            <tr>
              <td>自分の地域を選択</td>
              <td>気になるお店をチェック</td>
              <td>早速注文！</td>
            </tr>
          </tbody>
        </table>
        <div className={styles.step + ' ' + styles.step4}>STEP4</div>
        <div>お店で受け取って完了！</div>
        <Button className={styles.button} variant='success' block={true} onClick={this.next}>お店を見つける</Button>
    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Top2Page);
