import { combineReducers } from "redux";
import { JWTInfo } from "../models/LoginData";
import { ErrorData } from "../modules/ErrorData";
import { errorDataReducer } from "./errorDataReducer";
import { loginDataReducer } from "./loginDataReducer";
import { generalInfoReducer } from "./generalInfoReducer";
import { shopsInfoReducer } from "./shopsInfoReducer";
import { ShopsInfo } from "../modules/ShopsInfo";
import { closeShopsInfoReducer } from "./closeShopsInfoReducer";
import { CloseShopsInfo } from "../modules/CloseShopsInfo";
import { shopInfoReducer } from "./shopInfoReducer";
import { ShopInfo } from "../modules/ShopInfo";
import { userInfoReducer } from "./userInfoReducer";
import { UserInfo } from "../modules/UserInfo";
//%%reducer import entry%%

import { GeneralInfo } from "../modules/GeneralInfo";
//%%state class import entry%%

export interface State {
  ShopsInfo: ShopsInfo;
  CloseShopsInfo: CloseShopsInfo;
  shopsInfo: ShopsInfo;
  shopInfo: ShopInfo;
  closeShopsInfo: CloseShopsInfo;
  GeneralInfo: GeneralInfo;
  userInfo: UserInfo;
  //%%state property entry%%
  errorData: ErrorData;
  loginData: JWTInfo;
  generalInfo: GeneralInfo;
}

export const state = combineReducers<State>({
  ShopsInfo: shopsInfoReducer,
  CloseShopsInfo: closeShopsInfoReducer,
  shopsInfo: shopsInfoReducer,
  shopInfo: shopInfoReducer,
  closeShopsInfo: closeShopsInfoReducer,
  GeneralInfo: generalInfoReducer,
  userInfo: userInfoReducer,
  //%%combined reducer entry%%
  errorData: errorDataReducer,
  loginData: loginDataReducer,
  generalInfo: generalInfoReducer,
});