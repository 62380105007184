import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { restoreLoginDataFromLocalStorage } from './models/LoginData';
import { JwtRPC } from './rpc-clients/JwtRPC';
import { configForCurrentEnv } from './config/environment';
import store, { persistor } from "./store/configureStore";
import { LoginDataAction } from './actions/LoginDataAction';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

const apiServer = configForCurrentEnv.endpoints.api;

window.addEventListener("DOMContentLoaded", async (event: any) => {
  const loginData = restoreLoginDataFromLocalStorage();
  if(loginData) {
    return;
  }

  const rpc = new JwtRPC(apiServer);
  const newLoginData = await rpc.getJWT();

  store.dispatch({
    type: LoginDataAction.Save,
    data: newLoginData
  });
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
