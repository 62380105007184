import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
import { State } from '../reducers';
import { Dispatch } from 'redux';
import { Button } from 'react-bootstrap';
//%%import entry%%

//modules

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class TopPage extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    // setTimeout(this.moveNext, 1500);
  }
  moveNext = () => {
    this.props.history.push('/index2')
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  render() {
    return (
    <>
      <div style={{textAlign: 'center'}}>
        <div style={{display: 'inline-block'}}>
          <img src="/images/top.png" style={{maxWidth: '80%'}} alt="テイクアウツ！" />
        </div>
        <div style={{margin: '20px'}}>地域のお持ち帰り可能なお店が<br />見つかるサービス</div>
        <div style={{fontSize: '30px', fontWeight: 'bold'}}>テイクアウツ！</div>
        <div style={{margin: '80px'}}><Button variant='success' block={true} onClick={this.moveNext}>始める</Button></div>
    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopPage);
