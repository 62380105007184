import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { GeneralInfo } from "../modules/GeneralInfo";

const defaultState = (): GeneralInfo => (new GeneralInfo());
export const generalInfoReducer = (
  state = defaultState(),
  action: GenericAction<string, any>
) => {
  let newState = state;
  const data = action.data;
  switch (action.type) {
    case ActionType.OpenModal:
      newState.showModal = true;
      newState.modalContent = data.modalContent;
      newState.modalContentComponent = data.modalContentComponent;
      newState.modalHeader = data.modalHeader;
      newState.modalHeaderComponent = data.modalHeaderComponent;
      newState.nextFunction = data.nextFunction;
      return { ...newState }
    case ActionType.CloseModal:
      newState.showModal = false;
      return { ...newState }
    case ActionType.UpdateModal:
      newState.modalContent = data.modalContent;
      newState.nextFunction = data.nextFunction;
      return { ...newState }
    case ActionType.GeoApprove:
      newState.geoApproved = data;
      return { ...newState }
    //%%actionType entry%%
    default:
      return state;
  }
};

export class GeneralModalComponentConfig {
  modalHeader?: string
  modalHeaderComponent?: JSX.Element
  modalContent?: string
  modalContentComponent?: JSX.Element
  nextFunction?: {}
}