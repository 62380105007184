import store from "../store/configureStore";

export class JWTInfo {
  public jwt: string;
  public refreshToken?: string;
}

export function restoreLoginDataFromLocalStorage() {
  const persistData = localStorage.getItem("persist:root")
  if (!persistData) {
    return null;
  }

  const obj = JSON.parse(persistData);
  if(!obj.loginData) {
    return null;
  }

  const loginData = JSON.parse(obj.loginData) as JWTInfo | null;
  if (loginData === null) {
    return null;
  }

  if (store.getState().loginData && store.getState().loginData.jwt !== loginData.jwt) {
    return null;
  }

  if (loginData.jwt && loginData.refreshToken) {
    return loginData;
  }
  
  return null;
}