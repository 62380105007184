import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
//import { ApplicationAsyncActionCreatorType, applicationAsyncActionCreators } from '../actions/rpcActions/applicationAsyncActionCreator';
import { genRpc } from '../rpc-clients/utils/JSONRPCUtil';
import { State } from '../reducers';
import { Dispatch } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { LoginRPC, TokenLoginInput } from '../rpc-clients/LoginRPC';
import { LoginUser } from '../entity/LoginUser';

//elements
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  //applicationActions?: ApplicationAsyncActionCreatorType;
  appState?: State;
}

class AdminAutoLoginPage extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    const token = (this.props as any).match.params.token;
    let rpc = await genRpc(LoginRPC);
    const input = new TokenLoginInput();
    input.loginUser = new LoginUser();
    input.loginUser.login_token = token;
    try {
      const result = await rpc.tokenLogin(input);
      if (result.success){
        this.props.dispatch({type: ActionType.Login});
        this.props.history.push('/amb/dashboard')
      }else{
        this.props.dispatch({type: ActionType.Logout});
        this.props.history.push('/amb/login')
      }
    } catch (err) {
      console.log(err);
      this.props.history.push('/amb/login')
    }
  }
  /*
  save = async () => {
    const  = new ();
    .date = new Date()
    .hours = this.props.appState.Info.hours;

    let saveInput = new SaveInput();
    saveInput. = ;

    let rpc = await genRpc(RPC);
    const result = await rpc.save(saveInput);

    this.loads()
  }
  */
  render() {
    return (
    <>
      <Header></Header>
      <div>
    {/* %%child component entry%% --> */}
      </div>
    </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    //applicationActions: bindActionCreators(applicationAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAutoLoginPage);
