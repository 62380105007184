import React from 'react';
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { Router, Route } from 'react-router-dom';
import history from "./history";

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.scss';
import TopPage from "./components/TopPage";
import Top2Page from "./components/Top2Page";
import MainPage from "./components/MainPage";
import AreaPrefPage from "./components/AreaPrefPage";
import AreaCityPage from "./components/AreaCityPage";
import ShopListPage from "./components/ShopListPage";
import AdminLoginPage from "./components/AdminLoginPage";
import AdminDashboardPage from "./components/AdminDashboardPage";
import AdminShopListPage from "./components/AdminShopListPage";
import AdminAmbassadorListPage from "./components/AdminAmbassadorListPage";
import AdminAutoLoginPage from "./components/AdminAutoLoginPage";
//%%import entry%%
import './css/style.scss';

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Route exact={true} path="/" component={TopPage} />
          <Route exact={true} path="/index2" component={Top2Page} />
          <Route exact={true} path="/main" component={MainPage} />
          <Route exact={true} path="/area_pref" component={AreaPrefPage} />
          <Route exact={true} path="/area_city/:id" component={AreaCityPage} />
          <Route exact={true} path="/shop_list" component={ShopListPage} />
          <Route exact={true} path="/shop_list/:id" component={ShopListPage} />
          <Route exact={true} path="/amb/login" component={AdminLoginPage} />
          <Route exact={true} path="/amb/dashboard" component={AdminDashboardPage} />
          <Route exact={true} path="/amb/shop_list" component={AdminShopListPage} />
          <Route exact={true} path="/amb/amb_list" component={AdminAmbassadorListPage} />
          <Route path="/amb/auto-login/:token" component={AdminAutoLoginPage} />
{/* %%page entry%% */}
        </Router>
      </Provider>
    );
  }
}