import React from 'react';
import { connect } from "react-redux";
import { ActionType } from '../constants/ActionType';
import { ShopAsyncActionCreatorType, shopAsyncActionCreators } from '../actions/rpcActions/shopAsyncActionCreator';
import { State } from '../reducers';
import { Dispatch, bindActionCreators } from 'redux';
//%%import entry%%

//modules
import Header from './ui-common/Header';
import { ShopListInput } from '../rpc-clients/ShopRPC';
import { GeneralModalComponentConfig } from '../reducers/generalInfoReducer';
import ShopRow from './ShopRow';
import { Link } from 'react-router-dom';
import SearchBox from './SearchBox';
import { ShopFilter } from '../entity/ShopFilter';
import { japaneseCityInfoByCityId } from '../constants/japaneseCities';

//elements
import styles from '../css/shop_list.module.css'
import { Navbar, Nav, Container } from 'react-bootstrap';
//%%element import entry%%

interface IState {
  //%%component state entry%%
}
interface IProps {
  history: any;
  dispatch?: Dispatch;
  actionType?: ActionType;
  shopActions?: ShopAsyncActionCreatorType;
  appState?: State;
}

class ShopListPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }
  async componentDidMount() {
    await this.loadShopRPC_shopList();
    const cityId = (this.props as any).match.params.id;

    let shopFilter = this.props.appState.shopsInfo.shopFilter;
    if (!shopFilter) {
      shopFilter = new ShopFilter();
    }
    if (cityId) {
      shopFilter.cityId = cityId;
    }
    console.log(shopFilter);
    this.props.dispatch({ type: ActionType.SaveShopFilter, data: shopFilter });
    if (this.props.appState.userInfo.geoLocation) {
      setInterval(() => {
        this.props.dispatch({ type: ActionType.CalcShopDistance, data: this.props.appState.userInfo.geoLocation });
      }, 1000);
    }
  }
  loadShopRPC_shopList = async () => {
    const input = new ShopListInput();
    input.aroundHere = this.props.appState.generalInfo.geoApproved;
    input.page = 1;
    await this.props.shopActions.shopList(input);
  }
  handleChange = (event: any) => {
    const item = this.props.appState.shopInfo;
    let value = event.target.value;
    if (event.target instanceof HTMLInputElement) {
      const t: HTMLInputElement = event.target;
      if (t.type === "checkbox") {
        value = t.checked;
      }
    }
    (item as any)[event.target.name] = value;
    this.props.dispatch({ type: ActionType.UpdateShop, data: item });
  }
  showModal = (event: any) => {
    const data = {
      modalTitle: '',
      modalContentComponent: (
        <></>
      ),
    } as GeneralModalComponentConfig;
    this.props.dispatch({ type: ActionType.OpenModal, data: data });
  }
  modalCallback = () => {
    this.loadShops()
  }
  loadShops = () => {

  }

  /*
  save = async () => {
    const shop = new Shop();
    shop.date = new Date()
    shop.hours = this.props.appState.shopInfo.hours;

    let saveShopInput = new SaveShopInput();
    saveShopInput.shop = shop;

    let rpc = await genRpc(ShopRPC);
    const result = await rpc.saveShop(saveShopInput);

    this.loadShops()
  }
  */
  render() {
    const cityInfo = this.props.appState.shopsInfo.shopFilter ? japaneseCityInfoByCityId(this.props.appState.shopsInfo.shopFilter.cityId) : null;

    return (
      <>
        <Header></Header>
        <Container>
          {cityInfo ? cityInfo.pref + cityInfo.city + 'のテイクアウト可能なお店' : ''}
          <div className={styles.count}>{this.props.appState.shopsInfo.filteredShops.length}件</div>

          <div className={styles.subLink}><Link to="/main">地域を選び直す</Link></div>
          <SearchBox></SearchBox>
          <table>
            <tbody>
              {this.props.appState.shopsInfo.filteredShops.map(shop => {
                return (
                  <ShopRow key={shop.id} shop={shop}></ShopRow>
                );
              })}
            </tbody>
          </table>
        </Container>
        <Navbar expand="lg" variant="light" bg="light" sticky="bottom">
              <Nav.Link href="/area_pref">地域を選び直す</Nav.Link>
              <Nav.Link href="/main">TOPに戻る</Nav.Link>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    appState: state
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch: dispatch,
    shopActions: bindActionCreators(shopAsyncActionCreators, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopListPage);
