import { ActionType } from "../constants/ActionType";
import { GenericAction } from "../types";
import { ShopInfo } from "../modules/ShopInfo";

const defaultState = (): ShopInfo => (new ShopInfo());
export const shopInfoReducer = (
  state = defaultState(),
  action: GenericAction<string, any>
) => {
  let newState = state;
  const data = action.data;
  switch (action.type) {
    case ActionType.UpdateShop:
      newState = { ...data };
      return { ...newState }
    //%%actionType entry%%
    default:
      return state;
  }
};
