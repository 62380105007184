export const japanese50LetterColumns = {
    'あ': 'あ',
    'か': 'か',
    'さ': 'さ',
    'た': 'た',
    'な': 'な',
    'は': 'は',
    'ま': 'ま',
    'や': 'や',
    'ら': 'ら',
    'わ': 'わ'
}
export const japaneseHankakuKanasForColumns = {
    'あ': ['ｱ', 'ｲ', 'ｳ', 'ｴ', 'ｵ', ],
    'か': ['ｶ', 'ｷ', 'ｸ', 'ｹ', 'ｺ', ],
    'さ': ['ｻ', 'ｼ', 'ｽ', 'ｾ', 'ｿ', ],
    'た': ['ﾀ', 'ﾁ', 'ﾂ', 'ﾃ', 'ﾄ', ],
    'な': ['ﾅ', 'ﾆ', 'ﾇ', 'ﾈ', 'ﾉ', ],
    'は': ['ﾊ', 'ﾋ', 'ﾌ', 'ﾍ', 'ﾎ', ],
    'ま': ['ﾏ', 'ﾐ', 'ﾑ', 'ﾒ', 'ﾓ', ],
    'や': ['ﾔ', 'ﾕ', 'ﾖ', ],
    'ら': ['ﾗ', 'ﾘ', 'ﾙ', 'ﾚ', 'ﾛ', ],
    'わ': ['ﾜ', 'ｦ', 'ﾝ', ]
}


export const japaneseHankakuKana = {
    'あ': 'ｱ',
    'か': 'ｶ',
    'さ': 'ｻ',
    'た': 'ﾀ',
    'な': 'ﾅ',
    'は': 'ﾊ',
    'ま': 'ﾏ',
    'や': 'ﾔ',
    'ら': 'ﾗ',
    'わ': 'ﾜ'
}
