export const genres = {
    0: "和食",
    1: "洋食・西洋料理",
    2: "中華料理",
    3: "アジア・エスニック",
    4: "カレー",
    5: "焼肉・ホルモン",
    6: "鍋",
    7: "居酒屋・ダイニングバー",
    8: "創作料理・無国籍料理",
    9: "ファミレス",
    10: "レストラン（その他）",
    11: "ラーメン",
    12: "汁なしラーメン",
    13: "つけ麺",
    14: "カフェ",
    15: "喫茶店",
    16: "コーヒー専門店",
    17: "紅茶専門店",
    18: "中国茶専門店",
    19: "日本茶専門店",
    20: "カフェ・喫茶（その他）",
    21: "パン・サンドイッチ",
    22: "スイーツ",
    23: "バー・お酒",
    24: "その他"
}